import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "There's something to be said for sledding",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  date: new Date(2022, 0, 23),
  articleType: "column",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/theres-something-to-be-said-for-sledding/article_3c9d6f30-ade3-57a1-868b-8024f6dde6c8.html",
  content
};

export default article;