import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import mangrove from "./mangrove.jpg";
import fish from "./fish.jpg";
import everglade from "./everglade.jpg";
import gator from "./gator.jpg";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "sophomore"
};

const article: Article = {
  title:
    "Mangrove trees, the Everglades, and Key Largo highlight the splendors of touring Florida",
  articleType: "review",
  author: [ME],
  date: new Date(2021, 8, 26),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/mangrove-trees-the-everglades-and-key-largo-highlight-the-splendors-of-touring-florida/article_87a8a47c-1fe7-595b-b7bb-0c9efbbf5c94.html",
  images: [
    {
      src: mangrove,
      caption:
        'Tranquil, twisting channels fill the gaps between massive "islands" of mangrove trees off the coast of Key Largo, Florida.',
      credit: ME
    },
    {
      src: fish,
      caption:
        "Snorkelers swim with the tropical fish at a coral reef just off the shore of Key Largo, Florida.",
      credit: ME
    },
    {
      src: everglade,
      caption:
        "The freshwater marshlands of the Everglades are unlike almost anywhere else in the world.",
      credit: ME
    },
    {
      src: gator,
      caption:
        "A little too close for comfort? This alligator is sunning itself next to the paved walkway at the Everglades National Park.",
      credit: ME
    }
  ],
  content,
  award: "Best Travel Story"
};

export default article;
