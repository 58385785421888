import { Article } from "../../articleInterfaces";
import { content } from "./content";

import strother from "./strother.webp";
import barbee from "./barbee.webp";
import keller from "./keller.jpg";
import vaneaton from "./vaneaton.webp";
import kollman from "./kollman.jpg";
import longbottom from "./longbottom.jpg";
import fulton from "./fulton.jpg";

const CP = "Courtesy photo";

const article: Article = {
  title: "Dad in one word",
  articleType: "column",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/dad-in-one-word/article_229cd619-7b27-5019-9024-48490b9c41ca.html",
  date: new Date(2023, 5, 18),
  author: [
    {
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "incoming senior"
    },
    {
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "graduate"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "graduate"
    },
    {
      name: "Carson VanEaton",
      school: "East Valley High School",
      grade: "incoming junior"
    },
    {
      name: "Maisy Kollman",
      school: "Eisenhower High School",
      grade: "incoming sophomore"
    },
    {
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "graduate"
    },
    {
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "graduate"
    },
    {
      name: "Maham Khan",
      school: "West Valley Virtual Academy",
      grade: "incoming junior"
    },
    {
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "junior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming senior"
    }
  ],
  content,
  images: [
    {
      src: strother,
      caption:
        "Jillian Strother of Riverside Christian School with her dad, David.",
      credit: CP
    },
    {
      caption:
        "Lexi Barbee of West Valley High School with her dad, Jarod, while waiting for their flight to California for a volleyball tournament.",
      src: barbee,
      credit: CP
    },
    {
      caption: "Natalie Keller of Selah High School and her dad, L.J.",
      src: keller,
      credit: CP
    },
    {
      src: vaneaton,
      credit: CP,
      caption:
        "Carson VanEaton of East Valley High School and his dad, David, by their Lego table on Friday, May 19, 2023."
    },
    {
      src: kollman,
      credit: CP,
      caption:
        "Anabelle and Maisy Kollman of Eisenhower High School with their dad, Matthew."
    },
    {
      src: longbottom,
      credit: CP,
      caption:
        "Abi Longbottom of Naches Valley High School with her dad, Thomas."
    },
    {
      src: fulton,
      credit: CP,
      caption:
        "Nate Fulton, left, of West Valley High School with his two sons, Magnus, center, and Axel."
    }
  ],
  editorsPick: true,
  award: "Best Group Feature Contribution"
};

export default article;
