import { Article } from "../../articleInterfaces";

const article: Article = {
  title: "Off The Chain: Unleashed's picks for May",
  date: new Date(2021, 4, 23),
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/off-the-chain-unleasheds-picks-for-may/article_86e0f593-6f59-5cae-8da7-88a3b4d7ff00.html",
  content: [
    "WOMBO is not your traditional lip-syncing app. Instead, WOMBO uses artificial intelligence, or AI, to animate a selfie to any of dozens of prominent tunes from the internet\'s past. After I found out about WOMBO when a friend texted me one of its creations, I knew I had to try for myself.",
    "The app\'s sleek and intuitive interface makes snapping a selfie and choosing one of the many available songs a breeze. Once processed, the app makes it easy to share your creation with the world. After using it for the first time, I was shocked by how good the resulting animation was. But that is not all. WOMBO also allows users to upload their own photos, leading to many viral singing videos of world leaders, celebrities, and just about anything with a face!",
    "WOMBO was created by Ben-Zion Benkhin and has a rating of 4.8 out of 5 stars across almost 47,000 reviews on the Apple App Store. Ready to try it for yourself? WOMBO is available for iPhones on Apple\'s App Store and for Android devices through Google Play, as well as through the app\'s own website, WOMBO.ai.",
  ]
};

export default article;