import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "Meet the Unleashed staff! (Part 1 - The veterans)",
  articleType: "staff",
  author: [
    {
      name: "David Goehner",
      school: "Unleashed coordinator"
    },
    {
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Ruby Hoeger",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "senior"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "senior"
    },
    {
      name: "Maham Khan",
      school: "West Valley Virtual Academy",
      grade: "junior"
    },
    {
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "senior"
    },
    {
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "senior"
    },
    {
      name: "Whit Peters",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "senior"
    },
    {
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "junior"
    }
  ],
  content,
  date: new Date(2022, 9, 9),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/meet-the-unleashed-staff-part-1---the-veterans/article_85642f70-35cc-5317-b805-857d7b425820.html",
  topStory: true
};

export default article;