import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import {
  addWeeks,
  Renews,
  Subscription,
  thisWeek,
  Transaction
} from "./common";

type Map<T> = { [key: string]: T };

console.warn("Need to clean up persistent.ts!");

export interface BudgetStore {
  transactions: Map<Transaction>;
  subscriptions: Map<Subscription>;
  start: string; //Not editable
}

type ReducerFunction<T> = (
  state: BudgetStore,
  payload: PayloadAction<T>
) => void;

interface Reducers {
  //Unused, mostly to get Redux to shut up
  [k: string]: ReducerFunction<any>;

  putTransaction: ReducerFunction<Transaction>;
  putSubscription: ReducerFunction<Subscription>;
  //by id
  deleteTransaction: ReducerFunction<string>;
  deleteSubscription: ReducerFunction<string>;

  //doing actual migrations is hard, ok?
  clear: (state: BudgetStore) => void;
}

const budgetSlice = createSlice<BudgetStore, Reducers>({
  name: "budget",
  initialState: {
    transactions: {},
    subscriptions: {},
    start: thisWeek().toISOString()
  },
  reducers: {
    putSubscription: (s, p) => {
      if (p.payload.amount >= 0) {
        s.subscriptions[p.payload.time] = p.payload;
      }
    },
    putTransaction: (s, p) => {
      if (p.payload.amount >= 0) {
        s.transactions[p.payload.time] = p.payload;
      }
    },
    deleteTransaction: (s, p) => {
      delete s.transactions[p.payload];
    },
    deleteSubscription: (s, p) => {
      delete s.subscriptions[p.payload];
    },
    clear: (s) => {
      s.transactions = {};
      s.subscriptions = {};
    }
  }
});

export default budgetSlice.reducer;

export const {
  putSubscription,
  putTransaction,
  deleteTransaction,
  deleteSubscription,
  clear
} = budgetSlice.actions;

export function selectIsCurrentWeek(state: RootState) {
  return (
    thisWeek().getTime() === new Date(state.transientBudget.weekOf).getTime()
  );
}

export function divisor(s: Renews) {
  switch (s) {
    case "weekly":
      return 1;
    case "monthly":
      return 4;
    case "yearly":
      return 52;
  }
}

interface WeeklyBreakdown {
  net: number;
  overage: number;
  transactions: Transaction[];
  subscriptions: Subscription[];
}

const MAX_DATE = new Date(8640000000000000);
function includesDate(
  date: Date,
  start: Date,
  stop: Date | "endoftime" | "endofweek"
): boolean {
  if (stop === "endofweek") {
    stop = addWeeks(start, 1);
  } else if (stop === "endoftime") {
    stop = MAX_DATE;
  }

  return start <= date && date < stop;
}

function filterTransactions(currWeek: Date) {
  //Does this transaction fall between the start and end of the week?
  return (t: Transaction) =>
    includesDate(new Date(t.time), currWeek, "endofweek");
}

function filterSubscriptions(currWeek: Date) {
  //Does the current week fall between the start and end of the subscription?
  return (s: Subscription) =>
    includesDate(
      currWeek,
      new Date(s.time),
      !!s.stop ? new Date(s.stop) : "endoftime"
    );
}

export const calculateWeeklyBudget: (state: RootState) => WeeklyBreakdown = createSelector(
  [
    (state: RootState) => new Date(state.budget.start),
    (state: RootState) => new Date(state.transientBudget.weekOf),
    (state: RootState) => state.budget.transactions,
    (state: RootState) => state.budget.subscriptions
  ],
  (startWeek, stopWeek, transactions, subscriptions) => {
    var currWeek = startWeek;

    var weekTransactions: Transaction[] = [];
    var weekSubscriptions: Subscription[] = [];
    var net: number = 0;
    var cum: number = 0;

    while (currWeek.getTime() <= stopWeek.getTime()) {
      weekTransactions = Object.values(transactions).filter(
        filterTransactions(currWeek)
      );
      weekSubscriptions = Object.values(subscriptions).filter(
        filterSubscriptions(currWeek)
      );

      const netTransactions = weekTransactions.reduce(
        (p, t) => (t.action === "withdrawl" ? p - t.amount : p + t.amount),
        0
      );
      const netSubscriptions = weekSubscriptions.reduce(
        (p, s) =>
          s.action === "withdrawl"
            ? p - s.amount / divisor(s.renews)
            : p + s.amount / divisor(s.renews),
        0
      );

      net = netTransactions + netSubscriptions;
      cum += net;
      
      currWeek = addWeeks(currWeek, 1);
    }

    return {
      transactions: weekTransactions,
      subscriptions: weekSubscriptions,
      net: cum,
      overage: cum - net
    };
  }
);
