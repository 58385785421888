import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "Visualize a mountain of pale green mashed potatoes, with sprigs of kale spread menacingly throughout. When I was younger, the mere sight of this side dish was enough to make my hair stand on end.",
  "Lungcole, a curious family tradition, has been a staple of our Christmas holiday celebrations for as long as I can remember. However, despite its green and off-putting appearance, it is actually pretty good.",
  "To make lungcole, just throw some mashed potatoes, cooked kale, mustard, liquid smoke and ham juice into a pot and add butter and salt to taste.",
  "So, why did this pleasant pairing of potatoes and kale invoke such a deep-seated fear within me when I was younger?",
  "I think the biggest reason is its name: lungcole, which is probably mangled German from generations ago in my family. Pronounced quite ominously as \"lung coal,\" it did not invoke the prettiest picture in my mind when I was younger.",
  "However, the second reason was the quantity. I remember the huge globs of green speckled mashed potatoes that filled our plates. The \"lung coal\" was out to find you, and it would win.",
  "But why was lungcole named — or spelled — the way that it is in my family? Interestingly, no one in my family knows. The common consensus is that the \"cole\" is for kale (which is actually called \"grünkohl\" in German). But the \"lung\" is anyone\'s guess. My family believes the combo has its roots in Germany, with potential Danish or Irish influences. But little is known for sure.",
  "My maternal grandma, who carries the torch for this tradition, pointed out that lungcole was likely an affordable dish to make with its basis in potatoes.",
  "Lungcole may have created many mini-moments of culinary terror during the holidays for me while I was growing up. However, it also served as a neat reminder of the traditions, histories and stories that make us who we are.",
  "Even if we don\'t always remember where they came from.",
]