import { Article } from "../../articleInterfaces";
import { content } from "./content";

import students from "./students.jpg";
import mariners from "./mariners.jpg";
import wc from "./soccer.jpg";
import swift from "./swift.jpg";
import royalty from "./royalty.jpeg";
import kj from "./katzenjammer.jpg";
import storyteller from "./storyteller.jpg";
import sadsongs from "./sadsongs.jpg";
import strangerthings from "./st.jpg";

const article: Article = {
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Whit Peters",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Ellie Suhm",
      school: "Eisenhower High School",
      grade: "freshman"
    },
    {
      name: "Maisy Kollman",
      school: "Eisenhower High School",
      grade: "freshman"
    },
    {
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Rebecca Lommers",
      school: "Riverside Christian School",
      grade: "senior"
    },
    {
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "junior"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "senior"
    }
  ],
  articleType: "news",
  content,
  date: new Date(2023, 0, 1),
  title: "Unleashed students look back at the biggest events of 2022",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/unleashed-students-look-back-at-the-biggest-events-of-2022/article_87b59486-8623-11ed-96d8-0f8429db967d.html",
  images: [
    {
      src: students,
      caption: "First grade students work independently in Leah Meiser's class at Cottonwood Elementary School in Yakima, Wash., on Friday, Feb. 25, 2022.",
      credit: "Emree Weaver, Yakima Herald-Republic"
    },
    {
      caption: "Julio Rodriguez congratulates Cal Raleigh as he scores in the ninth inning of an American League Wild Card game between the Seattle Mariners and Toronto Blue Jays on Oct. 8.",
      credit: "Dean Rutz, The Seattle Times file",
      src: mariners
    },
    {
      caption: "Argentina's players celebrate their victory at the end of the World Cup final soccer match between Argentina and France at the Lusail Stadium in Lusail, Qatar, Sunday, Dec. 18, 2022.",
      credit: "AP Photo/Christophe Ena, File",
      src: wc
    },
    {
      caption: "Singer Taylor Swift performs on stage in a concert at Wembley Stadium on June 22, 2018, in London. On the heels of a messy ticket roll out for Swift\'s first tour in years, fans are angry; they\'re also energized against Ticketmaster. While researchers agree that there\'s no way to tell how long the energy could last, the outrage shows a way for young people to become more politically engaged through fan culture.",
      credit: "Joel C Ryan/Invision/AP, File",
      src: swift
    },
    {
      caption: "Prince Harry and Meghan Markle, Duke and Duchess of Sussex visit the track and field event at the Invictus Games in The Hague, Netherlands, Sunday, April 17, 2022. Prince Harry and his wife, Meghan, are expected to vent their grievances against the monarchy when Netflix releases the final episodes of a series about the couple\'s decision to step away from royal duties and make a new start in America.",
      credit: "AP Photo/Peter Dejong, File",
      src: royalty
    },
    {
      src: kj,
      caption: "",
      credit: ""
    },
    {
      src: storyteller,
      credit: "",
      caption: ""
    },
    {
      src: sadsongs,
      credit: "",
      caption: ""
    },
    {
      src: strangerthings,
      credit: "",
      caption: ""
    }
  ],
  topStory: true
};

export default article;