import { Article } from "../../articleInterfaces";
import { content } from "./content";

import sus from "./sus.jpg";

const article: Article = {
  title: "Sci-fi murder mystery \"Among Us\" still offers great multiplayer fun for your summer gaming",
  author: [
    {
      name: "Moira Lindner",
      school: "Riverside Christian School",
      grade: "graduate"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming sophomore"
    }
  ],
  articleType: "review",
  date: new Date(2020, 9, 25),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/sci-fi-murder-mystery-among-us-still-offers-great-multiplayer-fun-for-your-summer-gaming/article_cc902a01-ec82-56ae-9609-803593d120ea.html",
  content,
  images: [
    {
      src: sus,
      caption: "",
      credit: "Courtesy / Innersloth"
    }
  ],
  award: "Best Review Article"
}

export default article;