import { Article } from "../../../articleInterfaces";
import { DisplayManyAuthors } from "../../../Authors";
import { DateDisplay } from "../../../../shared/DateDisplay";
import { ReadingTime } from "./ReadingTime";
import { ReadItOn } from "./ReadItOn";
import { Responsive } from "../../../../shared/Responsive";
import sharedStyle from "../../../../shared/style.module.css";

function MobileAbout(article: Article) {
  return (
    <>
      <p className={sharedStyle.center}>
        <DisplayManyAuthors authors={article.author} format="long" />
      </p>
      <p className={sharedStyle.center}>
        <DateDisplay date={article.date} />
      </p>

      <p className={sharedStyle.center}>
        <ReadingTime {...article} />
      </p>
      <p className={sharedStyle.center}>
        <ReadItOn
          url={article.yakimaHeraldURL}
          where="on the Yakima Herald Website"
        />
      </p>
      <p className={sharedStyle.center}>
        <ReadItOn url={article.clipping} where="in print" />
      </p>
    </>
  );
}

function DesktopAbout(article: Article) {
  return (
    <>
      <p className={sharedStyle.center}>
        <DisplayManyAuthors authors={article.author} format="long" />
        {" | "}<DateDisplay date={article.date} />
      </p>

      <p className={sharedStyle.center}>
        <ReadingTime {...article} />
        <ReadItOn
          url={article.yakimaHeraldURL}
          where="on the Yakima Herald Website"
          showSep
        />
        <ReadItOn url={article.clipping} where="in print" showSep/>
      </p>
    </>
  )
}

export const About = Responsive(MobileAbout, DesktopAbout); 