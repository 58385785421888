import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "West Valley High School may have moved into hybrid learning, which means remote learners are still part of the picture.",
  "During the weeks prior to the Feb. 16 return of onsite classes, some West Valley students shared their thoughts about this past year's practice of online learning and the impending reopening of the school building's doors.",
  "For Rohnit Thind, a senior, remote learning had some shortfalls. As he described it, \"We are not interacting.\"",
  "Wyatt Anderson, a freshman, had a different take.",
  "\"Working on assignments is definitely easier, but I can get more done at home,\" he said.",
  "For Cora Gilbert, a freshman, being social while staying remote is a struggle.",
  "\"I'm a talkative person, but there's no real opportunity to bond,\" she said. \"You really only know the people who talk a lot.\"",
  "Thind agreed.",
  "\"There isn't much socialization and there aren't many conversations happening. It's really hard to associate since everyone has their cameras off,\" he said.",
  "Even so, \"Turning on your camera doesn't help,\" Gilbert said.",
  "Thind believes there is a lot of anxiety in the remote classrooms.",
  "\"You feel like you're going to derail the class or that you'll make a mistake,\" he said.",
  "Thind thinks many people are still sleepy during Zooms, which leads to a situation where \"you're just always kind of confused and you're afraid to ask a question because people are going to be like, 'Why'd you ask that question?'\"",
  "\"You find yourself holding back,\" he added.",
  "But low interaction is not the only problem that Gilbert has faced.",
  "\"Many classes are no longer available for online learning. The periods when you have a class keep changing, too,\" she said.",
  "Gilbert was referring to an honors English class she was hoping to take as a remote student. With that class no longer an option, she has to take an additional class alongside a traditional English class in order to get honors credit.",
  "Getting help in online learning is also a challenge",
  "\"You have to wait to get help,\" said Gilbert. \"Sometimes a teacher's office hours don't line up with your schedule ... emails get lost.\"",
  "But she understands the struggles that teachers have, as well.",
  "\"Teachers have so much to do. They're in Zooms all day and have to prepare assignments,\" Gilbert said.",
  "However, this is not to say online learning is all bad.",
  "\"I like online learning more because I get more time to work on projects.\" said freshman Trenton Durham.",
  "But he also knows that many students need additional help and feels a hybrid model is a good approach.",
  "Gilbert noted that online learning has taught her how to use tools like Google Classroom and the graphic design platform Canva.",
  "\"We're probably going to understand computers better by the time this is all over,\" she said",
  "Still, Thind feels that \"I'm not learning as well as I should\" and said online learning \"doesn't have the hype\" of in-person learning.",
  "Thind believes health safety protocols will be a critical part of making the return to school successful.",
  "\"We should come back to school the right way\" by wearing masks and sanitizing desks and other areas, he said.",
]