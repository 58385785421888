import { Article } from "../../articleInterfaces";
import { ContentRenderer } from "../processing/ContentRenderer";
import { Byline } from "./Byline";
import { Cut } from "./Cut";
import { MyHelmet } from "./MyHelmet";
import { useAppSelector } from "../../../store";
import { About } from "./About";

import sharedStyle from "../../../shared/style.module.css";
import style from "./index.module.scss";

export function UnleashedArticle(article: Article) {
  const isMobile = useAppSelector(state => state.responsive.isMobile);

  return (
    <article className={`${style.unleashedArticle} ${isMobile ? style.mobile : style.desktop}`}>
      <MyHelmet {...article} />
      <h1 className={sharedStyle.center}>{article.title}</h1>
      <About {...article} />
      <hr />
      <Cut {...article} />
      <ContentRenderer content={article.content} />
      <Byline {...article} />
    </article>
  );
}