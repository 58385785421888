import { Article } from "../../articleInterfaces";
import { content } from "./content";
import group from "./group.jpg";
import indy from "./indy.jpg";
import clipping from "../resources/may-11-24.pdf";

const FITTERER = "Courtesy of Kirsten Fitterer";

const article: Article = {
  articleType: "news",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  date: new Date(2024, 4, 11),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/exploring-the-impact----and-ironies----of-the-navajo-code/article_97e7fe37-647c-5792-b128-3c02c9b775b8.html",
  title: "Exploring the impact -- and ironies -- of the Navajo Code Talkers of World War II",
  images: [
    {
      src: group,
      caption: "Historian Zonnie Gorman, right, visits with local reporters during a pre-lecture press conference as part of her Yakima Town Hall visit at The Capitol Theatre on March 20, 2024.",
      credit: FITTERER
    },
    {
      src: indy,
      caption: "Historian Zonnie Gorman (right) responds to a question from Unleashed's Indiana Hilmes during a pre-lecture press conference as part of her Yakima Town Hall visit at The Capitol Theatre on March 20, 2024.",
      credit: FITTERER
    }
  ],
  clipping,
  content
};

export default article;