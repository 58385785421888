import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { articles } from "../articles";
import { Article } from "../articleInterfaces";
import { extractText } from "../Article/processing/extractText";
import Fuse from "fuse.js";

interface SearchableArticle {
  title: string;
  body: string;
}

const idx = new Fuse<SearchableArticle>(
  articles.map<SearchableArticle>((a) => ({
    title: a.title,
    body: extractText(a.content)
  })),
  {
    includeScore: true,
    keys: ["title", "body"],
    ignoreLocation: true,
    threshold: 0
  }
);

export const selectArticles: (state: RootState) => Article[] = createSelector(
  [
    (state: RootState) => state.directory.query,
    (state: RootState) => state.directory.honors,
    (state: RootState) => state.directory.category,
    (state: RootState) => state.directory.sortDates
  ],
  (query, honors, categories, sortDates) => {
    let filtered: Article[];

    //If there is a query, act on it, otherwise return all articles
    if (!!query) {
      filtered = idx.search(query).map((r) => articles[r.refIndex]);
    } else {
      filtered = articles.slice();
    }

    //Sort dates
  filtered.sort((a, b) => a.date.getTime() - b.date.getTime());
  if (sortDates === "descending") {
    filtered.reverse();
  }

  //What honors should I select?
  if (!!honors.length) {
    filtered = filtered.filter((a) => {
      for (let filter of honors) {
        switch (filter) {
          case "author's choice":
            if (!!a.authorsChoice) {
              return true;
            } else break;

          case "top story":
            if (a.topStory) {
              return true;
            } else break;

          case "editor's pick":
            if (a.editorsPick) {
              return true;
            } else break;

          case "award":
            if (!!a.award) {
              return true;
            } else break;

          case "featured":
            if (!!a.featured) {
              return true;
            } else break;
        }
      }

      return false;
    });
  }

  //What article categories should I include?
  if (!!categories.length) {
    filtered = filtered.filter((a) => categories.includes(a.articleType));
  }

  return filtered;
  }
);