import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "You are the impostor. Your mission is to sabotage a spacefaring voyage, and to kill as many people as possible. They are looking for you. Hide your tracks.",
  "Among Us is an online multi-player game of guesses and lies. The game has been around since June of 2018 but skyrocketed in popularity during 2020, leaving many people wondering what the fuss was all about.",
  "Does the game owe its popularity to its developers, who chose to create a game unassociated with major game publishers? Or is it the invigorating trials that ensue as you try to unravel the mysteries of the murders? Or is this a game owed its popularity to being something that was giving people an easy way to connect during the past year?",
  "For potential answers, some incoming sophomores from West Valley High School decided to share their experiences with Among Us and what they believe is so exciting about the game.",
  "On one hand, the game offers an ease of access to its user base. As Ivy Tweedy explains: \"Among Us is contactless. You can play from anywhere and talk to anyone instantly.\"",
  "However, Trenton Durham thinks that the game\'s secret sauce is this: \"It\'s free on mobile, and available on PC. You can cross play between the two platforms effortlessly.\"",
  "He adds that the game also has the feel of a party game, which bolsters its popularity by encouraging people to share it with people who might not have played before.",
  "Wyatt Anderson agrees that he social interaction is a plus for any online game: They\'re \"a good way to check in with people and show them you care. It\'s the time we spend together that\'s important, not where it leads us.\"",
  "The gameplay in Among Us is pretty simple. You are a mate on a scientific exploration team assigned with various tasks to fulfill, with the goal to complete of your jobs as fast as possible. However, a wrench is thrown into your plan because there is an impostor on board who is out for blood. The impostors may sabotage the ship or kill the mates to achieve a total takeover.",
  "Not all hope is lost, though, because mates can call emergency meetings to either report a dead body or accuse another player of being the impostor. This gives mates the chance to eject the impostors before they can carry out their deadly mission.",
  "Anderson also appreciates the strategy involved: \"There\'s a lot more involved that just going around killing people. You get to watch what other people are doing.\"",
  "Observing other players is especially important because the impostor has access to the ventilation ducts, which allows them fast but obvious transit. Impostors are also assigned to do tasks, but other players can tell that they did not do anything because their efforts do not increase the group\'s overall percentage of complete tasks.",
  "Many platforms exist for such fraternizing but, with its murder-mystery and science-fiction multiplayer aspects, Among Us really took off. Many consider the game\'s surge last year unusual, considering that it came out in June of 2018 and that it was not produced by a large developer with tons of money to create or market its release.",
  "The history of social deduction games is a long and primarily positive one with games like Mafia, Werewolf, and Coup being among the ranks of similar titles. It is only fitting that this sort of game would turn up in an online format, reaching the top of the Steam Game Store\'s sales charts during the earlier months of the 2020 quarantine. This game is especially popular with a younger demographic and many teens have found enjoyment while playing it.",
  "Despite the cry for more from some players, Anderson and Tweedy say they are glad the developers did not make their earlier planned sequel to the game, and believe that sticking to the original concept has been a smart move.",
  "\"I\'m glad the developers didn\'t make a sequel,\" said Anderson. \"Now they can focus their attention on doing more updates and server upgrades. If they had made a sequel, they would\'ve lost a good portion of their fan base.\"",
  "Tweedy added: \"No one wants to change games because this one is just fine. It would have been a risk to make a new game.\"",
  "During 2020's lonely times of isolation, many people turned to online games as a solace and a form of interconnectivity. Players are still spending a fair amount of time online with friends and family, laughing and competing for silly victories.",
  "Anderson thinks one reason it garnered so much buzz is due to its wide-spread, online media presence. \"Many people heard about the game through Twitch or YouTube. I heard about it through YouTube videos, and from my friends and cousins.\"",
  "Whatever it is that has sparked such a frenzy around the game, it is difficult to dispute the impact that it has had on a significant amount of people. If you haven\'t tried Among Us for yourself yet, it just might be a worthwhile experience.",
  "Among Us is available for free on both IOS and Android, which come with ads. The game is also offered on the Steam Store for $5.00 (plus tax) for a no-ads experience."
]