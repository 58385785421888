import { Article } from "../../articleInterfaces";
import { content } from "./content";

import barbee from "./barbee.jpg";
import cone from "./cone.jpg";
import hilmes from "./hilmes.jpg";
import keller from "./keller.jpg";
import kollman from "./kollman.jpg";
import longbottom from "./longbottom.jpg";
import miller from "./miller.jpg";
import strother from "./strother.jpg";

const article: Article = {
  title: "Happy Father's Day — and thanks for the memories, Dad!",
  articleType: "column",
  author: [
    {
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "incoming senior"
    },
    {
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "incoming junior"
    },
    {
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "incoming junior"
    },
    {
      name: "Lucas Cone",
      school: "West Valley High School",
      grade: "graduate"
    },
    {
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "incoming senior"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "incoming senior"
    },
    {
      name: "Gracie Miller",
      school: "Eisenhower High School",
      grade: "graduate"
    },
    {
      name: "Maham Khan",
      school: "West Valley High School",
      grade: "incoming sophomore"
    },
    {
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "incoming senior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming junior"
    }
  ],
  content,
  date: new Date(2022, 5, 19),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/happy-fathers-day-and-thanks-for-the-memories-dad/article_cd6ca948-ab07-5a26-a692-d2dfbbf0c430.html",
  images: [
    {
      src: miller,
      caption: "Mike Miller and Gracie Miller",
      credit: "Courtesy of Gracie Miller"
    }, 
    {
      src: cone,
      caption: "Jesse Cone and Lucas Cone",
      credit: "Courtesy of Lucas Cone"
    },
    {
      src: longbottom,
      caption: "Thomas Longbottom and Abi Longbottom in 2012",
      credit: "Courtesy of Abi Longbottom"
    },
    {
      src: barbee,
      caption: "Lexi Barbee and her father",
      credit: "Courtesy of Lexi Barbee"
    },
    {
      src: keller,
      caption: "Natalie Keller and her dad, L.J. Keller",
      credit: "Natalie Keller of Selah High School / Unleashed"
    }, 
    {
      src: kollman,
      caption: "Anabelle Kollman with her dad, Matthew Kollman",
      credit: "Anabelle Kollman of Eisenhower High School / Unleashed"
    },
    {
      src: strother,
      caption: "Jillian Strother and Dave Strother",
      credit: "Jillian Strother of Riverside Christian High School / Unleashed"
    },
    {
      src: hilmes,
      caption: "Indy Hilmes and Todd Hilmes",
      credit: "Indy Hilmes of Selah High School / Unleashed"
    }
  ],
  topStory: true
};

export default article;