import { Article } from "../../articleInterfaces";
import { content } from "./content";
import oct1 from "../resources/oct-1-23.pdf";

const article: Article = {
  title: "Farewell to the 2023 Central Washington Fair",
  articleType: "news",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  date: new Date(2023, 9, 1),
  content,
  clipping: oct1
};

export default article;
