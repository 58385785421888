import { Article } from "../../articleInterfaces";
import { content } from "./content";

import cardigan from "./cardigan.jpg";
import racc from "./racc.jpg";
import shacket from "./shacket.jpg";
import vest from "./vest.webp";

const article: Article = {
  title: "The Unleashed guide to season-transition clothing",
  articleType: "review",
  author: [
    {
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "senior"
    },
    {
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Ellie Suhm",
      school: "Eisenhower High School",
      grade: "freshman"
    }
  ],
  date: new Date(2022, 10, 13),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/the-unleashed-guide-to-season-transition-clothing/article_155d6ecb-c4d9-53f8-ac36-74147bfb5e4d.html",
  content,
  images: [
    {
      caption: "Olivia Rankin of Eisenhower High School pulls up a warm cardigan at the end of an afternoon.",
      credit: "Mya Rankin",
      src: cardigan
    },
    {
      caption: "Heather Barbee is modeling the shacket with her boyfriend Jayden Ritch. The shacket truly is the perfect addition to your wardrobe this coming fall season.",
      credit: {
        name: "Lexi Barbee",
        school: "West Valley High School"
      },
      src: shacket
    },
    {
      caption: "Magnus Fulton in his fall-worthy \"Roasting Raccoon\" shirt.",
      credit: {
        name: "Magnus Fulton",
        school: "West Valley High School"
      },
      src: racc
    },
    {
      caption: "Eleanor Suhm of Eisenhower High School shows off the flexibility of a vest.",
      credit: "Mya Rankin",
      src: vest
    }
  ],
  topStory: true,
  award: "Best Team Coverage"
};

export default article;