import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "For 14-year-old Samantha Payne, it started with some inspiration from a family friend.",
  '\"My dad\'s best friend\'s daughter got really into drag racing," she said. "I really started getting into it because I would see her do it. I was like, \'Hey, she\'s only 16 and she was able to do all this.\'\"',
  "This year marks the end of the West Valley High School freshman\'s second season as a drag racer. Although she started drag racing last year, racing has always been a part of her family.",
  '\"My dad was a drag racer before I was born," she said. "He didn\'t race professionally, but he did it on the side. He stopped racing once I was born. When I told my dad I wanted to do it, he was all on board for that.\"',
  'Payne says racing initially had some appeal because it was a chance to drive a car. It also had another attraction: "It looked really easy.\"',
  "But there was more it than that.",
  "\"At the time, I really only thought it was \'You go when it\'s green\'. But once I got into racing a little more, I realized there are a lot more rules, and there's a bit more of a challenge than I originally thought,\" she said.",
  "But before she could race, she had to learn to drive.",
  '\"The first thing I had to do was actually learn how to operate the car," she said. "After driving lessons, it was really kind of figuring out reaction time — how you needed to move your feet to get the best reaction time.\"',
  "Payne competes in both the Summit and Gamblers racing series, and the majority of her races take place at the Renegade Raceway in Wapato. Both series are part of the National Hot Rod Association, with Summit being sponsored by Summit Racing Equipment and Gamblers being sponsored by Auto Max.",
  '\"Both series pretty much have the same classes, but they just call them different things," Payne said. "There\'s also a class for electric cars, motorcycles, and ski bikes. There are the pro and super pro classes where you can adjust the motor. There\'s also a high school class, which is pretty much when you\'re in high school and have your license.\"',
  "However, Payne competes in the Jr. Street class. The class is for anyone under 16, and the cars must be street legal.",
  '\"You also can\'t operate the car unless you are going down the track," she said. "You also have to have an adult over the age of 25 with their license be in the car with you and they have to drive you up to the starting line.\"',
  "Payne\'s car of choice? The family\'s 2005 Subaru Legacy.",
  "The car is street legal. So, as Payne points out: \"We just drive it to the track. On the way there, I listen to music and just try to stay calm.\"",
  "Once on the track, the safety equipment varies.",
  "\"For the Subaru, really it's just a helmet,\" Payne said. \"But if you were in like a car that went over 100 miles per hour, you'd have to have a fire jacket. Sometimes there's race shoes and race pants that are fire resistant.",
  '"You could also have a cage that\'s supposed to help keep you in the car if you crash. Certain seat belts can also get brought into it and a neck brace if you go fast enough.\"',
  "Thankfully, Payne hasn\'t had too many close calls.",
  '\"One time I went a little close to the wall, but I pulled it back before anything could happen," she said.',
  "The first to the finish doesn\'t always win, though.",
  '\"I do what\'s called bracket racing," Payne said. "It\'s where if a car could get down an eighth of a mile in 11 seconds, it could still go against a car that could do that in 9 seconds. But for other races, it\'s whoever gets to the finish line first.\"',
  "Since the Jr. Street race is only an eighth of a mile, there is no pit stops during the race.",
  "Still, as Payne explains, \"We go down the track and we have our pits where we just kind of park and stay there. My pit crew is my family.\"",
  "Her dad usually offers advice and predictions before and after the races.",
  '\"He says things like \'I\'m going to guess, with the weather, you\'ll get down the track in 11.59 seconds.\' I\'ll try and hope I run that or close to it," Payne said.',
  "Payne also enjoys the support among her fellow racers.",
  '\"The racing community is pretty friendly," she said. "If a part breaks on your car, you can go around and ask, \'Hey, do you have this part?\' Most of the time if they have it, they\'ll lend it to you. My dad has also lent out things, too.\"',
  "Some of her favorite races came at the tail end of this past season.",
  '\"During my last race this season, it was very much that anyone could take the win," Payne said. "We were all so close in points that whoever won that race won the title for that series and I was able to take the title for the Gamblers series.\"',
  "She also got to participate in this past Summit Series race, in which the top two Jr. Street racers from each team compete again the other Division 6 teams' top two Jr. Street racers.",
  '"I got to go to Woodburn\'s track (in Oregon) and show that I was a decent race car driver for Renegade," said Payne. "It was pretty cool to represent Renegade there.\"',
  "But what\'s it like being in the driver\'s seat during a race?",
  "Payne says it is very exciting and there\'s \"a lot of adrenaline.\"",
  "While she wants to be a writer after high school and doesn\'t plan on racing professionally, Payne plans on keeping racing as a hobby.",
  {
    format: "section",
    content: "If you go"
  },
  {
    format: "block-with-inline",
    content: [
      "To learn more about Renegade Racing, its next racing schedule, and its other racers, visit Renegade Raceway\'s website (",
      {
        format: "link",
        content: "www.renegaderaceway.com",
        src: "http://www.renegaderaceway.com/"
      },
      "), ",
      {
        format: "link",
        content: "the Renegade Racing Facebook page",
        src: "https://www.facebook.com/profile.php?id=100040873850829"
      },
      ", and Renegade Racing's Instagram site (",
      {
        format: "link",
        content: "@renegaderaceway",
        src: "https://www.instagram.com/renegaderaceway/"
      },
      ")."
    ]
  }
];
