import { Article } from "../../articleInterfaces";
import { content } from "./content";

import turkey from "./turkey.jpg";

const article: Article = {
  title: "What we are thankful for in 2020",
  author: [
    {
      name: "Ava Hoeger",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Maya Crowder",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "sophomore"
    },
    {
      name: "Susan Wilmes",
      school: "Davis High School",
      grade: "junior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    },
    {
      name: "Anabelle Kollman", 
      school: "Eisenhower High School",
      grade: "sophomore"
    }
  ],
  images: [
    {
      src: turkey,
      caption: "\"What I'm thankful for in 2020\"",
      credit: {
        name: "Morgan Greene",
        school: "Davis High School"
      }
    }
  ],
  articleType: "column",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/what-we-are-thankful-for-in-2020/article_79e952a5-f5dd-5a4d-875e-64b251cf62f9.html",
  date: new Date(2022, 10, 22),
  content
}

export default article;