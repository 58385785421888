import { Author, JSONDSL } from "../../articleInterfaces";
import img1 from "./1.jpeg";
import img2 from "./2.jpeg";
import img3 from "./3.jpeg";
import img4 from "./4.jpeg";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "senior"
};


export const content: JSONDSL = [
  "Today's the final day of the fair. If you've got $3, make sure to check out the Selah Heights Grange Dairy Bar for some delicious soft serve ice cream in vanilla or chocolate \u2014 and of course, the midway with its thrilling rides and challenging games.",
  {
    src: img1,
    credit: ME,
    caption: ""
  },
  {
    src: img2,
    credit: ME,
    caption: ""
  },
  {
    src: img3,
    credit: ME,
    caption: ""
  },
  {
    src: img4,
    credit: ME,
    caption: ""
  }
]