import { Article } from "../../articleInterfaces";
import { content } from "./content";
const article: Article = {
  title: "In the battle of computer navigation, the clear winner is the wired mouse",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  articleType: "review",
  content,
  date: new Date(2022, 0, 23),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/in-the-battle-of-computer-navigation-the-clear-winner-is-the-wired-mouse/article_20a8f817-f2ea-5d99-9f5c-b24d7daed064.html"
};

export default article;