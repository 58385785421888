import { ReactNode } from "react";
import { Image } from "../../articleInterfaces";
import { isString } from "../../../shared/isString";

import style from "./index.module.scss";

export function UnleashedImage({ src, caption, credit, float }: Image) {
  let creditLine: ReactNode;
  if (isString(credit)) {
    creditLine = credit;
  } else {
    creditLine = (
      <>
        {credit.name} of {credit.school} / Unleashed
      </>
    );
  }

  return (
    <div style={{ float }} className={float !== undefined ? style["inline-image"] : style["regular-image"]}>
      <img src={src} alt="" />
      <p>{caption}</p>
      <p><i rel="author">{creditLine}</i></p>
    </div>
  );
}
