import { Article } from "../../articleInterfaces";
import { content } from  "./content";

import lethal from "./lethal.webp";
import feb10 from "../resources/feb-10-24.pdf";

const article: Article = {
  articleType: "review",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/review-survivalist-theme-is-part-of-the-multiplayer-fun-of-new-lethal-company-game/article_0eee0e5e-4a8a-5e53-8d2c-f25b80cee024.html",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  date: new Date(2024, 1, 10),
  title: "Survivalist theme is part of the multiplayer fun of new Lethal Company game",
  images: [
    {
      src: lethal,
      caption: "A scene from Lethal Company on Steam.",
      credit: "Courtesy / Steam"
    }
  ],
  clipping: feb10,
  content
}

export default article