import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  articleType: "news",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/houses-of-halloween/article_78aa19a2-d68c-54d7-a15b-a6eaefe4d67b.html",
  date: new Date(2022, 9, 23),
  title: "Houses of Halloween!",
  content
};

export default article;