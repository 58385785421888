import { Article } from "../articleInterfaces";
import { useAppSelector } from "../../store";
import { selectArticles } from "./selectArticles";
import { Cards } from "./Cards";
import { Listing } from "./Listing";
import { SearchMenu } from "./SearchMenu";
import { Responsive } from "../../shared/Responsive";
import { Accordion } from "./Accordion";

import sharedStyle from "../../shared/style.module.css";
import style from "./index.module.css";

function ShowResults() {
  const results: Article[] = useAppSelector(selectArticles);

  if (!!results.length) {
    return (
      <>
        {results.map((a) => (
          <Listing {...a} key={a.title} />
        ))}
      </>
    );
  } else {
    return (
      <p className={sharedStyle.large + " " + sharedStyle.center}>
        <i>No results!</i>
      </p>
    );
  }
}

function DesktopDirectory() {
  return (
    <>
      <SearchMenu />

      <div className={style.results}>
        <div>
          <ShowResults />
        </div>
        <div>
          <Cards />
        </div>
      </div>
    </>
  );
}

function MobileDirectory() {
  return (
    <>
      <Accordion title="Search">
        <SearchMenu />
      </Accordion>

      <Accordion title="What is Unleashed?">
        <Cards />
      </Accordion>

      <ShowResults />
    </>
  );
}

const ResponsiveDirectory = Responsive(MobileDirectory, DesktopDirectory);

export function Directory() {
  return (
    <>
      <h1 className={sharedStyle.center}>Unleashed</h1>

      <ResponsiveDirectory />
    </>
  );
}
