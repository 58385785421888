import { Article, Author } from "../../articleInterfaces";

import beef from "./beef-pho.jpg";
import beef2 from "./beef-pho2.jpg";
import { content } from "./content";

const CAPTION = "Areli Escamilla favors the large portions of beef pho at Joy Garden.";
const PHOTOGRAPHER: Author = {
  name: "Anabelle Kollman",
  school: "Eisenhower High School"
}

const article: Article = {
  title: "Teens offer their Yakima restaurant recommendations",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  date: new Date(2021, 4, 28),
  images: [
    {
      src: beef,
      caption: CAPTION,
      credit: PHOTOGRAPHER
    },
    {
      src: beef2,
      caption: CAPTION,
      credit: PHOTOGRAPHER
    }
  ],
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/teens-offer-their-yakima-restaurant-recommendations/article_1316d68f-d9ff-5650-b5ff-c7ea6d6348a2.html",
  content
};

export default article;