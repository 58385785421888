import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Yikes! How could this happen to me? I stare hopelessly at the undulating mass of equations and variables before my eyes. Just a day to go until the big test but I still cannot figure out how to solve systems of equations. What am I going to do?",
  {
    format: "block-with-inline",
    content: [
      "We\'ve all had that happen to us at some point in our lives. But did you know that there\'s a free service that can help? Khan Academy (at ",
      {
        format: "link",
        content: "www.khanacademy.org",
        src: "https://www.khanacademy.org/"
      },
      ") provides easy to understand answers to any math question you might have, no matter your age! Recently, the service has expanded to cover topics in science, economics, and even language arts. The site offers videos that are around five minutes long and includes several step-by-step examples on how to solve the problem at hand. Other answers come in the form of an interactive webpage that, after explaining how to solve the problem, allow you to test your knowledge on different example problems. (No more need to ruin your paper with eraser rips as you struggle to grasp a new subject!) And who knows? Maybe next time the teacher calls on you for an answer, you\'ll be able to show off your newfound knowledge to the world, or at least help your friend who\'s having the same issue."
    ]
  },
  "Whenever a certain subject proves to be a thorn in your side, be sure to visit Khan Academy!"
]