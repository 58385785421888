import { JSONDSL } from "../../articleInterfaces";

export function extractText(content: JSONDSL): string {
  if (Array.isArray(content)) {
    //Recursive
    return content.map((c) => extractText(c)).join(" ");
  } else if (typeof content === "string" || content instanceof String) {
    return content as string;
  } else if ("format" in content) {
    if ("content" in content) {
      //recursive
      return extractText(content.content);
    } if (content.format === "date") {
      return content.date.toLocaleString("en-US", {day: "numeric", year: "numeric", month: "long"})
    } else {
      //It's a byline
      return `${content.name}, ${content.school}, ${content.grade}`;
    }
  } else if ("src" in content) {
    //It's an image, <a> is handled above
    return content.caption;
  } else {
    throw Error("Unable to parse: " + JSON.stringify(content));
  }
}