import { Article } from "../../articleInterfaces";
import { content } from "./content";
import mar9 from "../resources/mar-9-24.pdf";

const article: Article = {
  articleType: "news",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  date: new Date(2024, 2, 9),
  title: "Scenes of West Valley track",
  clipping: mar9,
  yakimaHeraldURL: "https://www.yakimaherald.com/photos_and_videos/west-valley-track/collection_df9f4ac6-db30-11ee-91df-9bbb0b0ade1a.html",
  content
}

export default article;