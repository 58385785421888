import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";

import reading from "./reading.jpg";
import thePage from "./the-page.jpg";
import withMagnus from "./with-magnus.jpg";

const CAPTION = "Magnus Fulton holds a copy of the Herald-Republic's Sunday comics section of Jan. 24, 1988, which Fulton found against the fence around his home  Jan. 11."
const AUTHOR: Author = {
  name: "Andrew Cabbage",
  school: "West Valley High School"
};

const article: Article = {
  title: "An enjoyable home delivery of the Sunday comics section ... from 34 years ago!",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  date: new Date(2022, 0, 23),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/an-enjoyable-home-delivery-of-the-sunday-comics-section-from-34-years-ago/article_fad261fc-7611-56a6-a3a4-057231b86042.html",
  articleType: "column",
  images: [
    {
      caption: CAPTION,
      credit: AUTHOR,
      src: withMagnus
    },
    {
      caption: CAPTION,
      credit: AUTHOR,
      src: reading
    },
    {
      caption: CAPTION,
      credit: AUTHOR,
      src: thePage
    }
  ],
  content,
  topStory: true
};

export default article;