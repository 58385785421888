import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Bob Woodward is a legendary investigative journalist who has worked for The Washington Post since 1971 and is currently an associate editor there.",
  "He reported on the Watergate scandal and has written 22 books, including works on the Supreme Court, the last 10 presidents, war, the military, the CIA, the economy, Hollywood and COVID-19. Three of those books were made into movies.",
  "Woodward, 80, has also shared in two Pulitzer Prizes, first for the coverage of the Watergate scandal with Carl Bernstein, and later as the lead reporter for coverage of the 9/11 terrorist attacks.",
  "Woodward came to Yakima to speak at this season\'s kickoff of the Town Hall series Sept. 20 at The Capitol Theatre. His comments focused on the importance of free press for sustaining a healthy democracy.",
  "After detailing for the audience his work on the Watergate scandal and President Gerald Ford\'s pardon of his predecessor, Richard Nixon, Woodward shared Nixon\'s \"Never forget, the press is the enemy\" quote. That quote conveyed how powerful investigative journalism can be in holding accountable those in power and for shaping the nation\'s thoughts and understanding on a subject. ",
  {
    format: "section",
    content: "Self-advocacy"
  },
  "Speaking to journalists during a news conference in the theater\'s lower level prior to his main lecture, Woodward gave this advice to reporters who feel they have something important:",
  "\"Go to the editor or whoever you\'re working for, make your case and then beg. Don\'t be shy about it. (Explain why) this is an important story, this is why we should do it.\"",
  {
    format: "section",
    content: "Getting context"
  },
  "Also during the news conference, Woodward encouraged reporters to remember to ask the \"why\" question.",
  "\"Too often in the daily grind we choke on the event,\" he said. \"It happened and it\'s important to deal with what happened. But how did we get there?\"",
  "Both in the news conference and later when being interviewed on the Capitol\'s main stage, Woodward touched on the impact that the digital age and social media has had on journalism. Despite making news reporting easier to access, he noted that inundation of the market fuels a demand for short-form news.",
  "\"Social media has had a massive impact because it reduces everything to short form — one paragraph, a sentence, even one word,\" he commented. \"Of course, you know that you can\'t understand things with a sentence or a word.\"",
  "He went on to explain that this can very easily fuel confusion and discord. It is very easy to become disoriented and lose an idea of what\'s really happening when short-form squashes everything down.",
  "During the main presentation to The Capitol Theatre\'s audience, Woodward explained how important it is in news media to \"get the best obtainable version of the truth.\" He explained that this is achieved through witnesses, participants, contemporaneous notes, and documentation, and that good journalism should be founded in these four pillars.",
  "As he explained, good reporting takes time, and accuracy is more important than speed.",
  "The growing pressure for short-form content and rapid news cycles makes this goal more difficult than ever to achieve."
];
