import { Article } from "../../articleInterfaces";
import { content } from "./content";
import phone from "./phone.jpg";

const article: Article = {
  title: "Summer cell phone tips: Autocorrect is a lifesaver!",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming sophomore"
    }
  ],
  date: new Date(2021, 5, 27),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/summer-cell-phone-tips-autocorrect-is-a-lifesaver/article_fa3edb3c-bb26-50f6-a12d-050c7e65cc39.html",
  images: [
    {
      credit: {
        name: "Mary Mickelson",
        school: "Eisenhower High School"
      },
      src: phone,
      caption: "\"Cell phone\""
    }
  ],
  content
};

export default article;