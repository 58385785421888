import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "If you have seen NBC\'s \"Parks and Recreation,\" you will know that there are two kinds of people in this world: Those who love Twizzlers and those who love Red Vines.",
  "With how ubiquitous and controversial these sugary snacks are, where do the Valley\'s teenagers stack up on the matter?",
  "Sydney Hager, a junior at Eisenhower High School, will not have it any other way with her Red Vines.",
  "\"For me, Red Vines are something that you have at the movies,\" said Hager. \"They just taste better, are easier to chew, and last longer. If I take one bite of a Twizzlers, I say \'Eww.\' I don\'t like Twizzlers because they have a rubbery texture to them.\"",
  "Hager also prefers the Red Vines\' packaging.",
  "\"Twizzlers come in those little two-packs at Halloween that are hard to open. You basically have to put them into their own pile because no one will trade for them,\" she said.",
  "\"I also like the Red Vines logo. I feel like it stands out and has more colors than the Twizzlers logo.\"",
  "For Parker Shea, a 2021 West Valley graduate, it all comes down to the situation.",
  "\"I\'ll get the Costco Red Vines for the bulk, but the peeling Twizzlers are easier to eat, portion out, or get a quick burst of flavor from,\" Shea said. \"However, Red Vines have the max flavor per bite.\"",
  "\"Twizzlers also have a nostalgia factor for me because I used to eat the rainbow ones on road trips,\" he added.",
  "Ella Shea, Parker\'s sister and a junior at West Valley, likewise sees a distinction based on the occasion.",
  "\"Twizzlers make for a better snack, but Red Vines are my choice for movies,\" she says.",
  "Hailing from Selah, Central Washington University undergraduate Cormac Arenson is a strong advocate for Red Vines.",
  "\"Red Vines are softer when they\'re fresh, keep their taste, and have more flavor.\"",
  "Arenson rejects Twizzlers because they lose their texture. \"When you eat them, nothing happens. They taste like hard plastic,\" he said.",
  "Joseph Opinger, an Eisenhower graduate now attending Brigham Young University, is Twizzlers all the way,",
  "\"Twizzlers are just softer,\" he said. \"Red Vines leave dry shards at the bottom of the bucket.\"",
  "Ella Steinhilb, a sophomore at Eisenhower, recognizes that, while the bulk bucket format of Red Vines can pose some challenges, her taste buds are still won over by Red Vines\' \"divine red flavor.\"",
  "Blake Bazaldua, a senior at Riverside Christian, takes a more neutral position.",
  "\"They\'re both the same,\" he said.",
  "Noah Cosper, a junior at West Valley, offers a different viewpoint.",
  "\"People are always sticking their grubby hands into the bucket of Red Vines,\" he said. \"Given the choice between Red Vines or Twizzlers, I\'d choose the Swedish Fish.\"",
]