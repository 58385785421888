export type Name = "Magnus Fulton" | "Maria Jacobson" | "Natalie Keller" | "Whit Peters" | "Ellie Suhm" | "Maisy Kollman" | "Indiana Hilmes" | "Rebecca Lommers" | "Jillian Strother" | "Olivia Rankin" | "Lexi Barbee" | "Carson VanEaton" | "Anabelle Kollman" | "Abi Longbottom" | "Maham Khan" | "David Goehner" | "Ruby Hoeger" | "Lucas Cone" | "Gracie Miller" | "Nataly Kosevich" | "Olivia Gonzalez" | "Kate Bethel" | "Andrew Cabbage" | "Morgan Greene" | "Mary Mickelson" | "Macie Ladd" | "J.J. Montelongo" | "Stella Padilla" | "Moira Lindner"| "Ava Hoeger" | "Maya Crowder" | "Susan Wilmes" | "Frankie Olivas Jr." | "Axel Fulton" | "Natalie Hernandez" | "Mallory Keller" | "Elise Klepach" | "Rosie Pandit" | "Cameron Pelson" | "Mya Rankin" | "Ava Schoonover" | "Sebastian Smartlowit" | "Annaka Yockey" | "Jasper Kenzo Sundeen";

export const LISTOFSCHOOLS = ['West Valley High School', 'Davis High School', 'East Valley High School', 'Eisenhower High School', 'Highland High School', 'Naches Valley High School', 'Riverside Christian School', 'Selah High School', 'Sunnyside High School', 'Wapato High School', 'West Valley Virtual Academy'] as const;
export type Schools = typeof LISTOFSCHOOLS[number] | 'Unleashed coordinator' | "Yakima Herald-Republic";

export type Grade = "freshman" | "sophomore" | "junior" | "senior" | "incoming freshman" | "incoming sophomore" | "incoming junior" | "incoming senior" | "graduate";

export interface Author {
  name: Name;
  school: Schools;
  grade?: Grade;
}

export type Award = 
  "Best Humor Writing" |
  "Best Review Article" |
  "Best \"Off The Chain\" Review" | 
  "Best Food Story" |
  "Best Travel Story" |
  "Excellence in Photo Illustration" |
  "Best Group Feature Contribution" |
  "Best Team Coverage" |
  "Best TV Review" | 
  "Staff Choice Award: Favorite Review";

export interface Image {
  src: string;
  caption: string;
  credit: Author | string;
  float?: "left" | "right";
}

export type ArticleType = "column" | "staff" | /* "opinion" | */ "news" | "review";

export interface Article {
  title: string;
  author: Author[];
  date: Date;
  content: JSONDSL;
  award?: Award;
  images?: Image[];
  articleType: ArticleType;
  yakimaHeraldURL?: string; //used as a url
  clipping?: string; //used as a url
  topStory?: true;
  editorsPick?: true;
  authorsChoice?: true;
  featured?: true;
  spotlight?: true;
}

export interface FormattedText {
  content: JSONDSL;
  format: "bold" | "italics" | "section" | "div-clear" | "block-with-inline" | "unordered-list";
}

export interface Byline extends Author {
  format: "byline";
}

export interface Link {
  format: "link";
  content: JSONDSL;
  src: string //used as a url
}

export interface FormattedDate {
  format: "date";
  date: Date
}

export type JSONDSL = string | Image | FormattedText | Byline | Link | FormattedDate | JSONDSL[];