import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Last New Year\'s Eve, I gave myself a challenge. I was going to stop streaming my music. Instead, for the next year, I was going to buy a song or an album a week.",
  "I had been paying for Apple Music for about eight months by that point. So why make the switch?",
  "I used to use Apple Music as my streaming service of choice. But I noticed that I never used the service to its full potential. Sometimes I\'d try Apple Music\'s recommendation features. Other times I\'d listen to something new on a whim and maybe add it to my library. And at other times I just used my car\'s radio.",
  "But mostly, I would discover songs that I loved on my own and stream them again and again.",
  "I was listening to the same songs a lot anyway, so why not buy them? So, I canceled my Apple Music subscription. It was a little unnerving watching the library I\'d spent 8 months curating vanish.",
  "Now the next decision: How did I want to rebuild?",
  "You can buy music from a lot of different places. Amazon and iTunes offer just about everything. Others, like Bandcamp, tend to have more indie artists and better payouts to artists. I chose to use iTunes because it was the same library that I had used with Apple Music and offers DRM-free tracks. This means that if you buy something, you own it and can download an unencrypted and non-scrambled version to do with as you wish.",
  "Previously, my library had been a tangle of tracks from albums that I had added whenever they caught my ear. I could do the same again — most tracks cost about a dollar — or I could buy the full album for about $10.",
  "I went with the full albums. I knew I liked one of the tracks, so maybe I\'d like the others, too. Sometimes an album was a cover-to-cover hit and sometimes it was a flop.",
  "I discovered many of the songs I love this way. Some I\'d heard long ago and had forgotten about. Other songs were entirely new to me. I also appreciated the songs a lot more. Buying my music encouraged me to come back and listen to things multiple times and learn the stories and metaphors behind the tracks I loved. I could have done the same back when I streamed, but I never had a reason or incentive to.",
  "While I appreciated the songs more once I owned them, I also enjoyed having full control over my library. I could take the songs I owned and play them on any device.",
  "Sure, I could connect via Bluetooth or download tracks to my phone before when I was streaming, but now I had even more options. Offline on my laptop? Burned to a CD? On an airplane or in another country? All possible.",
  "It was also nice having the money spent actually go to something (almost) tangible every month. If I spent $10, I got to keep what I spent that money on. When I streamed, that money was gone no matter how much or little I used it. When I bought my music, I could choose to not buy anything for a month if I wanted and just listen to what I already owned. I could even buy songs from a different provider.",
  "Purchasing albums also became a form of collecting. Each album had a story of when I bought it and how I found it. It became a form of personal history to watch my music taste shift over time.",
  "But the benefits didn\'t stop there. You\'ll never listen to ads again if you own your own music. You can also choose to use whatever music-playing software you want, offering a lot more flexibility, customization and privacy. By owning music, you also won\'t have to worry about future price increases that can occur with streaming.",
  "While the revenue splits are still minimal for artists either way, they do earn more from music sales than from streaming. Exact figures vary, but in the case of iTunes, artists will often earn about a dollar for every $10 of music sold. The record company might take $5.35 of that $10 and Apple would keep $3.70. Streaming is much worse, and can range from $0.001 to $0.008 per stream for the performer, depending on the platform.",
  "Still, streaming is a fantastic choice for most consumers.",
  "Streaming makes it effortless to listen to anything on a whim, with the entire, inexhaustible catalog at your fingertips at all times. Moreover, streaming services are great at recommending new songs for you to listen to. Many streaming services, like Spotify, also offer sharing and collaborative playlist options.",
  "Streaming resources such as Spotify or Pandora offer a free but ad-supported plan. Most services offer a more feature-filled and ad-free option for about $10 a month. If you are always listening to new music, these ad-supported and paid tiers might be the perfect fit.",
  "Purchasing your music has a significant upfront cost — about $1 a track or $10 an album — which will quickly outpace a streaming service if you make regular purchases like I do. Still, you get to keep what you buy and will never have to pay for that same song or album again in the future."
];
