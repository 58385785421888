import { AddCharge } from "./AddCharge";
import { Charges } from "./Charges";
import { WeekOf } from "./WeekOf";
import { Remaining } from "./Remaining";

export function Interface() {
  return (
    <>
      <AddCharge />
      <WeekOf />
      <Remaining />
      <Charges />
    </>
  );
}
