import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "From learning to instruct in a virtual format to condensing curriculum so it can be taught in a shorter time, teachers have adapted to uncharted educational territory in the midst of the COVID-19 pandemic. And upon the return to full-time, in-person instruction this fall, educators are facing additional challenges.",
  "Among those: With the 2019-20 school year having been cut short and the majority of the 2020-21 year having been conducted remotely, many students have emerged with gaps in their knowledge and learning.",
  [
    {
      format: "section",
      content: "Selah High School"
    },
    "\"I think students haven\'t had as much consistency in their learning,\" said Selah High School math teacher Theena Roberts. \"It\'s fragmented and it\'s been broken up. Students have had to adapt a whole bunch in order to be successful, which means the teachers have had to adapt.\"",
    "Deborah Thompson, an English teacher at Selah, shares similar sentiments regarding the disruption of students\' learning.",
    "\"I know we may have lost a lot, so I\'m worried about that,\" she said. \"I just want to make sure that they\'re able to meet at least those basic standards.\"",
    "Both Roberts and Thompson have tackled the challenge by first attempting to discover where students are struggling.",
    "\"At the beginning of this year, I tested the waters to figure out where those gaps were, where those holes were,\" Roberts said. She plans to fill those gaps by incorporating opportunities for students to review material.",
    "Meanwhile, Thompson spends time with struggling students one-on-one.",
    "In addition to ensuring students are successful academically, the two teachers hope to create a positive learning environment.",
    "\"I want students to feel safe and comfortable coming into my classroom,\" Roberts said. \"And I want them to be able to critically think and problem-solve. I want students to love math again.\"",
    {
      format: "byline",
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "Eisenhower High School"
    },
    "Michelle Beard, English teacher at Eisenhower High School, said that while grades did drop during virtual and hybrid school, students seem to be doing just fine. Recently, her students took a reading-level quiz, and Beard said students were pleasantly surprised at their scores, which were higher than expected.",
    "While it\'s only the beginning of the year, she hasn\'t noticed any knowledge gaps. On the contrary, many of her students are focused, goal-oriented, and ready to learn.",
    "Beard stresses the importance of having the right mindset.",
    "\"Here\'s my thing — everyone says we\'re behind, but who are we behind? The whole country and most of the world was shut down, too,\" she said.",
    "Kristen Lombardi, a math teacher at Eisenhower, has noticed some gaps in knowledge. But thanks to a newly adopted math curriculum that focuses on taking online placement tests and customizing plans for each student, things have been going smoothly. Like Beard, she has noticed a difference in students\' attitudes.",
    "\"I see that the students actually want to be here, which is really nice,\" Lombardi said.",
    "Both teachers agree that student participation and effort were lower online, but it seems that knowledge and understanding have been gained by both students and teachers.",
    "\"Teachers sometimes assume that students\' lives are like how ours were at their age,\" said Beard, who places high importance on simply having a conversation with each student, especially during the pandemic.",
    "Lombardi echoes that sentiment, adding that online teaching gave her a glimpse into her students\' situation outside of school and that teachers need to understand that \"a student doesn\'t have much control over their home life.\"",
    "Beard and Lombardi believe that mutual empathy and awareness are vital for teacher-student relationships. They also feel students should be aware that teachers had to learn and change just as much as their pupils did.",
    "\"We are stressed, too,\" Beard said. \"We had to shift how we taught. Now I\'m totally plugged in 24 hours a day. If somebody emails me at 9 p.m., I feel like I have to email back.\"",
    "So what\'s changing this year? Lombardi hopes that students can grow from the obstacles they faced and become more resilient.",
    "\"We are going to focus more on relationships,\" Beard said. \"We missed that so much last year. I just want to teach people, not computer screens.\"",
    {
      format: "byline",
      name: "Anabelle Kollman",
      grade: "junior",
      school: "Eisenhower High School"
    }
  ],
  [
    {
      format: "section",
      content: "West Valley High School"
    },
    "For Janae Larsen, who teaches Spanish at West Valley High School, the pandemic provided an opportunity to rethink the curriculum.",
    "\"It was out with the old and in with the new,\" she said. \"The pandemic forced a lot of us to change and update our teaching practices.\"",
    "Tyson Whitfield, who teaches history at the high school, is facing a similar situation.",
    "\"The pandemic forced us all to be more reflective of our practices,\" he said. \"We\'ve had to cut a lot of the \'fluff\' out of the curriculum.\"",
    "He adds that, as a result, the question has become one of: \"What do students need to know when they leave this building? And, now that we are back to a five-days-a-week, in-person schedule, how do we not bring back the \'garbage\' and \'filler\' now that we see students more often?\"",
    "For Whitfield, it\'s all been positive.",
    "\"These changes have made school much more interesting and engaging,\" he said.",
    "Larsen feels that because of the pandemic, teaching changed.",
    "\"We\'ve had to find ways to reach students and each other on a personal level,\" she said. \"We are trying to engage with students as people. We\'re able to see parts of their personalities that we wouldn\'t be seeing if we weren\'t trying new things or if we were online.\"",
    "This is important because, as Whitfield explains: \"If we don\'t show students we care, the academic side becomes irrelevant.\"",
    "But what does this look like in practice?",
    "\"I try to start each class period with an icebreaker to try to bring the class together,\" Whitfield said.",
    "Teachers made adjustments, too.",
    "\"We have also made a couple of long-term mental and physical health changes as well,\" Larsen said, including staff yoga.",
    "These changes are valuable because, as Whitfield said, \"If we don\'t take care of ourselves, we are useless.\"",
    "What does the road ahead look like for learning?",
    "\"It\'s going to take us two to three years to make up the lost ground for students in our program,\" Larsen said.",
    "So how can students make the most of the return to in-person learning?",
    "\"Value this learning experience,\" Whitfield said. \"We know what the alternative is.\"",
    "Larsen wants students to remember to ask questions, especially because everyone will have learning gaps after last year.",
    "But what if schools have to close again this year?",
    "\"We don\'t want to close,\" Larsen said. \"But if we do, the expectations will be higher. We\'ll deal with it. We\'ll adapt and we\'ll do it to the best of our abilities.\"",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ]
];
