import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "Some of the best advice we've had from mom",
  author: [
    {
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "junior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Nataly Kosevich",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "junior"
    },
    {
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "sophomore"
    },
    {
      name: "Olivia Gonzalez",
      school: "Davis High School",
      grade: "senior"
    },
    {
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "sophomore"
    },
    {
      name: "Maham Khan",
      school: "West Valley High School",
      grade: "freshman"
    },
    {
      name: "Gracie Miller",
      school: "East Valley High School",
      grade: "senior"
    }
  ],
  articleType: "column",
  date: new Date(2022, 4, 8),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/some-of-the-best-advice-weve-had-from-mom/article_d0af3345-1e6e-54d2-b6ce-a7fb3e879751.html",
  content,
  topStory: true
};

export default article;