import { Navigate, useParams } from "react-router-dom";
import { articles } from "../articles";
import { Article } from "../articleInterfaces";
import { urlForArticle } from "..";
import { UnleashedArticle } from "./UnleashedArticle";

type ArticleToURL = {[url: string]: Article}
const mapper: ArticleToURL = articles.reduce<ArticleToURL>((acc, a) => { acc[urlForArticle(a)] = a; return acc }, {})

export function GetArticle() {
  const title = useParams().title;
  if (!!title) {
    if (title in mapper) {
      return <UnleashedArticle {...mapper[title]} />
    }
  }
  return <Navigate to="/404" replace={true} />
}