import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "\"Doctor Strange in the Multiverse of Madness\" takes moviegoers on an action-packed adventure across realities. Director Sam Raimi\'s latest film isn\'t afraid to challenge what it means to be part of the Marvel Cinematic Universe, offering no small dose of horror, gore and heartstring-pulling.",
  "With its thrilling plot twists, heart-stopping jump-scares, tear-jerking moments and good old-fashioned action, it is no wonder that many in our own universe rave and rant about this film.",
  "\"I think that this movie was kind of an experiment for Marvel because this is not their normal formula,\" said Gracyn Cantrell, a sophomore at West Valley High School. \"I was getting tired of the Marvel formula. It\'s good, but after a while it gets old. I like that this movie wasn\'t just like every other Marvel movie where it was just only action. This one puts you on edge. It gets you scared.\"",
  "\"This movie was more than just your traditional horror film — it was much more psychological,\" said Wyatt Anderson, also a sophomore at West Valley. \"I think that it really pushed its PG-13 rating.\"",
  "However, this isn\'t a new direction for Marvel, by any means.",
  "\"It\'s not like this movie came out of nowhere and changed the face of Marvel. Some of the Marvel shows got pretty dark, too,\" Cantrell said.",
  "Still, there is much to love about the newest installment in the MCU, with Benedict Cumberbatch reprising the character most recently seen in \"Spider-Man: No Way Home.\"",
  "\"I really liked the love story with Christine Palmer (the title character\'s girlfriend from the 2016 movie),\" Cantrell said. \"I was wondering what was going to happen to them.\"",
  "Anderson agrees.",
  "\"The scenes with Christine Palmer were really well done,\" he said.",
  "The \"Doctor Strange\" sequel also brings with it a new character: America Chavez, a teenage girl capable of opening gateways to other universes.",
  "Cantrell wants to learn more about this new arrival.",
  "\"I felt like there could have been more backstory to this character than just the 10-second flashback we were given. We still don\'t know much about her,\" Cantrell said.",
  "So, of course, Dr. Strange isn\'t the only star of the show (as much as the lead character\'s ego would want you to believe otherwise). You can\'t ignore Elizabeth Olsen\'s performance as Wanda Maximoff.",
  "Cantrell loves Olsen\'s performance.",
  "\"Maximoff was a villain when she first came to the MCU and we watched her become good. Now, we are watching her swing back to being a villain. I really like seeing her as a villain because you get to see her full power,\" Cantrell said.",
  "Another familiar MCU character doesn\'t seem to be quite as satisfying in this sequel.",
  "\"Why didn\'t they let Wong be his full comedic self?\" Anderson asked. \"After all, he\'s been doing all these cameos lately.\"",
  "And how well did Marvel execute a movie centered around the interactions of multiple parallel realities?",
  "For the most part, Cantrell appreciates it.",
  "\"I like how when Dr. Strange fell through the multiverse with Chavez, we don\'t just see different versions of New York (where the scene took place); we also see universes where reality is totally different,\" Cantrell said.",
  "Visuals such as that are a crucial part of any movie, but the musical score can often make or break a film. Cantrell feels the music in the new movie fits well with the horror scenes. Particularly memorable is one scene when our titular hero brings some music-related creativity to his combat, building to some grand, classically-inspired crescendos as the battle wages on.",
  "If you\'re ready for your own dose of multiversal mania at a local theater, you\'ll enjoy \"Doctor Strange in the Multiverse of Madness.\"",
]