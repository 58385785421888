import { Article } from "../../articleInterfaces";
import { content } from "./content";

import woodward from "./woodward.jpg";
import oct1 from "../resources/oct-1-23.pdf";

const article: Article = {
  title: "Lessons in journalism from Bob Woodward",
  images: [
    {
      src: woodward,
      credit: {
        name: "David Goehner",
        school: "West Valley High School"
      },
      caption: "Journalist Bob Woodward greets Unleashed team members Sebastian Smartlowit of Wapato High School, Magnus Fulton of West Valley High School and Ellie Suhm of Eisenhower High School upon entering the press conference prior to his appearance at the Yakima Town Hall Series at The Capitol Theatre on Sept. 20, 2023."
    }
  ],
  articleType: "column",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  date: new Date(2023, 9, 1),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/lessons-in-journalism-from-bob-woodward/article_eb6e2471-0249-535b-8286-f02bf4483656.html",
  content: content,
  clipping: oct1,
  editorsPick: true
};

export default article;