import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "The wind howled, hurling the sand like thousands of tiny daggers against my legs. The clouds on the horizon flashed with light. A cold wave ripped the sand out from under me. But I didn\'t care. And in just 12 more short hours, with that first and only storm of the trip behind me, I would stand in the same spot again and admire one of the best beaches I had ever seen.",
  "My family\'s trip to the Florida Keys had begun.",
  {
    format: "section",
    content: "Mangrove forests"
  },
  "Mangroves are dense, stout, twisting trees that have the audacity to grow where few other terrestrial plants would dare: in the saltwater of the temperamental coastline. With the trees jutting out far from the shore, the best bet for exploring their wonder is in a kayak.",
  "From a distance, the mangrove trees appear as one impassible, unending tangle of branches and leaves. However, upon closer inspection, the islands of trees are separated by tranquil, narrow channels. Some of these channels are barely wider than a kayak.",
  "The watery channels seemed like something out of another world. They had an eerie calm to them, in sharp contrast to the breeze, currents and waves of the open waters. Jellyfish lurked in the gently swaying seagrass beneath our boats. The channels twisted with no rhyme or reason, and I felt disorientated and lost in the massive green labyrinth. I was glad to have a guide to lead us.",
  {
    format: "section",
    content: "The Everglades"
  },
  "I actually saw the Everglades on my first day in Florida as the plane came in to land. I just didn\'t know it at the time. From the window seat, I saw an endless expanse of gently swaying reeds sprinkled with small islands of trees.",
  "The freshwater marshlands almost looked like a prairie with slowly swaying green-brown grass-like reeds extending as far as the eye could see. The reeds were broken up by small islands of trees that dotted the landscape wherever they were able to break through the limestone floor of the Everglades.",
  "What really surprised me was how perfectly flat the Everglades appeared to be. The land seemed to extend into the distance forever before eventually falling over the horizon. Not like here in Yakima, where it\'s hard to go very far without encountering hills and mountains. We also don\'t have hundreds of alligators casually sunning themselves next to the road.",
  {
    format: "section",
    content: "Snorkeling"
  },
  "To end the trip, my family went snorkeling at a coral reef in Key Largo.",
  "On television, coral is brightly colored with equally vibrant fish pouring out of every nook and cranny. But here, many of the corals had a more yellow and brown hue to them and looked like giant, popcorn-shaped boulders.",
  "Fish of all shapes and sizes hid in the swaying kelp and sheltered in the pockets of the coral. Whenever I got too close, schools of fish would pour out of their hiding places. The fish, unlike the coral they hid in, were brightly colored. Many fish sported electric blue or yellow stripes and fins.",
  "The world is an amazing place. I\'m grateful that I had this chance to explore some of it with my family."
]