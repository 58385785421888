import { Author, JSONDSL } from "../../articleInterfaces";

import hatfields from "./hatfields.jpg";
import shotput from "./shotput.jpg";
import sprinters from "./sprinters.jpg";
import warmups from "./warmups.jpg";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "senior"
}

export const content: JSONDSL = [
  "High school track season just got started. Here are scenes from the first week of practice at West Valley High School.",
  {
    src: sprinters,
    caption: "Sprinters from West Valley's girls track team burst out together during the first week of practices Feb. 29, 2024. With the current track surface dating back to 2009, West Valley will be receiving a new subsurface and a new layer of rubberized track surface after the end of this season as part of renovations that will be happening at the track and field area this summer.",
    credit: ME
  },
  {
    src: hatfields,
    caption: "Distance runners Bryce Hatfield, Ethan Hatfield, Bennett Bauer of West Valley's track and field team lead off a group during the initial week of spring season practices on Feb. 29, 2024.",
    credit: ME
  },
  {
    src: shotput,
    caption: "Coach Adam Eldridge helps his West Valley High School shot put throwers with their form during the Rams' first week of practices Feb. 29. 2024.",
    credit: ME
  },
  {
    src: warmups,
    caption: "Members of the West Valley High School boys track team complete a turn during warmups on Feb. 29, 2024.",
    credit: ME
  }
]