import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  {
    format: "bold",
    content: [
      "Magnus Fulton",
      "West Valley High School",
      "9th grade"
    ]
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "Unleashed experience: "
      },
      "1 year."
    ]
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "Summer binge: "
      },
      "I rewatched \"The Office\" for the sixth time over the summer, trying to squeeze in one last run through before the show that has shaped all of our lives leaves Netflix. I also ate an unhealthy amount of Reese's Peanut Butter Cups and watched all of the Marvel movies with my family."
    ]
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "About Me: "
      },
      "I love being outdoors. I explored my neighborhood and went for grocery runs on my bike over this last summer. I also went on a daily run with my chocolate Lab, Leia, who I eventually had to start leaving behind because her once frantic excitement at the mere mention of the word \"run\" had faded into a resounding \"no\" over the course of the summer."
    ]
  },
  "I also love hiking. I explored many of the trails managed by the Cowiche Canyon Conservancy with my family, and was even able to sneak in a couple trips up to the Cascade Mountains, as well!",
  "I also love making cool things with computers and hanging out with my friends, so I organized several online Minecraft parties to stay in touch over the summer."
]