import { Article } from "../../articleInterfaces";
import { content } from "./content";

import pencil from "./pencil.jpg";

const article: Article = {
  title: "The Great Unleashed Pencil Debate!",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    },
    {
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "junior"
    }
  ],
  articleType: "column",
  date: new Date(2022, 4, 22),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/the-great-unleashed-pencil-debate/article_da82419c-d00c-5586-a336-2024544f6c91.html",
  images: [
    {
      src: pencil,
      caption: "In this corner, the Dixon Ticonderoga. And in the opposing corner, the clickable mechanical pencil.",
      credit: {
        name: "Magnus Fulton",
        school: "West Valley High School"
      }
    }
  ],
  content,
  award: "Excellence in Photo Illustration",
  authorsChoice: true
};

export default article;