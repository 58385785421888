import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Devina Khan, West Valley Junior High\'s computer essentials and STEM teacher, wants to make sure everyone has an opportunity to dream and achieve big. However, Khan feels that women often go underrepresented in the STEM fields, the careers that focus on science, technology, engineering, and math.",
  "She thinks that this is because of the perception that computing leads to a solitary lifestyle, as well as the belief that work in the information technology field is is competitive rather than collaborative. Also, said Khan, the \"association of computers with gaming appeals more to boys.\"",
  "So that\'s where Ignite comes in.",
  "\"Ignite exists so that girls can get over the hump and fear of STEM and also get inspiration from other women professionals in STEM fields,\" Kahn said.",
  "Khan is the leader of the program\'s chapter at West Valley Junior High.",
  "\"Ignite is an international organization, with chapters all around the world,\" she said. \"Ignite brings girls together, and exposes them to different STEM opportunities through field trips, mentorship programs, guest speakers, and many different classes.\"",
  "But that\'s not all. In 2018 and 2019, Khan\'s Ignite chapter hosted a STEM fair that featured projects, demonstrations, hands-on activities and a booth from Triumph Actuation Systems.",
  "And between January and March 2020, Ignite members helped 32 adult members of the community learn important skills with Microsoft Word.",
  "In February 2020, West Valley\'s junior high school Ignite members went head-to-head with high school students at Central Washington University\'s \"Many Faces of Entrepreneurship\" tournament, where students had to collaborate and compete with each other to create new marketable products. West Valley\'s Caitlin Pham, then a seventh-grade member of the Ignite program, won the MVP award.",
  "Another Ignite member from the school, seventh-grader Joy Lally, is among those who have learned many lessons from her experiences with Ignite.",
  "\"Our last panel said to try out different things to find what you like in STEM. That way you can test out what you could and would like to do in the future,\" she said.",
  "Eighth grade Ignite member Evelin Sererino Reno has learned there\'s more to STEM than people might think.",
  "\"You don\'t need to be good at math,\" Reno said. \"There are other STEM fields like art ... that you might be interested in. You never know. You might just find something you like.\"",
  "She thinks getting into STEM fields is hard, especially for girls.",
  "Even if it\'s something you want to do, \"you\'re scared to do it, because you can\'t relate to anybody or you haven\'t had a very great example.\"",
  "But Ignite helps girls find encouragement.",
  "\"Everybody we have talked to empowers us a lot,\" Reno said.",
  "Reno\'s classmate, Natasha Kalombo, also sees the benefits of the Ignite program.",
  "\"It\'s really inspiring seeing all these women, especially women of color, coming into the field and making that more normal,\" she said.",
  "Kalombo\'s biggest takeaway from Ignite?",
  "\"Don\'t be afraid to take risks,\" she said. \"If someone says that you can\'t do something, you have the power to change that. You have the power to change your future because, if you put your mind to it, you can do anything that you want to accomplish.\"",
]