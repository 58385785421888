import { Article } from "../../articleInterfaces";
import { content } from "./content";

import mar2 from "../resources/mar-2-24.pdf";


const article: Article =  {
  title: "A brotherly bout about buying",
  author: [
    {
      name: "Axel Fulton",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  articleType: "column",
  date: new Date(2024, 2, 2),
  clipping: mar2,
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/column-a-brotherly-bout-about-buying-why-in-person-shopping-is-better/article_9d6232fc-f0fa-58c0-b3a3-55c850ec2ee0.html",
  content
}

export default article;