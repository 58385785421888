import { Article } from "../../articleInterfaces";
import { content } from "./content";

import thompson from "./thompson.jpg";
import beard from "./beard.jpg";
import beard2 from "./beard2.jpg";

const article: Article = {
  title: "Valley\'s teachers adapt – and adapt again – as high schools begin classes",
  author: [
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "junior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  articleType: "news",
  date: new Date(2021, 8, 26),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/valley-s-teachers-adapt-and-adapt-again-as-high-schools-begin-classes/article_1018e532-05b7-55d7-ad8d-f921bbf7f546.html",
  images: [
    {
      caption: "Selah High School English teacher Deborah Thompson plans a lesson in her Selah High School classroom on Sept. 10. Thompson is one of many teachers who have had to adapt to meet the needs of students after two school years disrupted by the COVID-19 pandemic.",
      credit: {
        name: "Natalie Keller",
        school: "Selah High School"
      },
      src: thompson
    },
    {
      caption: "Michelle Beard teaches her first period class at Eisenhower High School Friday, Sept. 10, 2021.",
      credit: {
        name: "Anabelle Kollman",
        school: "Eisenhower High School"
      },
      src: beard
    },
    {
      caption: "Michelle Beard teaches her first period class at Eisenhower High School Friday, Sept. 10, 2021.",
      credit: {
        name: "Anabelle Kollman",
        school: "Eisenhower High School"
      },
      src: beard2
    }
  ],
  content,
  topStory: true
};

export default article;