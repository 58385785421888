import { Byline, JSONDSL, Name } from "../../articleInterfaces";

import lexi from "./lexibarbee.jpeg";
import magnus from "./magnusfulton.jpeg";
import indy from "./indyhilmes.jpg";
import ruby from "./rubyhoeger.jpg";
import maria from "./mariajacobson.jpg";
import natalie from "./nataliekeller.jpg";
import maham from "./mahamkhan.webp";
import anabelle from "./anabellekollman.webp";
import abi from "./abilongbottom.jpg";
import whit from "./whitmanpeters.jpg";
import olivia from "./oliviarankin.jpg";
import jillian from "./jillianstrother.webp";

const WEAVER = "Emree Weaver / Yakima Herald-Republic";

interface _AboutLine extends Byline {
  yearInUnleashed: "first" | "second" | "third" | "fourth";
}

type AboutLine = Omit<_AboutLine, "name" | "format">;

function person(
  float: "left" | "right",
  headshot: string,
  name: Name,
  aboutline: AboutLine,
  meal: string,
  about: string,
  credit: string = WEAVER
): JSONDSL {
  return {
    format: "div-clear",
    content: [
      {
        src: headshot,
        caption: "",
        credit,
        float
      },
      {
        format: "section",
        content: name
      },
      {
        format: "italics",
        content: `${aboutline.school}, ${aboutline.grade}, ${aboutline.yearInUnleashed} year in Unleashed`
      },
      {
        format: "block-with-inline",
        content: [
          {
            format: "bold",
            content: "Favorite homemade meal: "
          },
          meal
        ]
      },
      {
        format: "block-with-inline",
        content: [
          {
            format: "bold",
            content: "About me: "
          },
          about
        ]
      }
    ]
  };
}

const COURTESY = "Courtesy photo";

export const content: JSONDSL = [
  "For the 24th consecutive autumn, it\'s time for the great journalistic tradition of introducing this year\'s creative team of teens you\'ll be seeing here every Sunday on the Unleashed page.",
  "Unleashed is supported through a longstanding partnership involving the Yakima Herald-Republic, Educational Service District 105, and participating area high schools. This collaboration allows local young people to share their voice and talents beyond the classroom by creating articles, photography and illustrations that appear in this newspaper. Their work is also posted on the Herald-Republic\'s Unleashed webpage, and on the Unleashed Facebook site (which you can follow at \"Yakima Herald-Republic Unleashed\").",
  "For 2022-23, the Unleashed team has expanded to include representation from 10 of the area\'s school districts and private schools: East Valley, Highland, Naches Valley, Selah, Sunnyside, Toppenish, West Valley, Yakima, La Salle and Riverside Christian.",
  "Also this year, seniors Natalie Keller of Selah High School and Anabelle Kollman of Eisenhower High School join the very elite club of fourth-year members of the program. That\'s a distinction that only 28 of Unleashed\'s past participants have ever achieved, out of more than 400 students to go through the program since it started in 1999.",
  "This week, we\'re re-introducing you to the dozen veterans in this year\'s Unleashed group. Next week, you\'ll meet this fall\'s newest additions to the team.",
  person(
    "left",
    lexi,
    "Lexi Barbee",
    {
      school: "West Valley High School",
      grade: "senior",
      yearInUnleashed: "second"
    },
    "My dad\'s Montreal seasoned steak with smoked bacon mashed potatoes, steamed broccoli and breadsticks.",
    "Hello! I am a volleyball coach for No Limits Volleyball Club and a soccer coach for Upward. I love volunteering and am an active member in the West Valley Church of the Nazarene. My favorite things to do in my free time are Bible studies, volunteering, going on crazy adventures with friends and weightlifting. I earned the honor of being the 4A First Team All State setter last year and have a true passion for volleyball. I honestly don\'t know where I\'m going to go to college yet or what I am going to major in next year, but I trust that the Lord will lead me down the right path. And, I am beyond excited for my senior year. That\'s a bit about me and I can\'t wait for you guys to see my future articles."
  ),
  person(
    "right",
    magnus,
    "Magnus Fulton",
    {
      school: "West Valley High School",
      grade: "junior",
      yearInUnleashed: "third"
    },
    "Dal bhat, a curry dish of rice and lentils. My family throws in potatoes, carrots, onions and a few other herbs and spices. When I was younger, I was almost always the last one to finish eating. But with dal bhat, things were different. By the time the rest of my family had finished their first bowl, I was usually already wolfing down my second or third.",
    "As I enter my third year with Unleashed and my junior year at West Valley High School, it\'s looking like this year\'s adventures will be taking me away from that campus more than they\'ll have me on it. I\'m starting out as a full-time Running Start student at Yakima Valley College, from where I hope to move on to a degree in computer science and software engineering. I\'ll also be splitting my time this year between cross country practice, Unleashed, time with friends, clubs, and whatever other crazy ideas I come up with. Whether I\'m racing with my friends at cross country, or against ever-approaching deadlines at YVC, I can\'t wait to see what this year has in store."
  ),
  person(
    "left",
    indy,
    "Indiana Hilmes",
    {
      school: "Selah High School",
      grade: "junior",
      yearInUnleashed: "third"
    },
    "Chicken Parmesan.",
    "Hey there. I\'m so proud of how much I\'ve grown both as a writer and as a person through this program. Unleashed has allowed me to discover my own creative voice, use some (hopefully funny) jokes in my articles, and talk a LOT about the music that I like and the cool people I meet. In my free time, you can find me writing short stories, dancing, running, playing a lot of instruments (not all at once), and listening to a very diverse array of music. Long story short, Unleashed is a little bit of a home to me, and I hope you tune in to all of our wild high school adventures in the months to come."
  ),
  person(
    "right",
    ruby,
    "Ruby Hoeger",
    {
      school: "West Valley High School",
      grade: "senior",
      yearInUnleashed: "third"
    },
    "Fettuccine Alfredo.",
    "I cannot believe this is already my third and final year in Unleashed! As time has progressed, I\'ve fallen more and more in love with writing. My interests are widespread: I am involved in ASB, play on my school\'s golf team, am vice president of National Honor Society, volunteer for the Yakima Humane Society and the YWCA, and am a part of YouthWorks. In my free time, I enjoy playing the piano, walking my dog, baking, and spending time with my friends."
  ),
  person(
    "left",
    maria,
    "Maria Jacobson",
    {
      school: "Highland High School",
      grade: "senior",
      yearInUnleashed: "third"
    },
    "Flank steak with chimichurri sauce. I really love the great and unique taste of the chimichurri, especially when you add a perfect steak with it. You can make the chimichurri sauce by looking it up online, but it\'s basically a bunch of olive oil, vinegar, garlic, cilantro, and parsley. It\'s a quick and easy dinner that is always pure satisfaction after a long day of school.",
    "Hello! I love drawing, swimming, and watching TV shows and anime. I really love music, especially classic rock. Some of my current favorite songs are \"What is Life\" by George Harrison, \"Mama, I\'m Coming Home\" by Ozzy Osborne, and \"Only the Good Die Young\" by Billy Joel. I would definitely recommend all those songs. I also like playing video games, and some of my current favorites are Pokémon Ultra Sun, Story of Season (2014), and Danganronpa V2. I really like being in Unleashed because I get to review TV shows and movies, write articles about important issues, and create colorful art, all in one! I hope to have a great year and an even better graduation.",
    COURTESY
  ),
  person(
    "right",
    natalie,
    "Natalie Keller",
    {
      school: "Selah High School",
      grade: "senior",
      yearInUnleashed: "fourth"
    },
    "My mom\'s potato soup. Although this hot, creamy dish is typically considered a wintertime food, I am known to request potato soup year-round.",
    "The time to write my fourth and final annual Unleashed bio has come shockingly quickly. A lot has changed since my freshman year of high school, most notably my ever-expanding list of extracurricular activities. At school, I am involved in DECA, FCCLA LifeSmarts, National Honor Society, Link Crew and Senior Council, and I am a member and captain of the SHS Cheer Team. I am also the editor of Selah High School\'s newly created school newspaper, The Viking Voice, and will be the choreographer for the SHS Drama Club. Plus, I was recently selected to act as a student representative on the Selah school board. Outside of school, I take dance lessons in styles such as ballet, pointe, lyrical and jazz. Since this is, sadly, my final year of Unleashed, I look forward to finishing strong with some entertaining and informative articles.",
    COURTESY
  ),
  person(
    "left",
    maham,
    "Maham Khan",
    {
      school: "West Valley Virtual Academy",
      grade: "sophomore",
      yearInUnleashed: "second"
    },
    "Butter chicken with naan. Butter chicken is chicken in a really creamy curry. Everything is so soft and delicious; it\'s like a wonderland in your mouth.",
    "I\'m someone who enjoys trying new things, and Unleashed has been one of the best things I\'ve tried so far. I also really enjoy public speaking; I love everything about the experience. During my free time, I like to read, paint and spend time with family and friends. I also take an interest in keeping up with current events around the world and locally. I\'m excited to be a part of a team that will help you get the high school student version of the latest events."
  ),
  person(
    "right",
    anabelle,
    "Anabelle Kollman",
    {
      school: "Eisenhower High School",
      grade: "senior",
      yearInUnleashed: "fourth"
    },
    "It\'s a chilly autumnal morning as I write this, so I\'m going to have to go with soup and focaccia.",
    "This is my fourth and last year of high school and my fourth and last year of Unleashed. Here are some things I\'ve discovered during those four years: I believe that I was created by a loving God, Triscuits are better than Wheat Thins, listening to audiobooks counts as reading, and a good night\'s sleep can fix a lot of problems. I\'ve learned that asking for help is a valuable skill, you don\'t have to be good at your hobbies as long as you enjoy them, time with family is irreplaceable, and tofu can actually taste really good. I tried surfing, went to dances, hiked 21 miles in a day, visited Disneyland, and lived through a pandemic for the first time. I\'ve written articles about Christmas songs, Bob Ross, my 99-year-old neighbor (now 100), and YouthWorks pageants. As I finish out these four years, I hope to write even more articles, earn my AA degree through the Running Start program, continue hiking as much as I can, prioritize time with my family, and go to my first haunted house.",
    COURTESY
  ),
  person(
    "left",
    abi,
    "Abi Longbottom",
    {
      school: "Naches Valley High School",
      grade: "senior",
      yearInUnleashed: "second"
    },
    "Open-faced broiled vegetable bagel sandwiches.",
    "As this is my final year of high school and Unleashed, I hope to make it a memorable one! After gaining more experience with writing for the newspaper, I have found a passion in writing editorial pieces. I enjoy delving into the issues of social justice and advocacy. After high school, I hope to pursue my dream of becoming a human rights lawyer."
  ),
  person(
    "right",
    whit,
    "Whit Peters",
    {
      school: "Selah High School",
      grade: "junior",
      yearInUnleashed: "second"
    },
    "Pulled pork sandwiches with marinated onions.",
    "I am excited to return to Unleashed for my second year as a journalist, photographer and videographer. I enjoy covering local events and adventures in road and mountain biking, hiking, high school sports and travel. I spent nearly five months living abroad in Norway last year and hope to write about my travels this year, as well. I am also interested in politics and social issues, so expect to see some editorials from me throughout the year, especially as we approach an election cycle. I see Unleashed as a perfect outlet to share with the Yakima Valley my interests and passions as a high school student."
  ),
  person(
    "left",
    olivia,
    "Olivia Rankin",
    {
      school: "Eisenhower High School",
      grade: "senior",
      yearInUnleashed: "third"
    },
    "Batata soufflé",
    "I\'m a big fan of crocheting, sewing, reading, somewhat complicated coffee orders, fuzzy socks and fall. I\'ll never say no to chocolate and I\'m a firm believer in the positive power of weighted blankets."
  ),
  person(
    "right",
    jillian,
    "Jillian Strother",
    {
      school: "Riverside Christian School",
      grade: "sophomore",
      yearInUnleashed: "second"
    },
    "You could probably call my favorite home-cooked meal a \"Thanksgiving Starter Pack\" — turkey, mashed potatoes, corn, stuffing and green beans, all covered in gravy. With some sort of pie or baked goods for dessert, of course. I have so many great memories of gathering with my family at my grandma\'s house on Thanksgiving and Christmas, which has a similar menu.",
    "I love to write, whether it be angsty poetry and song lyrics, or TV show and movie reviews. I love being able to express my thoughts and voice my opinions using words. I enjoy reading, too. My favorite genres are crime fiction, fantasy, and the occasional sci-fi novel. I spend a lot of my spare time listening to music or making music — most of the time choosing songs by Taylor Swift, Olivia Rodrigo and Conan Gray.",
    COURTESY
  )
];
