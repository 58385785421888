import { ReactNode } from "react";
import { ReactComponent as Award } from "./award-fill.svg";
import { Article, Image, JSONDSL } from "../../articleInterfaces";
import { urlForArticle } from "../..";
import { DisplayManyAuthors } from "../../Authors";
import { Link } from "react-router-dom";
import { DateDisplay } from "../../../shared/DateDisplay";
import style from "./index.module.scss";
import sharedStyle from "../../../shared/style.module.css";
import { useAppSelector } from "../../../store";

function ListingImage(props: Image) {
  return (
    <img
      src={props.src}
      alt={props.caption}
      title={props.caption}
      className={style.listingPhoto}
    />
  );
}

interface BadgeProps {
  show: boolean | undefined;
  title: string;
  children: ReactNode;
}

function Badge(props: BadgeProps) {
  if (!!props.show) {
    return (
      <span className={style.honor} title={props.title}>
        {props.children}
      </span>
    );
  } else {
    return null;
  }
}

function Badges(props: Article) {
  return (
    <div className={style.badges}>
      <Badge
        show={props.authorsChoice}
        title="A curated list of the author's favorite articles"
      >
        Author's Choice
      </Badge>

      <Badge
        show={props.spotlight}
        title="A spotlighted article on the Yakima Herald's website"
      >
        Spotlight
      </Badge>

      <Badge
        show={props.topStory}
        title="An honor given to an article for its popularity on the Yakima Herald website"
      >
        Top Story
      </Badge>

      <Badge
        show={props.editorsPick}
        title="An honor given by the newspaper's editor"
      >
        Editor's Pick
      </Badge>

      <Badge
        show={!!props.award}
        title="Awarded by either the group's coordinator or voted on by Unleashed's members"
      >
        <Award /> {props.award}
      </Badge>

      <Badge
        show={props.featured}
        title="Designates an article that was featured on the Yakima Herald's website"
      >
        Featured
      </Badge>
    </div>
  );
}

function filterImagesFromJSONDSL(dsl: JSONDSL): Image[] {
  let images: Image[] = [];

  function searchForImages(item: JSONDSL) {
    if (Array.isArray(item)) {
      item.forEach(subItem => searchForImages(subItem));
    } else if (typeof item === 'object' && 'src' in item && 'caption' in item) {
      images.push(item);
    } else if (typeof item === 'object' && 'content' in item) {
      searchForImages(item.content);
    }
  }

  searchForImages(dsl);

  return images;
}

function getCoverImage(article: Article) {
  if (!!article.images?.length) {
    return article.images[0];
  } else {
    return filterImagesFromJSONDSL(article.content).shift();
  }
}

export function Listing(props: Article) {
  const isMobile = useAppSelector((state) => state.responsive.isMobile);

  const coverImg = getCoverImage(props);

  return (
    <Link
      to={`/unleashed/${urlForArticle(props)}`}
      className={`${sharedStyle.linkoverride} ${isMobile ? style.mobile : style.desktop
        }`}
    >
      <div className={style.listing}>
        {!!coverImg ? <ListingImage {...coverImg} /> : <div />}
        <article className={style.articleContent}>
          <p
            className={style.articleType}
            title={`An article that originally appeared in the Yakima Herald's ${props.articleType} category`}
          >
            {props.articleType}
          </p>

          <Badges {...props} />

          <p>
            <b>{props.title}</b>
          </p>
          <p>
            By <DisplayManyAuthors authors={props.author} />
          </p>
          <p>
            <i>
              <DateDisplay date={props.date} />
            </i>
          </p>
        </article>
      </div>
    </Link>
  );
}