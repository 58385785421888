import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import clipping from "../resources/may-11-24.pdf";
import mom from "./mom.jpg";
import carsonfamily from "./carson-family.jpg";

const MAGNUS: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "senior"
};

const CARSON: Author = {
  name: "Carson VanEaton",
  school: "East Valley High School",
  grade: "junior"
}

const article: Article = {
  articleType: "column",
  author: [MAGNUS, CARSON],
  date: new Date(2024, 4, 11),
  title: "Mom's most memorable motherhood moments",
  editorsPick: true,
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/moms-most-memorable-motherhood-moments/article_24d8f0ab-495b-5beb-9d82-442f1ae4fe86.html",
  clipping,
  content,
  images: [
    {
      caption: "Axel Fulton, left, and Magnus Fulton, right, listen as their mom, Erin Fulton, shares a moment with them of \"Tales of a Fourth Grade Nothing\" by Judy Bloome.",
      credit: MAGNUS,
      src: mom
    },
    {
      caption: "Carson VanEaton, Nicole VanEaton, Aubrey VanEaton, David VanEaton, and Brielle VanEaton at Leavenworth Adventure Park.",
      credit: "Photo courtesy of Pam Pauling",
      src: carsonfamily
    }
  ]
}

export default article;