import { TransactionAction } from "../store/common";

interface FinanceProps {
  amount: number;
  type?: TransactionAction;
}
export function Finance({amount, type}: FinanceProps) {
  let sign: string;
  switch (type) {
    case "withdrawl":
      sign = '-';
      break;

    case "deposit":
      sign = '+';
      break;
    
    case undefined:
      if (amount !== 0) {
        sign = Math.sign(amount) === 1 ? '+' : '-'
      } else {
        sign = '';
      }
      break;
  }

  return (
    <>
      {sign}
      ${Math.abs(amount).toFixed(2)}
    </>
  )
}