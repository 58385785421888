import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "The weather change has been drastic and extremely fast, not giving us enough time to go through all our colder-weather clothes or even pull them out of storage. So here are some favorite transitional clothing pieces that you might already have out as well as some great tips offered up by a few of our fashion-forward Unleashed staffers.",
  [
    {
      format: "section",
      content: "The cardigan"
    },
    "One of my personal favorite clothing items is the cardigan. There are several reasons why they\'re endlessly versatile: They come in all lengths, yarns, knit/ crochet patterns, colors and more. They can be a perfect reflection of your mood, a fantastic accessory, an ultimate comfort item and, if you\'re a Swifty, they\'re an obvious must.",
    "But back to why they\'re the perfect fall transition garment. During the summer when most of us spend our time in tank tops, cardigans serve as a saving grace when it gets windy or as chilly nights outdoors start to get at us.",
    "Once it starts entering fall, though, they really take the stage. As days get colder they\'re perfect over a T-shirt, blouse and even a button up. They\'re great to lounge around the house in, they\'re school dress code appropriate, and they seriously look good on everyone! They could even be your next crochet or knit project.",
    "The cardigan sweater is a timeless piece that never disappoints and certainly won\'t let us down this coming year.",
    {
      format: "byline",
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "senior"
    }
  ],
  [
    {
      format: "section",
      content: "The shacket"
    },
    "If you\'re like me, then you hate the awkward transition between summer and fall when it comes to fashion and style. Is it now too cold for a short sleeve? Is it still too warm to wear a hoodie?",
    "These questions are a daily concern for teens and young adults trying to find stylish attire for this in-between season. The good news is that I\'ve found the perfect piece of clothing to add to your wardrobe this fall season: The shacket.",
    "A shacket is a slightly oversized shirt-jacket that is everywhere on the internet. The shacket is the perfect layering piece through all seasons by providing comfort and warmth, regardless of the time of year.",
    "As we go into fall, make sure to be on the lookout for these stylish and comfortable alternatives; they are a must-have in your wardrobe for the coming seasons.",
    {
      format: "byline",
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  [
    {
      format: "section",
      content: "The two-part ensemble"
    },
    "I\'ve been waiting for this moment for three months now. Finally, the leaves have begun to align with the accent colors of a shirt I\'ve been itching to wear.",
    "Fall presents an interesting challenge. Its days are temperate and beautiful. But dare step outside and you will either be scorched by the sun or frozen by tundra-like winds. Thankfully, society has presented us with a versatile solution for any occasion: a T-shirt and sweatpants.",
    "The sweatpants are the easy part — I just choose at random from my floor or drawer — but the T-shirt is another problem entirely.",
    "My shirt of choice is actually one I stole from a friend — the Curbside-branded \"Roasting Raccoon\" shirt, drawn by Mandy Heck. Something about this shirt — a raccoon roasting a marshmallow by a campfire — just really spoke to me, so I bought one for myself. Heck\'s design also works well with the season, too, given that it has such a calm and relaxed feeling to it.",
    "However, the hot and cold whiplash of fall also demands a certain level of practicality from your clothing, no matter how cool it looks. My shirt of choice is able to strike a nice balance. It is warm and soft, serving as a buffer against the cold for short outdoor excursions, much like the blanket that features prominently in its design. But it is also cool and comfortable enough to wear inside.",
    "The design itself is well constructed and integrated into the fabric, unlike other designed shirts that I own.",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "The vest"
    },
    "Let me start out by saying this: I love fall. I love sweaters, crunchy leaves and, of course, the cozy outfits. It\'s the perfect time for sweater weather and tea.",
    "That\'s why my go-to fall formula is a comfy sweater with straight-leg pants. It\'s simple enough to add a bit of spice without doing too much work. Then, of course, you leave the rest to accessorizing.",
    "But to focus more on a specific article of clothing that I always reach for around this time, mine would have to be a vest. People who know me know how much I love vests, and it\'s usually a little something I add to top off an outfit. There is so much flexibility and range with vests because, no matter the weather, you can easily layer.",
    "Long sleeves and hoodies pair perfectly under a simple gray vest. Or if I want to make a statement, I\'ll go for a colored vest with a simple outfit. It\'s a great way to incorporate a pop of color into my personal style.",
    "So, if you\'re looking for a simple way to transition your wardrobe into winter attire, invest in a vest!",
    {
      format: "byline",
      name: "Ellie Suhm",
      school: "Eisenhower High School",
      grade: "freshman"
    }
  ]
];