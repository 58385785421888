import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    "March 14 was a milestone date for high school students throughout the Yakima Valley. After nearly two years of being required to wear face coverings in schools, that Monday marked the first time in which \"masking up\" became optional for both school staff and students.",
    "As the state ended its mandate for masking in most public places, local students were faced with a daunting personal decision: whether or not to continue wearing a mask at school. They also felt a gamut of emotions regarding the lack of face coverings.",
    "For many students, finally seeing the lower halves of their peers\' and teachers\' faces was a breath of fresh air.",
  ],
  [
    {
      format: "section",
      content: "At Selah High School:"
    },
    "\"I honestly really like being able to see my teachers\' faces,\" said Selah High School junior Ella Andrews. \"I feel kind of more connected to them, honestly, when I can see their face better.\"",
    "At Selah High School, the vast majority of students and teachers opted during that first week to leave their face coverings behind, with a sprinkling of people choosing to remain masked.",
    "Selah High School junior Evan Fresco is among that latter group of students.",
    "\"I believe that it is personal choice to wear a mask; however, I believe that it could also help to wear masks a little bit more, just in case,\" Fresco said. \"I still believe that it won\'t hurt to wear a mask.\"",
    "Despite Andrews\' and Fresco\'s differing thoughts regarding masks, the two Selah students share a common thread when it comes to the value of personal decision making; both students acknowledge and respect others\' rights to make their own choices.",
    {
      format: "byline",
      name: "Natalie Keller",
      grade: "junior",
      school: "Selah High School"
    }
  ],
  [
    {
      format: "section",
      content: "At West Valley High School:"
    },
    "For a day that was so significant, March 14 came and went without a hitch at West Valley High School.",
    "Some students already felt a difference in the building. \"I feel that people are more expressive now and have more energy. It was almost like the mask contained them,\" said freshman Ethan Hatfield.",
    "Paul Lally, a freshman, felt the same way.",
    "\"A barrier was lifted. People are more social and talkative now,\" Lally said.",
    "Trenton Durham, a sophomore, agreed.",
    "\"With masks, it was like having a filter over your face. Without masks, it feels like a real high school again. A lot of people are happy to get back to normal life,\" Durham said.",
    "\"A lot more people are saying \'Hi\' now,\" said freshman Truman Reiersen. \"Overall, people are more friendly, social, and in a better mood.\"",
    "Reiersen also estimated that about 10% of students were still wearing masks that first week. Hatfield and Reiersen also reported all but one of the teachers in each of their course schedules have chosen not to wear masks while in class. Skylar Fernandez, a sophomore, noticed similar percentages on the bus rides to school, saying around 20% of the students were masked on some days, as was the bus driver.",
    "As far as the student acceptance of maskers vs. non-maskers goes, Hatfield said that he\'s heard some negative comments and that some people have been judgmental.",
    "And, even with the mask mandate lifted, students acknowledge that things have not reached the \"all-clear\" yet.",
    "\"I thought more people would wear a mask,\" said Hatfield. \"COVID is still here, and if someone has COVID, we\'re all going down.\"",
    "The school made some changes in response to the end of the mask mandate, as well. The lunch tables once again face toward each other instead of being placed in rows, a move that many students were excited about.",
    "The school also ran an educational campaign about respecting people\'s choices to wear or not wear a mask. The school put up posters and encouraged teachers to remind their students that masking is now a personal choice and to not judge their peers for their choice.",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  [
    {
      format: "section",
      content: "At Riverside Christian School:"
    },
    "The vast majority of students and teachers at Riverside stopped wearing masks after the mandate was lifted, allowing them to see peoples\' smiles again. Many felt the general morale of the students and faculty has gone up with the end of the masking requirement.",
    "\"I\'m glad mask wearing is finally everyone\'s choice, like relative to each person,\" Riverside Christian senior Ellie Perkins said. \"I think this is the proper time to lift the mask mandate because I think we\'re coming to the end of the major part of the pandemic, so trying to go back to a sense of normalcy seems like a good idea.\"",
    "Gretchen Perkins, the school\'s high school history teacher, is also happy about the end of the mask requirement, saying it\'s \"so freeing not to think if I have it on me.\"",
    "Although it doesn\'t fully seem like things are back to normal quite yet, the general feeling among students and staff is that some headway is being made toward some normalcy.",
    {
      format: "byline",
      name: "Kate Bethel",
      grade: "senior",
      school: "Riverside Christian School"
    }
  ]
];