import { Article } from "../../articleInterfaces";
import { content } from "./content";

import jan28 from "../resources/jan-28-24.pdf";

const article: Article = {
  title: "Off The Chain: Talk To Me",
  articleType: "review",
  //This article had one of the longest lead times between being turned in and 
  //seeing print that I remember. I sent this one off around September 24th, 
  //so we'd have time to get other Off The Chain "spooky" pieces.
  //Interestingly enough, I wrote this article almost entirely in my notebook 
  //before heading off to the Central Washington Fair ("Farewell to the 2023 
  //Central Washington Fair", Oct. 1 2023) and so it has the record of one of 
  //the quickest articles I've ever written as well. 
  //I also wrote "Lessons in journalism from Bob Woodward" before heading to the
  //fair as well in similar, but arguably more ironic, fashion. 
  date: new Date(2024, 0, 28),
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  clipping: jan28,
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/off-the-chain-unleashed-picks-for-january/article_4fa06c1b-e180-5f30-bbdf-43c6623833b7.html",
  content
}

export default article;