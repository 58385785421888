import { Article } from "../../articleInterfaces";
import { content } from "./content";
import cooper from "./cooper.webp";

const article: Article = {
  title: "How a NASA planetary protector is helping in the exploration of Mars",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  articleType: "news",
  content,
  date: new Date(2022, 11, 11),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/how-a-nasa-planetary-protector-is-helping-in-the-exploration-of-mars/article_d63ae4f8-b246-5cec-83d9-e51fb50ba2a9.html",
  images: [
    {
      caption: "NASA's Dr. Moogega Cooper and Magnus Fulton.",
      credit: "Kirsten Fitterer",
      src: cooper
    }
  ],
  authorsChoice: true
};

export default article;