import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "A look back at 2022 from the Unleashed staff:",
  [
    {
      format: "section",
      content: "No more mask mandate"
    },
    //This is what happens when you let me write stuff while staring 
    //philosophically out over the ocean. Interestingly enough, I was on a 
    //cruise at the time and as soon as I finished this article, my phone pinged
    //off a cell tower somewhere just enough for me to send an email. This was
    //especially cool to me because I had bought a local eSIM and it was another
    //excuse to use it. I'm pretty sure I composed this article on my phone too,
    //but I can't remember for certain.
    "On March 14,the West Valley School District and others ended the lengthy mask mandate that had been enacted to slow the spread of COVID-19.",
    "The first day back was marked by an uneasy tension. Do I mask? Do I not? What will my friends and teachers do? What\'s right for me, and what will fit in?",
    "It was a yes-or-no question with so many implications. Some of my friends and teachers stopped masking right away. Some never stopped.",
    "Every week was bookended by a cautious reading of the school\'s weekly COVID report. Cases went up and cases went down, but we were holding our breath the whole time. Through it all was a nervous excitement. Were we finally seeing the light at the end of the tunnel?",
    "We left behind a world we once knew back in March 2020. Two years later, we left behind another. The new world we were entering looked similar to the one we lost back in 2020. It marked another shift in everything we had grown accustomed to, not a return to something familiar. This new world would surely have its ups and downs, laughs and sorrows. But, most important, it had hope and potential.",
    "Today, on this 1st of January 2023, we once again enter a new world full of potential, hope, love, hate, happiness, sorrow, fear and excitement. We enter a new world ready to be shaped into whatever we make it.",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "How about those Mariners?"
    },
    "After 21 years of dismal mediocrity, the Seattle Mariners ended the longest playoff drought in major North American sports.",
    "With a young lineup and stellar pitching, the Mariners came back from a wretched start to the season, even winning 14 games in a row at one point. Their success was driven in large part by 21-year-old rookie center fielder Julio Rodriguez, who was named Rookie of the Year — a deserving accolade for his stellar defense, high batting average and 28 regular season home runs. Rodriguez also took part in the Home Run Derby, finishing as runner-up and elevating his status as a team leader.",
    "Newly acquired pitcher Luis Castillo led the staff, bolstered by consistently solid performances from Logan Gilbert, Marco Gonzales, Chris Flexen and Robbie Ray.",
    "The real magic of the Mariners was shown in the late innings, specifically in close games. They always seemed to have the ability to claw within reach of the win, and somehow pull it off.",
    "This consistency and drive were shown especially in the team\'s second playoff game, in which Seattle overcame a seven-run deficit to beat the Toronto Blue Jays with clutch hitting from the entire lineup. As in the regular season, Carlos Santana delivered big power, Cal Raleigh came in clutch, Ty France demonstrated solid consistency, and Adam Frasier was calm under pressure.",
    "While the Mariners did eventually fall to the dominant Houston Astros in the American League playoffs, they put up an incredible fight, nearly topping the World Series winners in the first game and stretching the final contest to 18 innings.",
    "This was a season of incredible emotions for Mariners fans. With the recent signings of multiple offensive standouts, the Mariners will surely be strong again in 2023.",
    {
      format: "byline",
      name: "Whit Peters",
      school: "Selah High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "The World Cup"
    },
    "There were many great sports adventures throughout 2022, but one celebrated all over the world happens to come once every four years.",
    "Since this will be the one and only time that my peers and I will ever experience a World Cup in high school, it was interesting in being exposed to an event like this. After all, this is history in the making. Many teachers were able to navigate lessons while encouraging students to be involved and updated on scores of the games that took place during school hours. The collective gasps and cheers in the lunchroom while the games were displayed on the big screen was something I will never forget.",
    "For 2022, the World Cup was held during December, straying from the tournament\'s traditional summer timing. This was a positive thing, as a lot of the people I know connected on things like game scores or who they should root for.",
    "France was the returning champion from 2018 and made it to the final against Argentina. The Dec. 8 match in Qatar was not a boring one, with a total of six goals between the two teams. Argentina\'s win that day was well deserved, as the team led most of the game. But by the 82-minute mark, France had tied it up. The game was settled when Argentina\'s Gonzalo Montiel scored the winning penalty shot, giving his team its third World Cup.",
    {
      format: "byline",
      name: "Ellie Suhm",
      grade: "freshman",
      school: "Eisenhower High School"
    }    
  ],
  [
    {
      format: "section",
      content: "Taylor Swift"
    },
    "One major event from 2022 was the release of Taylor Swift\'s album \"Midnights\" and the subsequent announcement of her Eras Tour.",
    "Tickets for the tour were sold entirely on Ticketmaster in November. The process involved a verified fan feature, in which people could sign up to possibly get a code to have the ability to attempt to get a ticket during the very first presale.",
    "Sounds confusing? It was.",
    "The entire sale was a dumpster fire. People waited in a virtual queue for hours just for the site to glitch and put them back at the end of the line. The whole verified fan process was made so fans could get tickets, not bots. Still, bots got many tickets and those are now being sold for absolutely crazy prices.",
    "Some (actual) people who signed up for the verified fan presale didn\'t get tickets. Then, on Nov. 17, Ticketmaster announced the next sale of tickets would be canceled because the company couldn\'t meet the demand.",
    "Overall, the whole process could have been a lot simpler. Ticketmaster promised that its website could handle the traffic, but it couldn\'t.",
    "Taylor Swift issued a statement on Instagram, saying it was \"excruciating for me to just watch mistakes happen with no recourse\" and \"It\'s truly amazing that 2.4 million people got tickets, but ... a lot of them feel like they went through several bear attacks to get them.\"",
    "Fans, like me, were left dejected, hoping that a professionally recorded version from the upcoming concert tour will be released.",
    {
      format: "byline",
      name: "Maisy Kollman",
      grade: "freshman",
      school: "Eisenhower High School"
    }    
  ],
  [
    {
      format: "section",
      content: "Documentaries"
    },
    "2022 was my introduction to documentaries. I started with \"Downfall: The Case Against Boeing\" (Netflix\'s deep dive into the two 737 Max crashes that killed 346 people) and finished with \"Harry & Meghan\" (a complete recollection of the royal couple\'s trials and tribulations thus far), with dozens of religious, true-crime and world news series in between.",
    "December\'s \"Harry & Meghan\" docuseries begins by recounting the details of Meghan\'s early life and career, with interviews from her mother, niece and several close friends. It then follows Harry and Meghan\'s relationship through their marriage, including Archie\'s birth and their departure from the royal family.",
    "Most important, however, is the topic of personal privacy. According to the series, Meghan suffered repeated bullying, judgment and even racism from the British tabloids — and none of it was countered by the palace.",
    "With that said, the documentary feels more like the personal story of the Duke and Duchess of Sussex than any sort of expose. The importance of truth and transparency shines through when the couple sits on the couch in their Santa Barbara, Calif., home, setting the record straight.",
    "The \"Harry & Meghan\" docuseries can be streamed on Netflix in the form of six hourlong episodes.",
    {
      format: "byline",
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "Books"
    },
    "2022 has been a year of many excellent book releases, but there are two in particular that have stood out: \"Katzenjammer\" by Francesca Zappia and \"The Storyteller\" by Kathryn Williams.",
    "\"Katzenjammer\" is a young adult horror novel in which the characters are trapped in their high school; it is told in dual timelines. In the earlier timeline, the characters want to escape their common high school life. Then after becoming stuck, the characters battle to become free again. The tale brings up many dark topics, and one should be careful to research it before reading the book. But it is also a story that allows the reader to interpret it in many ways, making each read a new adventure.",
    "\"The Storyteller\" is a contemporary young adult novel centering around a girl, Jess, who finds her great aunt Anna\'s hidden diaries. They are all written in Russian; so, in order to read them, she hires Evan, a local college student who is learning the language. As they continue to read, they realize there is a possibility that Jess\' great aunt might be Russia\'s lost Princess Anastasia.",
    "Both of these novels are amazing and underrated reads.",
    {
      format: "byline",
      name: "Rebecca Lommers",
      school: "Riverside Christian School",
      grade: "senior"
    }    
  ],
  [
    {
      format: "section",
      content: "Six sad songs"
    },
    "Joshua Bassett released \"Sad Songs in a Hotel Room\" in September. The first of its six tracks, the title song, paints a picture. Bassett tells of returning to a place that held memories of an old relationship.",
    "As he sings, he ponders all the things that have changed. He recalls memories by reliving them: wearing a robe in a hotel room, drinking champagne from the mini bar, looking through travel magazines, and ordering room service (now for one). As the title says, he sings a sad song in a hotel room. But, wait … the lyrics say \"songs\" plural.",
    "One of my friends suggested to me that maybe these six tracks are the sad songs he sings in the hotel room. Whether or not Bassett intended for this to be the case, it\'s an interesting thought.",
    "And it\'s almost a more immersive experience to listen to the EP while picturing a boy sitting alone in a hotel room, pondering his recent experiences and encountering a range of emotions and sentiments. All of these are conveyed in the tracks: from sadness and loneliness to realization and hurt, and from grief and longing to acceptance and introspection.",
    {
      format: "byline",
      name: "Jillian Strother",
      grade: "junior",
      school: "Riverside Christian School"
    }
  ],
  [
    {
      format: "section",
      content: "'Stranger Things'"
    },
    "Last summer, Netflix users tuned in to watch the much-anticipated fourth season of \"Stranger Things,\" amassing a staggering 1.4 billion hours of viewing time.",
    "Once again, viewers were transported back to the 1980s and watched as supernatural mayhem unfolded in the fictional town of Hawkins, Ind. After hearing so many positive remarks about the series, I decided to hop on the \"Stranger Things\" bandwagon and watch it from the beginning.",
    "The fourth season certainly lived up to the program\'s reputation. Its creators did an excellent job of blending several plots while piquing viewers\' interest from the get-go. As always, the large cast of characters was extremely likeable and believable.",
    "What really stood out to me, though, is how \"Stranger Things\" has revitalized pop culture from the \'80s. For example, after being featured in several \"Stranger Things\" episodes, Kate Bush\'s 1985 song \"Running Up That Hill (A Deal With God)\" was swiftly catapulted into the limelight.",
    {
      format: "byline",
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "senior"
    }    
  ]
];