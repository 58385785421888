import { Article } from "../../articleInterfaces";
import { content } from "./content";

import theater from "./theater.jpg";
import ella from "./ella.jpg";

const MAGNUS = "Magnus Fulton, West Valley High School / Unleashed";

const article: Article = {
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  content,
  title: "The great debate: Red Vines vs. Twizzlers",
  date: new Date(2022, 8, 18),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/the-great-debate-red-vines-vs-twizzlers/article_a9388abf-2614-51b8-88bb-676400ced009.html",
  images: [
    {
      src: theater,
      caption: "Red Vines or Twizzlers? Just outside Yakima Cinema, the battle for your taste buds has just begun.",
      credit: MAGNUS
    },
    {
      src: ella,
      caption: "Ella Steinhilb of Eisenhower High School tackles the delicious dilemma of Red Vines vs. Twizzlers.",
      credit: MAGNUS
    }
  ]
};

export default article;