import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Jeremy Hernandez of Terra Essentials was happy to see the Downtown Yakima Farmers Market move from its old spot by The Capitol Theatre to its new home at 15 W. Yakima Ave.",
  "\"I\'ve really seen an influx of people since we moved to the new location,\" Hernandez said.",
  "The Downtown Yakima Farmers Market started its 2023 season at its old site on May 14, then relocated June 25 to Rotary Marketplace on June 25.",
  "With ample parking and variety of businesses nearby, people can now easily stop by McDonald\'s, North Town Coffeehouse, Cashmere Valley Bank or one of the ATMs nearby before or after their trip to the market.",
  "But the real seller is the shade. The previous location by the Capitol Theatre left shoppers and vendors baking in the sun.",
  "\"Most people were forced to leave after 10 minutes because of how hot it got,\" Hernandez said. \"Now, people can stay all day.\"",
  "From a vendor\'s perspective, Hernandez feels the new location is also much nicer for setup and takedown, because of the wider space as well as dedicated spots for vendors.",
  "The new location features a spacious metal pavilion built last year with support from the Yakima Downtown Rotary Club, Southwest Rotary and Sunrise Rotary. Vendors are set up on both sides of the venue, as well as the outside east edge. Live music is performed at the front, with portable toilets available toward the back at the east side. Parking is available adjacent to the venue as well as in a larger lot behind it. The market is also accessible via bus route 1.",
  "Every last Sunday of the month features a Kids Day before the market closes at 1 p.m. Kids Day features additional games and nonprofits in addition to the regular vendors and live music performances. Educational Service District 105 often gives away free books, with other organizations like the Master Gardeners, the Women League of Voters, Asian Pacific Islander Coalition, and others making appearances.",
  "\"This new location is so much better,\" said Sara Dawson of Uncle Sam\'s Kettle Corn.",
  "She said sales are up for her business, which got its introduction to the market in August 2022.",
  "\"The whole market feels more professional,\" she said, attributing a lot of that to the addition of the shade cover at Rotary Marketplace.",
  "After the switch, Dawson started noticing a lot more young families and people coming to the market after church. Several families also bring their dogs, which for Dawson is a welcome addition because she\'s also a dog person.",
  "Leashed dogs are welcome anywhere in the market.",
  "\"We pet a lot of dogs here,\" said Carol Minear of the information desk.",
  "But that\'s not the only service the information desk provides.",
  "The desk offers a program to help farmers reduce credit and debit transaction fees. Customers can skip the ATM by purchasing Market Tokens in $5 increments using their cards. These tokens are valid as cash at any vendor. However, vendors still gladly accept your own cards and cash.",
  "This program was introduced by the Downtown Yakima Farmers Market to address credit and debit card transaction fees, which typically range from 1% to 5% of the transaction amount, along with an additional fixed fee of 5 to 20 cents. This can be a considerable amount for smaller businesses.",
  "The help desk also runs a Market Match program, which allows individuals to exchange their EBT and SNAP benefits for coupons. These coupons receive matching funds from grants provided by the Dept. of Social and Health Sciences and can be used at any vendor selling produce.",
  "Yvette Lippert, the market\'s manager, emphasizes that the program aims to ensure community-wide access to the farmers market, even in the face of competition from larger retailers, while also bolstering local farmers.",
  "During Lippert\'s leadership, the program\'s impact has grown significantly, from initially representing $1,200 in transactions during one summer to more than $15,000 in recent years. On an average day, the program matches approximately $600 to $700.",
  "Those aren\'t the only promising numbers.",
  "\"We were seeing around 3,500 people a day (in front of the Capitol Theatre), but now we average around 5,500 people a day,\" Minear said.",
  "Lippert was pleased to see Yakima\'s three Rotary clubs come together to make the new market location a success. She believes this made vendors and performers feel like an asset to the community.",
  "\"The new location really has that talk and buzz to it,\" Lippert said.",
  {
    format: "section",
    content: "If you go"
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "Where: "
      },
      "Rotary Marketplace, 15 W. Yakima Ave. in Yakima."
    ]
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "When: "
      },
      "Sundays through Oct. 22 from 9 a.m. to 1 p.m."
    ]
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "Parking: "
      },
      "Available directly adjacent to the market, with a large additional lot behind the market."
    ]
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "Payment options: "
      },
      "Most vendors accept cash, card, and EBT/SNAP. Many items available for under $10."
    ]
  },
  {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: "Website, vendor map: "
      },
      {
        format: "link",
        content: "downtownyakimafarmersmarket.com",
        src: "https://downtownyakimafarmersmarket.com/"
      }
    ]
  }
]