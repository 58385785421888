import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "While it seems to be only a myth in these months of fog and ice, summer will eventually come around again. And, while no one really knows what the new year will bring, if all goes well I might be facing a new challenge: a summer job.",
  "But I also wanted to make sure I was starting my career on the right foot. So, this fall, I joined DECA, a technical and career student organization with a chapter at my high school. And joined not a moment too soon.",
  "I got a game-changing e-mail Oct 26. Unlike most of my e-mails, which warn me that I have yet another school assignment, this one was different.",
  "As a DECA member, I was eligible for a speed mentoring opportunity put on by Microsoft. I eagerly signed up. I met with three project managers and one software developer four days later via Microsoft's video conferencing software, Microsoft Teams.",
  "One of the very first things I learned from that experience was the power of trying as many new things as possible.",
  "I have always felt that the best way to know if you like something is to try it. I discovered my love of programming, mountain biking, rock climbing and running this way. Plus, you never who you will meet along the way.",
  "My mentors reminded me that real-life examples are powerful. Providing something to interact with or look at with real-world implications can be a lot more exciting and memorable than a collection of facts to memorize.",
  "My mentors also placed an emphasis on finding a good internship. This surprised me. Internships were always something that were on my radar, but I had never really understood the importance of pursuing one.",
  "My mentors underscored how essential school is to your career. But they also explained that while school teaches you how to approach different problems, internships show you how to apply this knowledge to real-life situations.",
  "I can understand why internships are so valuable. I always learn best when I get to apply what I know by building and creating something, instead of just answering questions on a worksheet.",
  "But through it all, one lesson prevailed: We are all in this together. So make sure you treat everyone with respect, empathy and kindness.",
  "Many of my mentors had stories of how networking changes the course of their careers. Others shared examples of the power of asking questions.",
  "But the underlying message was the same. You can not go through life alone, so do not be afraid to reach out when you need it and remember to help other people when you can.",
  "Whatever this new year brings, I am ready to put my best foot forward.",
]