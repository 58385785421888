import { Article } from "../../articleInterfaces";
import { content } from "./content";
import christmastree from "./christmastree.jpg";

const article: Article = {
  articleType: "column",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  content: content,
  date: new Date(2023,0,1),
  title: "O Christmas tree, O Christmas tree, whatever should I do with you?",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/o-christmas-tree-o-christmas-tree-whatever-should-i-do-with-you/article_1609beb9-dd16-5f02-afbb-8c145e9c2d7b.html",
  images: [
    {
      src: christmastree,
      caption: "Why let your tree go to waste? An old Christmas tree still has a lot of use left in it, from arts and crafts to gardening to neighborhood entertainment.",
      credit: {
        name: "Magnus Fulton",
        school: "West Valley High School"
      }
    }
  ],
  authorsChoice: true
};

export default article;