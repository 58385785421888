import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    {
      format: "section",
      content: "The great outdoors, together"
    },
    "Most of my favorite memories with my dad are set outside, and one of my all-time favorites is of one morning when I was 11. We were backpacking by the Crystal Lakes and Dad and I were the first ones awake. We crawled out of our tents, and he made two cups of instant coffee that we carried to the top of this big rock near the lake. It was a pretty cold and misty morning, so I drank the coffee even though I hadn\'t developed a taste for it quite yet.",
    "My dad and I are alike in that we prefer to listen more than talk when we\'re in the forest, so we just sat there together, listening to all the beautiful sounds of the morning and watching the sun burn off the fog until the rest of our family woke up and we continued our journey. I don\'t know how long we really sat there but, in my mind, it was hours that I sat curled up with his arm around me, feeling perfectly content.",
    "We\'ve done many more exciting and memorable things. But, for some reason, that peaceful morning has stayed with me so clearly as a special memory of me and my dad.",
    {
      format: "byline",
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "incoming senior"
    }
  ],
  [
    {
      format: "section",
      content: "The Taylor Swift song"
    },
    "My dad and I have made countless memories over the years, and music has been a part of many of them.",
    "Some of my favorite memories involve driving down the highway blasting Florida Georgia Line, Luke Bryan, and Dwight Yoakam CDs. Others include singing along to the Dixie Chicks and Taylor Swift in our garage.",
    "I don\'t ever listen to \"The Best Day\" by Taylor Swift because it makes me cry. But I still know its lyrics by heart. The bridge of the song goes: \"I have an excellent father. His strength is making me stronger.\"",
    "This line reminds me of how much my dad has done for me and continues to do — whether it\'s giving me encouragement and advice, helping me with school assignments, or just being willing to listen to me talk.",
    "To my dad: Happy Father\'s Day! I\'ve definitely had \"The Best Day\" with you.",
    {
      format: "byline",
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "incoming junior"
    }
  ],
  [
    {
      format: "section",
      content: "Different but still similar"
    },
    "Sometimes my dad and I couldn\'t be more different, but we are also often remarkably similar.",
    "I see this during evenings watching Washington State University football in Pullman and the starlit drives back home through the endless sagebrush. And during rainy afternoons sitting out on the back deck while throwing a tennis ball for the family dog, over and over and over again. Or while cooking dinner together (after my mom has had a long day), weaving through the kitchen and tossing ingredients back and forth.",
    "We are different, but we come back to singing the same songs on the way home from church. We are different, but we both scratch behind our dog\'s ears and feed her the same fish treats when she does tricks. We are different, but we will forever get excited about pink sunsets shining behind the rolling hills of the Yakima Valley.",
    "My dad has taught me that being different is wonderful, but finding things you can love together is even better.",
    "Here\'s to many more bookstore trips and dancing along to the WSU marching band in the middle of a crowd. Love you, Dad.",
    {
      format: "byline",
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "incoming junior"
    }
  ],
  [
    {
      format: "section",
      content: "Inspiring the get-it-done approach"
    },
    "My dad is a cardiologist and has worked long hours as far back as I can remember. Despite the time and effort he puts into his work, his devotion to our family has never waned. He always makes time to support me and my brothers, whether he\'s attending our soccer, basketball or flag football games, reviewing whatever essay or story I\'m writing at the moment, or just being there to talk with me.",
    "I think because he\'s used to having limited free time, when there\'s something to do around the house, he has to get it done as soon as possible. It\'s sort of like an itch he has to scratch.",
    "Part of me admires this itch, because I know it\'s something I don\'t possess. I\'m a procrastinator. I wish that I was just compelled to do every assignment, chore, or other obligation as soon as it was asked of me. But that\'s just not the way I operate.",
    "The other part of me, the part that wishes I could sit in bed all day, finds this trait of his very annoying. My dad doesn\'t just do tasks around the house and move on. He\'s also a sore winner, and he\'ll tell you that himself. When he wins a family board game, he often does a victory dance, and, at 46 years old, he won\'t hesitate to talk trash to me when we play basketball.",
    "So within every \"I moved your laundry to the dryer\" or \"I did the dishes for you this time,\" there\'s an implied \"You didn\'t do it, so I did.\" Although this can be frustrating, I know that it\'s made me more productive over time. I can\'t help but be thankful for it.",
    {
      format: "byline",
      name: "Lucas Cone",
      school: "West Valley High School",
      grade: "graduate"
    }
  ],
  [
    {
      format: "section",
      content: "Road trip with a joke app"
    },
    "\"What does a baby computer call his father?\" a younger version of myself asked my father, awaiting the answer reveal.",
    "\"I have no idea,\" my dad said, making me feel like the most intelligent child in the world.",
    "\"Data!\"",
    "The car shook with laughter. Even though my dad had heard the same jokes the entire three-hour ride, he still laughed and made me feel like I was on the top of the world.",
    "We walked into the hotel at Dayton, and eventually headed to the pool. Dad agreed to jump in with me, even though it was going to be beyond freezing. After two hours in the freezing water, we decided to go to our favorite restaurant, Ray\'s Drive In. The next morning, we drove up to the slopes and spent the day going up and down our all-time favorite ski runs. Then, after two days at Dayton, it was time to come home.",
    "\"Dad, what do you call a fish wearing a bow tie?\"",
    "\"I have no clue, Lexi,\" my dad said on repeat the entire ride home.",
    "\"Sofishticated!\"",
    "Though we may be getting older and the jokes have been heard several times at this point, we still laugh in unison whenever I break out my joke app from another time. Our Dayton ski trip became an annual tradition that we still have to this day and will for years to come.",
    "I can assure you that the joke apps can still be found on my phone and are only in use once a year, on our way to Dayton.",
    {
      format: "byline",
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "incoming senior"
    }
  ],
  [
    {
      format: "section",
      content: "A driving force"
    },
    "When I think of my dad, I think of a collection of smaller, everyday moments. I think of how we share a common interest in a variety of movies, namely \"Star Wars.\" He was the one who introduced me to the film franchise at a young age, and ever since then, we\'ve watched all the movies together and often discuss where we think the saga is headed next.",
    "I think about how he taught me to drive, patiently helping me go from meandering through a parking lot at 5 miles per hour to conquering the freeway.",
    "I think about how we used to sing Weird Al Yankovic\'s Star Wars-themed parody of \"American Pie\" on the drive to school every morning.",
    "And, on a more serious note, I think about all the times he\'s been a supportive, guiding figure in my life.",
    "Happy Father\'s Day, Dad!",
    {
      format: "byline",
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "incoming senior"
    }
  ],
  [
    {
      format: "section",
      content: "Time for tying shoes"
    },
    "My dad tied my shoes for me until I was in the fourth grade. Was this outrageous? I was fully capable of tying my own shoes, but it was also a simple, caring gesture that my dad did to show me how I should be treated. My dad puts my mom, sister and I on pedestals because he doesn\'t want anything but the absolute best for us, setting a clear example for my sister and I in what we should be looking for in the future for a husband.",
    "Tying my shoes for an excessively long period of time wasn\'t the only small gesture my dad has done to show he cares. He pumps my gas for me, and wanders around bookstores with me for hours. One time I casually mentioned that I was thinking of buying some new bearings for my longboard, and about a week later he came into my room and set some on my desk.",
    "I appreciate all my dad does for me, more than I think he knows.",
    {
      format: "byline",
      name: "Gracie Miller",
      school: "Eisenhower High School",
      grade: "graduate"
    }
  ],
  [
    {
      format: "section",
      content: "The bicycle teacher"
    },
    "I remember when my dad taught me how to ride a bike. I was never afraid of the bike itself but of falling over. My bike with training wheels had always provided a sense of security that the two-wheeler did not. When my father decided it was time that I learn what it really felt like to ride a bike, I was excited … and nervous.",
    "We started off slow, with my dad holding the bike and running alongside me. It took time for me to learn because every time he would let go, I was afraid I would fall. As a result, I would stop riding before I ever got anywhere close to falling.",
    "However, after many days of practicing, lots of confidence-building from my dad, and learning how to balance myself, I took off, feeling the rush of the wind on my face as I zoomed down the street. The sense of freedom and exhilaration while riding a bike cannot be beat and it\'s thanks to my dad that I get to experience it.",
    "What I remember most is that my dad kept telling me that I needed confidence. If I rode with confidence, I wouldn\'t be afraid of falling over and would be able to keep my balance.",
    "It\'s something he continues to remind me of to this day whenever I\'m anxious about anything. He\'s always encouraged me to try new things and reminds me that practice and confidence make anything possible. My Babajani (Urdu word for \"daddy\") has made every day a better day for our family.",
    {
      format: "byline",
      name: "Maham Khan",
      school: "West Valley High School",
      grade: "incoming sophomore"
    }
  ],
  [
    {
      format: "section",
      content: "Soccer bonds"
    },
    "While my father was a young teen growing up overseas, he had a passion for soccer. When he had children, he wanted to share this passion.",
    "I remember watching my older sister and brother listening to my dad\'s words of advice on the field. Each of us had the pleasure of having him as a soccer coach.",
    "As a young child, I never quite understood the concept of traveling. So when my dad drove my brother, our friends and me to Seattle for a Seattle Sounders soccer game, I thought we were going to Narnia.",
    "After what felt like a century to a small child, we got to what was then known as CenturyLink Field. Before the game, there was a multitude of events and giveaways outside the stadium. Hours before kickoff, we would play foosball, try to break a juggling record, get free T-shirts, and take pictures in front of a larger-than-life inflated soccer ball.",
    "Just before taking our seats, each of us kids would stand in line to spin the wheel for a prize — the most exciting one being a wristband pass to meet the players after the game, which I won. As a little kid, I didn\'t care much for the soccer game itself. Counting down the 90 minutes, I anxiously waited for my chance to take pictures and get signatures from some of the players.",
    "While my appreciation for going to a Sounders game was not quite about soccer, I will never forget the time spent bonding with my father.",
    {
      format: "byline",
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "incoming senior"
    }
  ],
  [
    {
      format: "section",
      content: "A picture-perfect evening together"
    },
    "The frogs were croaking somewhere far in the distance. The cool breeze ruffled the trees. A thousand stars twinkled in the clear skies above us. Ten thousand more winked at us from the city below.",
    "My dad and I were sitting on our patio, watching the moon rise. It was a nice chance to slow down and talk; you can\'t rush the moon. He told me about his life and what things were like when he was growing up. We talked about my hopes and dreams for the future. We talked about school. We talked about the weather and what a gorgeous night it was. I realized just how long it had been since we last talked.",
    "The moon, which had been a cautious sliver on the horizon for half an hour, had finally braved its full face to the tranquil night.",
    "\"I wonder if there is any way we could take a picture of that?\" my dad asked after gazing at the moon for a moment. \"It\'s beautiful!\"",
    "Neither of our phones did it justice. I guess not everything can be captured on camera.",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming junior"
    }
  ]
];
