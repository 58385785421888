import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    "Two West Valley High School students recently celebrated their birthdays — on leap day, Feb. 29. So, student leaders from the Associated Student Body program organized classroom celebrations that included signed cards for their classmates who only get to see their birthday come up every four years.",
    "The celebrations for the students occurred after Kristin Johnson, the school's ASB adviser, noticed as she was planning this year's calendar that 2024 has a Feb. 29.",
    "\"I left myself a note in January to figure out something we could do for a leap day,\" she said. \"I think it was in ASB class that someone said, \'I wonder if there are any leap day birthdays?\'\"",
    "It turns out there are two such students among the 1,200 enrolled at WVHS who have a Feb. 29 birthday: freshman Latanisha Brown and senior Peter Nordstrom.",
    "\"Any time any community has a chance to come together and celebrate something, put a smile on people's faces, make them feel seen and appreciated, it's a good thing,\" Johnson said. \"It's something you should do.\" ",
    "Using Unleashed \"4 Questions\" format — because their birthdays happen only once every four years — here are some insights about the two West Valley High School students who are among the 0.07% of the population with a birthday on leap day: ",
  ],
  [
    {
      format: "section",
      content: "Latanisha Brown"
    },
    {
      format: "bold",
      content: "What does an ideal birthday look like to you?"
    },
    "\"I'd say being around family would be the best. Also, I\'d choose to go shopping. It\'d be just a little shopping spree type of thing at the mall. My family can buy things for themselves, and I can buy things for myself but just being around each other would be good. This year, I got to go on a dinner date with my entire family.\"",
    {
      format: "bold",
      content: "How do you spend your time?",
    },
    "\"Probably catching up on academics. And drawing and writing. I doodle every once in a while. I mostly draw out characters. It helps me map out what I\'m writing. But I also like to write sometimes in my free time about how I'm feeling or how someone else is feeling in a situation. I also like writing down my dreams.\"",
    {
      format: "bold",
      content: "What is your least favorite thing in the world?",
    },
    "\"I don\'t like how people criticize opinions. If someone is trying to speak their mind, and people step in to say that they are wrong or whatever, but it\'s just an opinion, I say let them believe their own opinion, because it isn\'t hurting you. When I speak my mind, I get criticized sometimes for certain things, and I just think 'Really?' I got to speak up for the few that might agree with what I have to say.\"",
    {
      format: "bold",
      content: "How will you decorate your future house?",
    },
    "\"I'd do more colorful things. I like color. I don't like that bland, beige and white stuff. I want something that gives character and is not bland or plain. All the houses now are just so square and they look exactly the same. There's no character in them or anything like that. I want to be able to decorate my house with memorable things.\"",
    {
      format: "byline",
      name: "Ava Schoonover",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "Peter Nordstrom"
    },
    {
      format: "italics",
      content: "Jacqueline Bounds, one of the West Valley School District\'s Developmental Learning Center paraprofessionals who works with Nordstrom and other students in the school district's Special Education program, paints a picture of what it is like to work with Nordstrom. Her responses follow.",
    },
    {
      format: "bold",
      content: "What\'s it like working with Peter?",
    },
    "Jaqueline Bounds: \"Oh, he's amazing. He makes me get up in the morning when I'm off to work. I\'ve known Peter since I had him up at the freshman campus. He's extremely happy. He is very smart.\"",
    {
      format: "bold",
      content: "What does a typical day with Peter look like?",
    },
    "\"Twice a week, for two hours during second and third period, we take our kids out into the community to work. Peter goes to Wray\'s at Meadowbrook and he stocks candy and gum and he does an amazing job. During fourth period, Peter has adaptive P.E. and he has a peer tutor who helps him. When it\'s nice out, we go outside.\"",
    {
      format: "bold",
      content: "Does Peter have any favorite things?",
    },
    "\"You can give him a 300-piece puzzle and he will put it together. His favorite videos are \"VeggieTales.\" He loves \"VeggieTales.\" He's really good on the Chromebook. You\'ll see him typing and you\'ll be like 'Woah!' with how fast he is.\"",
    {
      format: "bold",
      content: "What else should we know about him?",
    },
    "\"The thing with Peter that's really, really cool is he has an extremely amazing home life. And that makes a huge difference in our kids. (His parents) just make sure that Peter is very well taken care of. They take him on trips. They took him to Disneyland. Around Christmas time, his mom picked him up early, took him to Leavenworth for the lights.\"",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ]
]