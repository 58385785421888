import { Article } from "../../articleInterfaces";
import { content } from "./content";

import nov5 from "../resources/nov-5-23.pdf";

const article: Article = {
  title: "Commentary: My big break in music: Moving from streaming services and into purchasing albums",
  articleType: "column",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  date: new Date(2023, 10, 5),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/commentary-my-big-break-in-music-moving-from-streaming-services-and-into-purchasing-albums/article_c4bc5fe3-6d85-5a55-a153-094bd625a50e.html",
  content: content,
  clipping: nov5
};

export default article;