import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    {
      format: "section",
      content: "Supportive"
    },
    "There\'s not a day that goes by that my dad does not encourage me in everything I do. Whether it be in my academics, sports or hobbies, he has pushed me to challenge myself and grow as a person. He is selfless to a fault, and his generosity and patience are admirable. He\'s always on my side, even when I\'m wrong.",
    "Sometimes I have a hard time recognizing it, but I know my dad only wants the best for me. His support has been a defining factor in my life, and I don\'t think I will ever fully be able to comprehend his care for me. He has taught me how much it means to just be there for others — even if it takes them awhile to come around.",
    "To my dad: I really do appreciate everything you\'ve done to show me support, and I apologize that it\'s taken me so long to actually express it. I love you.",
    {
      format: "byline",
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "incoming senior"
    }
  ],
  [
    {
      format: "section",
      content: "Committed"
    },
    "Ever since we were little, my dad, Jarod Barbee, has always been committed to giving his best effort, always finishing what he starts and always putting in the work to make something out of nothing. Whether it be building a play set for us when we were kids or making a volleyball highlight video for recruiting, my dad has always been committed to making the finished product be the best it can be.",
    "It\'s because of my dad that I learned to have a strong work ethic and perseverance, just like him.",
    "My dad has always been one of my biggest supporters regardless of the sport I was in. When I started volleyball, he knew next to nothing about the sport. But because I loved the sport so much, my dad came to every game, learned the volleyball lingo and even started watching professional volleyball on TV. He had started to love the sport as much as I did!",
    "This led me and my dad into playing our first beach volleyball match together. My dad hates the sand but loved being out there playing the sport we both love, together. He was still new to the sport and there were times he got frustrated, but he never let that stop him from playing his heart out and having a good time.",
    "We had so much fun playing together that it became something we do regularly. He has always put max effort into everything he does, and has always been more than willing to make my passions his. I could not be more grateful to call him my father.",
    {
      format: "byline",
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "graduate"
    }
  ],
  [
    {
      format: "section",
      content: "Convivial"
    },
    "When I think of my dad, the first word that comes to mind is \"nice.\" However, as a member of the Unleashed program, I felt the need to select a more elaborate adjective to describe my dad\'s friendly disposition. After a brief moment of thought, I chose the word \"convivial.\"",
    "My dad is truly the most gregarious, congenial person I know. He is able to effortlessly relate to anyone and quickly form a connection with them.",
    "My dad\'s convivial nature can be seen in all facets of his life. At home, he is a caring dad who always knows how to make me feel better. As a training specialist at a social service agency, he is known to make humorous videos for his co-workers to enjoy at work functions. And, as an avid enthusiast of recreational basketball, he often gets up at 4 a.m. to go shoot some hoops with whoever shows up.",
    "I always appreciate what a kind person my dad is. His convivial nature is something I strive to emulate in my own life.",
    {
      format: "byline",
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "graduate"
    }
  ],
  [
    {
      format: "section",
      content: "Involved"
    },
    "My dad has always been very involved with my hobbies. Whether it was video games, snowmobiles or toy cars, my dad would go to great lengths to support my hobby with his time and effort.",
    "When I was a new Lego enthusiast at age 4, I woke up one morning to find storage bins in my room filled to the brim with Lego bricks. My dad had gone out late at night to buy a huge collection of used Legos, concerned it would sell to someone else if he didn\'t act fast. I was ecstatic. Over the years, my dad and I sorted through Legos to find certain pieces to reconstruct sets and also built new store-bought sets.",
    "When I entered my first Lego competition at age 7, my dad helped me practice builds and taught me strategies that could help me crush the competition. I participated in four competitions and placed in the top three each time, thanks to my dad\'s involvement.",
    "His involvement in my hobbies and life shows how much he truly cares about me and loves me.",
    {
      format: "byline",
      name: "Carson VanEaton",
      school: "East Valley High School",
      grade: "incoming junior"
    }
  ],
  [
    {
      format: "section",
      content: "Outdoorsman"
    },
    "So many of my favorite memories with my dad are set in the outdoors. I guess those memories stick more because they\'re clearer. They are set above the hustle and bustle of life in Yakima. And I think that\'s why he loves the outdoors so much.",
    "Hiking, skiing, hunting and more wonderful activities in the outdoors are such an influential part of my relationship with my dad. I remember so clearly the few times I\'ve gone hunting with him. I remember the landscapes and the snacks I ate, and I remember spending quality time with my dad.",
    {
      format: "byline",
      name: "Maisy Kollman",
      school: "Eisenhower High School",
      grade: "incoming sophomore"
    }
  ],
  [
    {
      format: "section",
      content: "Hardworking"
    },
    "In all of his many roles, my dad is hardworking.",
    "He is a hardworking adviser and always has the most insightful and practical advice. He is a hardworking homeowner and spends time to give us a continuously improving home. He is a hardworking spiritual guide and is always open to any conversations in helping me search for answers to my questions. He is a hardworking cook and loves to create new dishes to feed his family. He is a hardworking friend who wants to hear about my interests and thoughts.",
    "My dad works hard to create special memories for my family. A couple of summers ago, he spent time planning a road trip for us. Over about a week, we visited Glacier National Park, Yellowstone and the Grand Tetons, camping in our rooftop tent along the way. Dad had bought this string of lights for the trip, and we put them up every night and they made everything look magical. His attention to detail resulted in a fun time together that has turned into a special memory.",
    "My childhood has been full of camping trips, hikes, daddy-daughter dates, long drives, river floats, and ski days with my dad. I am so blessed that I am the recipient of 18 years (so far) of his dedication, love and hard work.",
    {
      format: "byline",
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "graduate"
    }
  ],
  [
    {
      format: "section",
      content: "Gregarious"
    },
    "My father is a gregarious kind of man. Gregarious in all shapes and forms. This is a word that describes a person who is fond of company or sociable. And, let me tell you, this is the perfect word to sum up the man I call \"Dad.\"",
    "Before and during my lifetime, my parents have been adventurous travelers. My father has traveled to at least 35 countries.",
    "He was born in Vietnam and graduated from high school in Taiwan. After his first child was born, he moved to Germany for seven years and traveled throughout Europe. He has traveled to Kenya for each of the past four years to teach continuing education courses for local physical therapists.",
    "In his travels, he has made friends throughout the world. After returning home from Kenya, he told stories of the friends he made and the respect he has for them. He recounted tales of going to markets where his friends bargained for the best price. He showed me the souvenirs he brought back and described the history and bargaining behind them. He shared pictures of his new friends and sometimes even their life stories.",
    "One of my dad\'s best work-related friends is an Australian who lives in Las Vegas. Even on a busy work trip to Texas, my dad makes time to reconnect with his college buddies. And he will travel a six-hour round trip with Seattle traffic just to watch a soccer game with his longtime best bud/fellow soccer coach.",
    "He makes it seem easy to make friends and be sociable.",
    {
      format: "byline",
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "graduate"
    }
  ],
  [
    {
      format: "section",
      content: "Hardworking"
    },
    "My father is a man who never stops and never takes a break. He starts early in the day and comes home after 7 p.m. on most days. He does so much for our family, and I\'ll never be able to truly acknowledge and appreciate all that he does.",
    "It\'s not easy to support a family, yet my dad does it with a smile and tries to support us in every way he can. He\'s incredibly busy but does his best to show up to all our school events, help us with homework, and spend time with his family.",
    "My dad is such a productive man and it\'s mind-blowing to see how much he gets done. It\'s easy to complain that \"I don\'t have time,\" but when I look at my dad, he has way less time than I do and gets far more done. It\'s inspiring to see that if you don\'t let simple excuses get in your way, you can do so much. He has shown my siblings and me that anything is possible and that hard work really pays off.",
    "I ask everyone to appreciate their fathers because they do so much for us, more than we\'ll ever realize.",
    "Love you, Babajani (Urdu word for \"Dad\")!",
    {
      format: "byline",
      name: "Maham Khan",
      school: "West Valley Virtual Academy",
      grade: "incoming junior"
    }
  ],
  [
    {
      format: "section",
      content: "Stoic"
    },
    "My dad, Brad Jacobson, never complained about his disease or how he was slowly becoming weaker each day. He was diagnosed with Parkinson\'s disease at age 50. That diagnosis changed to multiple systems atrophy, or MSA, about five years before he died.",
    "Even though he knew that statistically people with MSA do not live more than a few more years, he never complained about how unfair it was. He worked at his job of teaching science as long as he possibly could. He always tried to do things himself, even when he couldn\'t move his body. He worked on his small farm until he absolutely could not do it any longer.",
    "He never looked sad or angry about his condition, but I think he did that for our family, in order to show that he was still the same. He was stoic when the people from hospice came to speak to him about the changes that were to take place in his life. He was stoic when the hospital bed was set up at home. He was stoic when he could no longer get out of a chair by himself.",
    "I\'m proud to call him my dad. His stoicism was a gift to his family.",
    {
      format: "byline",
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "graduate"
    }
  ],
  [
    {
      format: "section",
      content: "Friend"
    },
    "I tried to describe my dad in a single word and failed miserably. Instead of just one solitary, perfect, heartfelt word, I had list of 28. Each seemed like a photo, but never the full person.",
    "\"Mentor\" felt too stiff and formal. Sure, it captures the respect I feel for him, and the knowledge I have learned and have yet to learn from my years with him. But there is this inescapable feeling of a business suit — some transaction to be completed.",
    "\"Driven\" seemed to only capture the competitive side. Don\'t get me wrong — I admire the work ethic and how hard he fought to get to where he is today. But where is the man who will go see a \"Transformers\" movie with his son in \"driven\"?",
    "\"Inspirational\" felt too grand. My dad inspires me to work hard, to treat others with respect, to face the day with enthusiasm. Yet there is this sense of looking on from afar with \"inspirational\" — it\'s something to work toward, but yet distant and untouchable.",
    "\"Caring\" seemed to check the boxes. Here was the guy willing to spend his weekends floating the river with his family, and that generosity of letting you pick the first Crumbl cookie out of the box. Here was the man who would spend late nights gazing at the stars with me, someone I could just talk to.",
    "\"Friend.\" Now here was a word that really seemed to fit. A friend is a fellow human, imperfect but also dependable and reliable.",
    "A friend can inspire you to push to new heights and mentor you through hard times. A friend cares for you, cheering and rooting for your successes, and offering support when you need it most.",
    "A friend is someone you can share a cookie with, watch a movie with, share a late night gaze at the stars with, or have an early morning chat with.",
    "Not everyone has that with their parents. But I\'m grateful I have gotten to have that relationship with mine. Thank you, Dad.",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming senior"
    }
  ]
];
