import { ReactNode, useEffect, useRef, useState } from "react";
import { ReactComponent as Arrow } from "./caret-down-fill.svg";

import style from "./index.module.scss";

interface AccordionProps {
  title: string;
  children: ReactNode;
}

export function Accordion(props: AccordionProps) {
  const [isOpen, setOpen] = useState(false);
  const [height, setHeight] = useState(0);  

  const ref = useRef<HTMLDivElement | null>(null);
  const onResize = () => {
    if (!!ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className={`${style.accordion} ${isOpen ? style.open : style.closed}`}>
      <div className={style.heading} onClick={() => setOpen(!isOpen)}>
        <Arrow className={style.arrow} /> {props.title}
      </div>
      <div className={style.animationContainer} style={{["--height" as string]: `${height}px`}} ref={ref}>
        <div className={style.content}>{props.children}</div>
      </div>
    </div>
  );
}
