import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    "Although it may seem hard to be grateful in these bleak times, COVID-19 has made some opportunities that would simply not be possible in a normal school year. Since our school schedule is more flexible with online classes, I have been able to get my high school dream job as a barista. There is no way I would have had time for this during a normal senior year.",
    "I am also grateful for my friends. Though it has been hard to stay in touch, I have actually grown closer with many of my friends because I have more time to spend with them. The effort we now have to put into keeping up personal relationships makes us appreciate them even more.",
    "Another way the coronavirus has influenced my life is it has shaped my personality. I have become a more grateful person in general. Before COVID-19, my schedule was packed full with everything you could imagine, and I often found myself tired of the activities I used to love doing. Now, I appreciate everything I get to do, even if before I may have viewed it as a chore. Becoming more grateful has made me a happier person in general, and I do not think there\'s anything more I could ask for.",
    {
      format: "byline",
      name: "Ava Hoeger", 
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  [
    "I\'m grateful for the unexpected freedom granted by quarantine. Back in March, it was daunting to suddenly have so few plans and obligations. I didn\'t expect the difficulties of transitioning to a life where household activities and online school were the only things I had to do.",
    "But through the challenge of deciding how to fill my time in a meaningful way, I developed deeper appreciation for things I sometimes take for granted, like my family and my neighborhood. Which aspirations and parts of life truly make me happy became more clear. While I miss the spontaneity and social aspects of regular life, I have so much gratitude for this opportunity to slow down, reprioritize and reconnect.",
    {
      format: "byline",
      name: "Maya Crowder", 
      school: "Davis High School",
      grade: "senior"
    }
  ],
  [
    "There\'s definitely a lot going on right now, but with Thanksgiving coming we can\'t help but look at all the things we\'re thankful for. For me, it didn\'t take long to realize that I\'ve been complaining quite a bit, when I really have a lot of great things and people in my life. I\'m obviously very thankful for my close friends, family, our house, food, clothes, and much more.",
    "But — and I\'m going to sound like a total teenager — I\'m also very thankful for my phone. It provides me with the opportunity to reach out to friends, family, teachers and peers so quickly and easily. I can submit an assignment from anywhere, I can FaceTime an old friend whenever, and I can call my grandparents at any time. The technology we have is given a bad rap but, without it, I don\'t know how we\'d function in this crazy world of COVID-19. We have the ability to do and know so much with just a small search on our devices. I\'m really lucky to have my phone and be in a time when a little box can help me connect to so many people.",
    {
      format: "byline",
      name: "Olivia Rankin", 
      school: "Eisenhower High School",
      grade: "sophomore"
    }
  ],
  [
    "2020 has been a rough year and it can be hard to find things to be thankful for. Time may not have always been usefully spent while being under a stay-at-home order. But, this period has also forced us to learn more about ourselves and find new hobbies and ways to spend our time.",
    "Over quarantine, I started to read, walk and bike a lot more. I also found new ways to stay in contact with my friends even when we couldn\'t see each other. Being at home all the time may not be fun, but it forced me to spend my time finding new things that I could enjoy.",
    "This year has also made me a lot closer to my family. Before the stay-at-home order I pretty much only saw my family on the weekends, and I often made plans with friends at that time. When I was forced to be with my siblings almost constantly, we started to actually do things together. We would make fun breakfasts together in the morning, go on walks or picnics in the afternoon, and would spend the evenings binge-watching our favorite shows together. We haven\'t had this kind of time together since we were little and I think that is definitely something to be thankful for.",
    {
      format: "byline",
      name: "Susan Wilmes", 
      school: "Davis High School",
      grade: "junior"
    }
  ],
  [
    "Some of the things I am grateful for are the opportunities that I was able to pursue because of the pandemic. One of the ways I took advantage of being stuck at home was beginning a Running Start-like program that is available for freshmen. Through West Valley High School, I can take classes at Arizona State University for both high school and college credit. I might not have considered this option if school had been in-person this fall.",
    "COVID-19 has also encouraged me to reconnect with my friends. Before the pandemic, I mostly talked to my friends at school. With that no longer being an option, and being a very social person, I began to reach out to check in with my friends in other ways. Sometimes, I was able to listen to their problems through phone calls, while other times I was able to connect through text messages or the social media platform Discord. Through it all, it\'s been nice to know that we were all in this together.",
    {
      format: "byline",
      name: "Magnus Fulton", 
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  [
    "2020 has been full of a lot of awful things, but a byproduct of some of those awful things is what I am truly thankful for: time. Lockdown restrictions for months and then school from my computer have given me more empty time than I\'ve ever had before. I sometimes find myself wishing that I had more things to fill my time, things that I used to be able to do. I miss traveling, in-person school and in-person extracurriculars, but I can also appreciate all this unfilled time.",
    "I am thankful for more time to spend with my family. We have been able to do a lot of things together that normally would be reserved only for weekends. During the summer, when my sister and I had no school and my parents could do much of their work remotely, we got in lots of hiking, one of our favorite things to do as a family. We also got to do a good deal of driving to explore our state, and we have a high-stakes game of gin rummy that has been running since April. I\'m thankful for this time with my family because I know it won\'t always be like this, with us all living and working together, so I want to appreciate it while I have it.",
    {
      format: "byline",
      name: "Anabelle Kollman", 
      school: "Eisenhower High School",
      grade: "sophomore"
    }
  ]
];
