import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  ScrollRestoration
} from "react-router-dom";
import { routes as unleashedRoutes } from "./unleashed";
import { routes as budgetRoutes } from "./budget";

function Home() {
  return <Navigate to="/unleashed" />
}

function Error() {
  return <h1>Not found!</h1>
}

const router = createBrowserRouter([
  {
    path: "",
    element: (
      <>
        <ScrollRestoration />
        <Outlet />
      </>
    ), 
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <Error />
      },
      ...unleashedRoutes,
      ...budgetRoutes
    ]
  }
]);

export function App() {
  return <RouterProvider router={router} />
}