import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../../store";
import {
  Renews,
  Subscription,
  Transaction,
  TransactionAction,
  TransactionType,
  addWeeks,
  thisWeek
} from "./common";

type NowEditing = Transaction | Subscription;
interface MinimumViableKey {
  time: string;
  type: TransactionType;
}

interface TransientBudgetStore {
  weekOf: string;
  writableDraft: NowEditing;
  pointer?: MinimumViableKey;
}

type ReducerFunction<T> = (
  state: TransientBudgetStore,
  payload: PayloadAction<T>
) => void;

interface Reducers {
  //Unused, mostly to get Redux to shut up
  [k: string]: ReducerFunction<any>;

  setWeek: ReducerFunction<string>;
  startEditing: ReducerFunction<NowEditing>;
  clearDraft: (state: TransientBudgetStore) => void;

  setType: ReducerFunction<TransactionType>;
  setAction: ReducerFunction<TransactionAction>;
  setAmount: ReducerFunction<number>;
  setDescription: ReducerFunction<string>;
  setRenews: ReducerFunction<Renews>;
}

function blankTransaction(): Transaction {
  return {
    type: "transaction",
    time: new Date().toISOString(),
    action: "withdrawl",
    amount: 0,
    description: ""
  }
}

function blankSubscription(): Subscription {
  return {
    ...blankTransaction(),
    type: "subscription",
    renews: "weekly"
  }
}

const transientBudgetSlice = createSlice<TransientBudgetStore, Reducers>({
  name: "transientBudget",
  initialState: {
    weekOf: thisWeek().toISOString(),
    writableDraft: blankTransaction()
  },
  reducers: {
    setWeek: (s, p) => {
      s.weekOf = p.payload;
    },
    startEditing: (s, p) => {
      s.pointer = { time: p.payload.time, type: p.payload.type };
      s.writableDraft = p.payload;
    },
    clearDraft: (s) => {
      s.pointer = undefined;
      s.writableDraft = blankTransaction();
    },

    setType: (s, p) => {
      if (p.payload === "transaction") {
        //This is why we affirmatively type things
        s.writableDraft.type = "transaction";
      } else if (p.payload === "subscription") {
        s.writableDraft = {...blankSubscription(), ...s.writableDraft, type: "subscription"}
      }
    },
    setAction: (s, p) => {
      s.writableDraft.action = p.payload;
    },
    setAmount: (s, p) => {
      s.writableDraft.amount = p.payload;
    },
    setDescription: (s, p) => {
      s.writableDraft.description = p.payload;
    },
    setRenews: (s, p) => {
      if (s.writableDraft.type === "subscription") {
        s.writableDraft.renews = p.payload;
      }
    }
  }
});

export default transientBudgetSlice.reducer;

const { setWeek } = transientBudgetSlice.actions;

export const { clearDraft, startEditing, setType, setAction, setAmount, setDescription, setRenews } = transientBudgetSlice.actions;

export function setWeekTo(delta: "-1" | "+1" | "now") {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    if (delta === "now") {
      dispatch(setWeek(thisWeek().toISOString()));
    } else {
      dispatch(
        setWeek(
          addWeeks(
            new Date(getState().transientBudget.weekOf),
            delta === "+1" ? 1 : -1
          ).toISOString()
        )
      );
    }
  };
}
