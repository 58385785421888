import { Article } from "../../articleInterfaces";
import { content } from "./content";

import dragons from "./dragons.jpg";

const article: Article = {
  title:
    "Imagine Dragons at Climate Pledge Arena delivered a mesmerizing experience",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  articleType: "review",
  date: new Date(2022, 3, 3),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/imagine-dragons-at-climate-pledge-arena-delivered-a-mesmerizing-experience/article_ed4f723b-bbe7-5195-b34e-56195f51b379.html",
  images: [
    {
      caption:
        'Imagine Dragons, with lead singer Dan Reynolds, performs March 7, 2022, at Climate Pledge Arena in Seattle. The group won a Grammy in 2014 for Best Rock Performance for the song "Radioactive."',
      credit: {
        name: "Magnus Fulton",
        school: "West Valley High School"
      },
      src: dragons
    }
  ],
  content
};

export default article;
