import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "Messages on setting career goals, courtesy of some mentors from Microsoft",
  articleType: "column",
  author: [
    {
      name: 'Magnus Fulton',
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  date: new Date(2020, 11, 27),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/messages-on-setting-career-goals-courtesy-of-some-mentors-from-microsoft/article_7f06a238-89dc-5bd5-afc0-930d10943432.html",
  content
};

export default article;