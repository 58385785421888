import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Where does the time go? There are only so many hours in a day. Balancing homework, jobs, sports, friends, family and everything we want to do for fun can be a struggle. But there are things we can do to see the light at the end of the tunnel.",
  {
    format: "unordered-list",
    content: [
      [
        {
          format: "bold",
          content: "Create a weekly schedule: "
        },
        "Visualizing your schedule helps you see when you need to work hard, so you can play hard. Looking ahead at due dates, sports and other obligations can help you avoid feeling overwhelmed and stressed."
      ],
      [
        {
          format: "bold",
          content: "Use to-do lists: "
        },
        "Sometimes we have a mountain of things we need to do. To-do lists can help prioritize what\'s important, ensuring that things do not slip through the cracks."
      ],
      [
        {
          format: "bold",
          content: "Build exercise and downtime into your schedule: ",
        },
        "We can\'t be all work and no play. Taking time to do the things you love is essential for staying motivated and fighting stress."
      ],
      [
        {
          format: "bold",
          content: "Learn to say no: "
        },
        "Just because you can do something doesn\'t mean you have to ... or should! It\'s OK to say no."
      ],
      [
        {
          format: "bold",
          content: "Ask for help: "
        },
        "You are not alone. Everyone has had similar struggles. Reaching out to a mentor, parent or teacher can be a great way to learn from other people\'s experiences. Sometimes we just need someone to talk to."
      ]
    ]
  }
]