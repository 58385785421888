import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "It\'s time for the Great Unleashed Pencil Debate!",
  "The lines have been drawn. Let the graphite fight begin...",
  {
    format: "bold",
    content: "The case for the Ticonderoga"
  },
  "Absolutely nothing makes life worse than a cheap eraser. When they work well, they are a great way to step back, think about what you have done and try again. But sometimes this new beginning is obliterated by the atrocities wrought by a cheerful pink blob. These \"erasers\" are truly the demise of us all.",
  "Thankfully, Dixon Ticonderoga rises above the rest. While many \"erasers\" transform our mistaken lines into a smudge-filled wasteland, Ticonderogas are able to answer the call. Even when wielded against words scribbled with the tenderness of a bear carving a block of wood, these pencils never fail to erase even the toughest of lines.",
  "But erasing is only half the battle. Ticonderas make it effortless to call forth a perfect black ribbon of writing. Whether you need crisp, sharp lines or fading gradients, these pencils have your back. And, hey, even if your words challenge the boundaries of grammar, at least their lines will look great.",
  "Next time you have to do some writing, make the right call. You won\'t be disappointed by these pencils.",
  {
    format: "bold",
    content: "The case for the mechanical pencil"
  },
  "Though Ticonderogas are a classic, it is time to look to the future.",
  "Old pencils\' almost archaic design of hexagonal sides leaves edge after edge on your skin, making it hard to write comfortably. If you\'re 5 and gripping your pencil in utter concentration while attempting to write your six-letter name (Why would you do this to me, Mother?), it is less than comfortable.",
  "Not only that but, though their erasers are marvelous, they\'re limited.",
  "Luckily there\'s a solution: mechanical pencils. Those beauties can write just as well as a pencil, feel like a pen, and have the element of comfort support for fingers built in. Plus, occasionally, they have longer erasers that come out of twist tubes.",
  "They also come in cool colors, and they are much more fun to use. It almost makes scrounging for spare pieces of lead worth it."
];
