import { Article } from "../../articleInterfaces";
import { content } from "./content";

import poster from "./poster.jpg";
import strange from "./strange.webp";

const MARVEL = "Marvel Studios";
const CAPTION = "\"Doctor Strange in the Multiverse of Madness\" is a satisfying sequel, not a cookie-cutter MCU retread.";

const article: Article = {
  title: "Thrilling 'Doctor Strange' sequel is not your traditional MCU film",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  content,
  articleType: "review",
  date: new Date(2022, 4, 15),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/thrilling-doctor-strange-sequel-is-not-your-traditional-mcu-film/article_117843d8-8036-501b-a876-dba047cad53e.html",
  images: [
    {
      src: poster,
      credit: MARVEL,
      caption: CAPTION
    },
    {
      src: strange,
      credit: MARVEL,
      caption: CAPTION
    }
  ]
}

export default article;