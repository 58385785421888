import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  //I kept going back and forth on whether to start with a narrative.
  //On one hand, I never really did that, I wanted too, and it provides detail.
  //On the other hand, this is a newspaper and unless done right, it's dorky.
  "\"Thumper!\"",
  "I hear my friend shout, which echoes down the dark, cramped, industrial corridors. The sound of something dragging itself over concrete grows louder, as do the screams.",
  "Suddenly, it is quiet.",
  "I push through the thin metal double doors separating The Facility from the rest of the world. Stepping into the bright sunlight outside, I pick my way through the dense forest back to the ship.",
  //The published version leaves out the credit counts. I like them and it's my website.
  "\"Thumper,\" I say, dropping the dropping the plastic fish (32 credits), big bolt (27 credits), and tattered metal sheet (15 credits) on the floor.",
  "\"R.I.P.,\" my crewmate calls back from the controls as he launches our ship back into orbit.",
  "While it cost our crewmate his life, we collected 274 credits worth of scrap during that run. Not much, but enough for this week\'s quota.",
  //My original phrase for the second sentance was "You risk your life for three 
  //in-game days for an entity known only as \"The Company\""
  "Lethal Company is a multiplayer scrap collection videogame. In the game, players engage in three in-game days for an entity known only as The Company, visiting the abandoned mansions and industrial complexes scattered on moons around the solar system. Once your contract is up, you sell what you have found, buy upgrades and replacements for the gear you have, and set out once again for the moons.",
  "But while humans may have abandoned those facilities, the hostile ecosystems there have not.",
  "Everything you encounter on the moons you visit can — and will — kill you. There are four-legged beasts with sharp teeth, shadowy and plant-like figures, slow-moving blobs that like music, houseflies that want the scrap as badly as you do, and other unique fictional creatures that all have no qualms seeing you as their prey. If the fauna does not kill you, the landmines and turrets scattered throughout the facilities certainly will.",
  "The PC game was released by the indie developer Zeekeers in October 2023. Estimates suggest that the game has sold between 4.6 million and 6.9 million copies as of the first month of this year. With players and YouTubers posting videos and theories about the viral multiplayer game, it was no surprise that it won the \"Better with Friends\" award on Steam, the videogame distribution platform where it is sold.",
  "This sci-fi game is part of a wider, emerging category of games known as \"roguelites.\" These games feature short, randomized and highly replayable levels with a small amount of progression mixed in. This encourages players to play for hours at a time or only a few minutes if they like, making the game more accessible.",
  "In Lethal Company, players encounter procedurally-generated corridors, randomized monster locations, and scrap generated each time you land on a moon. The only things you get to keep are whatever you bring onto your ship. Failure to meet quota even once means a complete loss of the gear and money you\'ve accumulated.",
  "Another part of the game\'s success is its heavy reliance on its multiplayer aspects, encouraging players to recruit their friends in their work for The Company. The game\'s progression involves ever-increasing \"quotas\" of scrap. This requires teamwork to extract enough scrap and fight off monsters during each in-game day. Players can stay behind on the ship to remotely open additional areas in each facility, arm themselves with a shovel to beat down monsters or navigate the eerie industrial halls in search of something to salvage.",
  "The contrast between the alien landscapes outside and the grungy industrial complexes inside is accentuated nicely by the game\'s retro-style graphics. The game\'s use of low-quality and grainy textures also works well to enhance its gritty survival horror themes.",
  "Lethal Company is sold through Steam\'s digital marketplace for $10 and is available on PC. The game features mature content for having slapstick horror elements.",
  //The published version, for whatever reason, ends with "Try out Lethal  
  //Company. And welcome to your first day on the job." I prefer my original 
  //line, especially as a reference to the game itself. The published version 
  //does have a call to action, however.
  "Welcome to your first day on the job."
]