import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ArticleType } from "../../articleInterfaces";

export type Honors = "editor's pick" | "top story" | "author's choice" | "award" | "featured";
export type Sort = "ascending" | "descending";
interface DirectoryStore {
  sortDates: Sort;
  honors: Honors[];
  category: ArticleType[];
  query: string;
}

type ReducerFunction<T> = (
  state: DirectoryStore,
  payload: PayloadAction<T>
) => void;

interface Reducers {
  //Unused, mostly to get Redux to shut up
  [k: string]: ReducerFunction<any>;

  setSortDates: ReducerFunction<Sort>;
  addHonor: ReducerFunction<Honors>;
  removeHonor: ReducerFunction<Honors>;
  addCategory: ReducerFunction<ArticleType>;
  removeCategory: ReducerFunction<ArticleType>;
  setQuery: ReducerFunction<string>;
}

function remove<T>(arr: T[], elem: T) {
  const index = arr.indexOf(elem);
  if (index > -1) {
    arr.splice(index, 1);
  }
}

const directorySlice = createSlice<DirectoryStore, Reducers>({
  name: "unleashed-directory",
  initialState: {
    sortDates: "descending",
    query: "",
    category: [],
    honors: []
  },
  reducers: {
    setSortDates: (s, p) => { s.sortDates = p.payload },
    setQuery: (s, p) => { s.query = p.payload },
    removeHonor: (s, p) => { remove(s.honors, p.payload) },
    removeCategory: (s, p) => { remove(s.category, p.payload) },
    addHonor: (s, p) => { 
      //Why not use a set? Sets were causing issues for some reason
      if (!s.honors.includes(p.payload)) {
        s.honors.push(p.payload);
      }
    },
    addCategory: (s, p) => { 
      if (!s.category.includes(p.payload)) {
        s.category.push(p.payload);
      }
    }
  }
});

export default directorySlice.reducer;

export const {
  setSortDates,
  setQuery,
  removeHonor,
  removeCategory,
  addHonor,
  addCategory
} = directorySlice.actions;
