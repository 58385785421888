function nth(d: number) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
};

// function dayOfWeek(d: number) {
//   switch (d) {
//     case 0: return "Sunday";
//     case 1: return "Monday";
//     case 2: return "Tuesday";
//     case 3: return "Wednesday";
//     case 4: return "Thursday";
//     case 5: return "Friday";
//     case 6: return "Saturday";
//   }
// }

function monthOfYear(m: number) {
  switch (m) {
    case 0: return "January";
    case 1: return "February";
    case 2: return "March";
    case 3: return "April";
    case 4: return "May";
    case 5: return "June";
    case 6: return "July";
    case 7: return "August";
    case 8: return "September";
    case 9: return "October";
    case 10: return "November";
    case 11: return "December";
  }
}

export function DateDisplay(props: {date: Date}) {
  return <time dateTime={props.date.toISOString()}>{monthOfYear(props.date.getMonth())} {props.date.getDate()}{nth(props.date.getDate())}, {props.date.getFullYear()}</time>
}