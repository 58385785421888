import { Article, Author } from "../../articleInterfaces";
import clipping from "../resources/jun-8-24.pdf";

import ivy from "./ivy.jpg";
import josh from "./josh.jpg";
import katie from "./katie.jpg";
import charles from "./charles.jpg";
import { content } from "./content";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "graduate"
};

const article: Article = {
  articleType: "news",
  author: [ ME ],
  date: new Date(2024, 5, 8),
  title: "West Valley's valedictorians look back on their Ram years ... and ahead",
  clipping,
  editorsPick: true,
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/west-valleys-valedictorians-look-back-on-their-ram-years-and-ahead/article_8d2c907e-2d5d-5706-9e3b-18e504ab66a9.html",
  images: [
    {
      src: ivy,
      caption: "One of Ivy Tweedy's favorite memories from high school was winning Inland Empire, a tennis tournament with over 60 teams, her sophomore year.",
      credit: ME
    },
    {
      caption: "In ASB, one of Josh Raj's jobs was designing some of the graphics used in posters, on hallway TVs, in assemblies, and on social media.",
      src: josh,
      credit: ME
    },
    {
      caption: "Charlie Naught poses on the track where he raced all four years of his high school career. After high school, Naught wants to become a veterinarian.",
      src: charles,
      credit: ME
    },
    {
      caption: "Some of Katie Murdock's favorite memories were made on the cross country field when West Valley got third in state last fall. She is interested in becoming a dietitian after high school.",
      src: katie,
      credit: ME
    }
  ],
  content
};

export default article;