import { Article } from "../../articleInterfaces";
import { content } from "./content";
import staff from "./staff.jpg";

import oct8 from "../resources/oct-8-23.pdf";

const article: Article = {
  title: "Meet the 25th Unleashed team!",
  author: [
    {
      name: "Axel Fulton",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Natalie Hernandez",
      school: "Eisenhower High School",
      grade: "junior"
    },
    {
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "senior"
    },
    {
      name: "Mallory Keller",
      school: "Selah High School",
      grade: "sophomore"
    },
    {
      name: "Maham Khan",
      school: "West Valley Virtual Academy",
      grade: "junior"
    },
    {
      name: "Elise Klepach",
      school: "Highland High School",
      grade: "junior"
    },
    {
      name: "Rosie Pandit",
      school: "Selah High School",
      grade: "sophomore"
    },
    {
      name: "Cameron Pelson",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Mya Rankin",
      school: "Eisenhower High School",
      grade: "sophomore"
    },
    {
      name: "Ava Schoonover",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Sebastian Smartlowit",
      school: "Wapato High School",
      grade: "senior"
    },
    {
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "senior"
    },
    {
      name: "Ellie Suhm",
      school: "Eisenhower High School",
      grade: "sophomore"
    },
    {
      name: "Carson VanEaton",
      school: "East Valley High School",
      grade: "junior"
    },
    {
      name: "Annaka Yockey",
      school: "Eisenhower High School",
      grade: "sophomore"
    }
  ],
  articleType: "staff",
  date: new Date(2023, 9, 8),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/staff/meet-the-25th-unleashed-team/article_475e9c0c-4694-524c-b7e4-46a8f362244b.html",
  images: [
    {
      src: staff,
      credit: "Michael Ambrose, Yakima Herald-Republic",
      caption: "The 2023-24 Unleashed team. Standing: Indiana Hilmes (Selah High School), Mallory Keller (Selah High School), Magnus Fulton (West Valley High School), Axel Fulton (West Valley High School), Jillian Strother (Riverside Christian School), Ellie Suhm (Eisenhower High School), Annaka Yockey (Eisenhower High School), Ava Schoonover (West Valley High School). Kneeling: Elise Klepach (Highland High School), Sebastian Smartlowit (Wapato High School), Natalie Hernandez (Eisenhower High School), Rosie Pandit (Selah High School), Mya Rankin (Eisenhower High School), Cameron Pelson (Selah High School). Not pictured: Carson VanEaton (East Valley High School) and Maham Khan (West Valley Virtual Academy)"
    }
  ],
  editorsPick: true,
  content: content,
  clipping: oct8
};

export default article;