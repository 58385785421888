import { Helmet } from "react-helmet-async";
import { Article } from "../../articleInterfaces";

export function MyHelmet(article: Article) {
  return (
    <Helmet>
      <title>{article.title}</title>
      {!!article.yakimaHeraldURL && (
        <link rel="canonical" href={article.yakimaHeraldURL} />
      )}
    </Helmet>
  );
}
