import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Just a few months ago, I thought the only way I\'d ever see a Barbie move was sedated or in a straitjacket. Then \"Barbenheimer\" happened.",
  "It started as a joke. Two seemingly polar opposite movies — one set in a world gone pink and the other offering a drama set to explosions and practical effects — being released on the same day.",
  "The stage was set. The only question left was: Which to see first?",
  "In what would have been a shock to myself several months prior, I chose to see \"Barbie\" first. I went into this film as blind as possible, knowing only the lead actors and the name of the film. I had not even seen a single trailer for it.",
  "It quickly became obvious that this was not the Barbie movie I was expecting.",
  "From the get-go, the movie parodies the opening of \"2001: A Space Odyssey,\" as a narrator provides a (fictional) history of dolls while we see a group of small girls playing with baby dolls. Suddenly, giant-sized Barbie appears. Realizing what is possible, the children destroy their dolls as Barbie winks at the camera.",
  "Again, this is not the film I was expecting.",
  "The movie also references \"The Wizard of Oz,\" \"The Matrix\" and even paintings like \"The Creation of Adam.\" But more important, the film pays constant homage to the various outfits, designs and movies that inventor Ruth Handler\'s doll inspired. One of my friends even commented that the actors and their vehicles sometimes moved as though a small child were playing with them.",
  "While the references where fun, they weren\'t what sold the movie for me. Barbie the doll is, in my mind, the icon of superficiality. \"Barbie\" the movie takes the opportunity to simultaneously embrace and fly in the face of this image.",
  "The movie deftly establishes an air of something being amiss in Barbieland, which drew me into a film I otherwise would have dismissed. The pastel colors and cinematography also help to further define the world.",
  "Much of the movie is centered around serious societal questions about the balance of power — very matriarchal in Barbieland and very patriarchal in the Real World. It also addresses internal struggles — depression, anxiety, and discovering your self-worth and place in the world.",
  "In spite of the (surprisingly) serious nature of the film, \"Barbie\" is a movie that is clearly designed to be fun. I — along with the rest of the audience — laughed the entire time at jokes, satirical jabs at our society, and expertly placed references. Who would\'ve guessed?",
  "The next day (I know, it\'s not a true \"Barbenheimer\" double-feature by not seeing both movies on the same day), I returned to the theater to see \"Oppenheimer.\" Again, I went into the film as blind as possible, having seen only the trailer and knowing only Christopher Nolan\'s love of practical effects and bombastic sound.",
  "At first glance, \"Oppenheimer\" is not \"Barbie.\" Pastels and plastics are switched for stark grayscale shots. A complex world of politics and cloak-and-daggery is built up slowly over the course of the film. Fiery practical explosions replace the other movie\'s fiery emotional outbursts.",
  "What I liked about \"Oppenheimer\" is the storytelling. While \"Barbie\" is on the nose about what is going on, \"Oppenheimer\" leaves it up to the viewers to put everything together. The historical accuracy of the movie is also a great touch, down to choosing actors who look like the real people they are representing. (I cannot say the same for \"Barbie,\" however, given that I have yet to visit Barbieland.)",
  "\"Oppenheimer\" also has great sound and visual designs. Each explosion looks stunning as it roils in raging oranges and reds, all with minimal use of CGI. The shock waves also hit you hard enough that it feels like your shirt could have been carried away in the blast. This is particularly impressive considering the conversational audio for the rest of the movie is still very much audible and not quieted down to compensate.",
  "What was really interesting to me about both of these movies was that, individually, both very well could have been a blip on the radar. Some have speculated that the \"double feature\" of these films was planned this way as a plan by Warner Bros. to draw attention away from Nolan\'s \"Oppenheimer\" after he left the studio following the dual theatrical/streaming release given to his \"Tenet\" in 2020.",
  "Speculation or not, the opposite was true: \"Barbenheimer\" has become a societal sensation. So much so that I planned for the event months in advance.",
  "The double feature drew so much attention because of how dissimilar the films appeared to be on the surface. Got any ideas for relating children\'s dolls and atomic annihilation? Maybe there was a touch of rivalry thrown in, as well.",
  "However, both movies ended up delivering a compelling, thought-provoking story for the summer blockbuster season, while also being approachable. I\'m not sure what, if any, profound thoughts could be had after the \"Transformers\" prequel or \"Indiana Jones and the Dial of Destiny.\"",
  "Both \"Barbie\" and \"Oppenheimer\" drip with the passion and excitement of their creators. Both are ambitious films with all-star casts. Both strive for cinematic perfection and to challenge the viewer.",
  {
    format: "block-with-inline",
    content: [
      "The only question left: Which will ",
      {
        format: "italics",
        content: "you"
      },
      " see first?"
    ]
  }
];
