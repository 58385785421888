import { ReactComponent as PlusCircle } from "./plus-circle.svg";
import { Link } from "react-router-dom";
import { clearDraft } from "../../store/transient";
import { useAppDispatch, useAppSelector } from "../../../store";

import style from "./index.module.scss";
import shared from "../../../shared/style.module.css";

export function AddCharge() {
  const dispatch = useAppDispatch();
  const isMobile = useAppSelector(state => state.responsive.isMobile);

  return (
    <Link
      to="/budget/record"
      className={`${isMobile ? style.mobile : style.desktop} ${shared.linkoverride} ${style.addbutton}`}
      onClick={() => dispatch(clearDraft())}
    >
      <div className={style.chargetype}>
        <PlusCircle />
        <span>Add Charge</span>
      </div>
    </Link>
  );
}
