import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "While many things are up in the air for this year\'s Halloween celebration because of COVID-19 and social distancing, there\'s one thing should not be: Halloween candy is delicious, and we can all agree on that.",
  "But which candy is best?",
  "The good news is that we have a worthy selection for this year\'s top candy.",
  {
    format: "unordered-list",
    content: [
      "Gummy bears are dominating the gummy category, boxing out the imposter sour worms. While the debate between Haribo and Black Forest is worthy of its own article, we can all agree that the squishy delight of gummy bears is hard to ignore.",
      "Caramels are also a contender. Whether eaten alone or combined with other tantalizing goodies like apples or popcorn or served in salted logs, you will not want to miss the melt-in-your-mouth joy of caramels.",
      "Chocolates are more than just tasty rectangles! One of my favorite uses includes melting the squares into a scrumptious syrup, and convincing my mom that chocolate-covered strawberries count as a healthy option!",
      "Hard candies come in many varieties. Two challengers stand out in this sugary arena. One is Jolly Ranchers; colorful and bold, these solid sugar logs will not disappoint. However, Smarties provide a go-to option for today\'s fast-paced world, requiring far less time to enjoy compared to their other hard candy counterparts.",
      "Any showdown of the ages would not be complete without the sour candies. Whether you prefer the punch-to-the-face power of Warheads, or the sweet and sour blend of Sour Patch Kids, sours have a lot more to offer than just eliminating your taste buds!",
    ]
  },
  {
    format: "section",
    content: "The winner"
  },
  "While your opinions may differ given the array of options, one competitor stands out above the rest. Smarties combine the best of all worlds. They pack the sugary punch of hard candies, but without the time commitment required to eat. As an added bonus, they come in an easy-to-carry and fast-to-open package.",
  "Happy Halloween!"
]