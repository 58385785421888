import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "\'Tis the season ... for a lot of holiday eating! From recipes to recommendations, here are some suggested gifts for your taste buds during the coming weeks:",
  [
    {
      format: "section",
      content: "German Sour Cream Twists"
    },
    "When you think of Christmas cuisine, visions of fruitcakes, gingerbread men and sugar plums might dance in your head. I\'m here to add a twist to those visions: a German sour cream twist, to be precise.",
    "German sour cream twists have everything to be desired from a Yuletide dessert. They\'re sweet, creamy, soft and they\'ve got the all-important snackability factor. They\'re the perfect size to munch on while you\'re watching your favorite Christmas flick.",
    "Before you bake these twists, you\'ll need 3 1/2 cups flour, 1 teaspoon salt, 1 cup butter, 1 package yeast (dissolved in 1/4 cup of warm water), 3/4 cup sour cream, 1 whole egg and 2 egg yolks, 1 teaspoon vanilla, and sugar for rolling. Using a pastry blender, you\'ll have to cut in the flour, salt and butter, then combine the rest of the ingredients, except for the sugar, before refrigerating for two hours.",
    "Now it\'s time to add some sweetness! Roll half of the dough into a rectangle on a generously sugared board. Fold the ends toward the center, sprinkle more sugar, and roll out again. Then repeat it a third time. After this process, roll the dough out until it is 1/4-inch thick. Then cut the dough into 1x4-inch strips, and twist the ends. Finally, bend the strips into the shape of a horseshoe, and repeat the previous steps with the other half of the dough. Once your twists are fully shaped and sugared, bake them at 375 degrees for about 10-15 minutes.",
    "This recipe was passed down from my grandma to my mom, and it\'s been enjoyed by our family for years. I hope your family will enjoy these twists as much as we do.",
    {
      format: "byline",
      school: "West Valley High School",
      name: "Lucas Cone",
      grade: "senior"
    }
  ],
  [
    {
      format: "section",
      content: "Peanut Butter Blossom Cookies"
    },
    "What is shaped like a flying saucer, coated in sugar, and tastes like something out of this world?",
    "Peanut butter blossom cookies! These cookies have it all — peanut butter, sugar coating and, most important, a Hershey\'s Kiss. Warm and gooey fresh out of the oven, or dipped in milk, you will not be disappointed!",
    "Want to make some of your own? Mix 1/2 cup each of granulated sugar, brown sugar, peanut butter, and butter together with one egg until blended. Add 1 1/2 cups all-purpose flour, 3/4 teaspoon baking soda and 1/2 teaspoon baking powder to the mix. Roll the dough into 1-nch balls, thoroughly coat with granulated sugar, and place on an ungreased baking sheet spaced 2 inches apart. Bake at 375 degrees for 8 to 10 minutes, or until light golden brown. Place the signature Hershey\'s Kiss into the center of each cookie.",
    "After making these cookies year after year, I can guarantee that they, and their interstellar shape, are sure to become an instant addition to any holiday tradition.",
    {
      format: "byline",
      school: "West Valley High School",
      name: "Magnus Fulton",
      grade: "sophomore"
    }
  ],
  [
    {
      format: "section",
      content: "Sugar Cookies"
    },
    "Growing up, a classic memory engraved in my brain is decorating holiday cookies. Sure, chocolate chip cookies are the original cookie — chocolatey and gooey. But I would prefer a nice warm, sweet sugar cookie any day.",
    "Customizable for just about any holiday you can think of, sugar cookies are perfect for a tasty treat throughout the chilly season and pair perfectly with hot cocoa. The smell of sugar cookies fresh out of the oven is nostalgic for me. I remember sitting at the kitchen counter and seeing a plethora of icings, frostings and sprinkles spread out in front of me, the result of the earlier morning spent in the grocery store and begging for every pink, purple and sparkly option in the baking section.",
    "Some of my best sugar cookies during the holiday season were even shaped to reflect it — pumpkins and ghosts for Halloween, turkeys for Thanksgiving, Christmas trees and Santa hats for Christmas, and stars for New Year\'s Day.",
    "Whether frosted, sprinkled or fresh out of the oven, sugar cookies can bring the magic of the holiday season into your home and offer long-lasting memories to the whole family. Happy baking!",
    {
      format: "byline",
      name: "Olivia Gonzalez",
      school: "Davis High School",
      grade: "senior"
    }
  ],
  [
    {
      format: "section",
      content: "Krumkake"
    },
    "Eggs, butter, sugar, flour and vanilla are the core ingredients for many Christmas treats, but my favorite holiday sweet that all these items go into is Norwegian krumkake.",
    "The wafery, rolled cookies are a delight in my home every Christmas season. After pouring a thin layer of batter onto a hot iron press similar to a waffle maker, the soft wafers cook for about a minute and are then ready to be rolled. Because each cookie has to be hand-rolled, burnt fingers are a minor casualty on the path to Christmas perfection.",
    "Making these cookies is a tradition that runs throughout much of my extended family. By partaking in this annual Christmas custom, the family pays tribute to relatives such as my great-great-grandparents who came to the United States from Norway, and to the generations who have continued to pass down the traditions.",
    "My mouth is already watering in anticipation of tasting the cookies again this year, so it\'s time to break out the krumkake iron.",
    {
      format: "byline",
      name: "Kate Bethel",
      school: "Riverside Christian School",
      grade: "senior"
    }
  ],
  [
    {
      format: "section",
      content: "Zebra Cookies"
    },
    "What\'s black, white and delicious all over? Zebra cookies!",
    "Every December my family makes lots of cookies, from gingersnaps to sugar cookies. But my favorite will always be zebra cookies. The flavorful chocolate dough, the powdered sugar crinkles, and the white cream Hershey\'s Kisses are what make this cookie special!",
    "Zebra cookies are given their name because of the crinkle stripe pattern they make when the chocolate dough expands, showing through the powered sugar. (Did you really think it was made out of zebra?) The added white cream Hershey\'s Kiss is the cherry on top.",
    "Everything about this cookie makes me think of winter nights spent by a warm fireplace. If you want to make these cookies yourself this Christmas, you can find the recipe almost anywhere online by doing a search for zebra cookies or crinkle cookies.",
    {
      format: "byline",
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "Gingerbread Houses"
    },
    "Building a gingerbread house can be a simultaneously delectable, creative and admittedly somewhat frustrating experience. The deliciousness of gingerbread house making is apparent; as you craft your house, you can easily sneak a taste of candy and frosting. The creativity behind the festive endeavor is also obvious; turning a few pieces of gingerbread, some candy and a bit of icing into a sugary cottage of your own creation is satisfying.",
    "Yet, the process can also be a bit frustrating. Sometimes, it seems, the icing simply will not hold the gingerbread house together, as the walls so easily collapse. Nonetheless, laughing at your architectural failures with your fellow gingerbread house builders can be a fun-filled, memorable experience.",
    "Now, when your festive creation is complete, comes the real question: Will you choose to eat your gingerbread house, or let it stand as holiday decor?",
    {
      format: "byline",
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "Mince Pies"
    },
    "With maternal grandparents from England, it is inescapable to experience the joy of tiny mince pies. Every holiday season, the mixture of a shortcrust pastry and mincemeat creates a tasty treat. Growing up, I easily remember rolling out the pastry and cutting out circles for the base and scalloped-circle cutouts to cover the mincemeat.",
    "While it seems peculiar to have a \"meat\" substance within a holiday sweet, it isn\'t always required. The British population has a keen taste for a mix of savory and sweet during the holiday season. For any vegetarians, including myself, mince pies can be made with mincemeat that uses vegetable shortening rather than beef suet.",
    "Traditions have always been sacred in my family. Baking and devouring mince pies never escaped that rule.",
    {
      format: "byline",
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "Naan Khatais"
    },
    "On a day when the wind bites your face and sends chills down your spine, there\'s nothing I like more than to sit inside with a warm blanket and a plate full of naan khatais (pronounced \"non cuh-ties\") South Asian almond cookies.",
    "Once you bite into one of these robust, delicate, crispy and slightly nutty cookies, you will be immediately greeted with a comforting flavor, perfect for that day inside from the cold weather. The almonds inside and on top help to add in that extra kick of flavor.",
    {
      format: "block-with-inline",
      content: [
        "With only a few ingredients, these are an easy and quick treat to make with friends and family. Be careful, though; it\'s hard to eat only one! YouTube offers this short and great recipe to follow if you want to make these: ",
        {
          format: "link",
          content: "www.youtube.com/watch?v=LoqhMhi3IfE",
          src: "https://www.youtube.com/watch?v=LoqhMhi3IfE"
        },
        "."
      ]
    },
    {
      format: "byline",
      name: "Maham Khan",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  [
    {
      format: "section",
      content: "Hot Chocolate and Cinnamon Rolls"
    },
    "When you think of Christmas food, what first comes to mind? For me, it\'s a warm mug of peppermint hot chocolate accompanied by mouth-watering cinnamon rolls.",
    "Hot chocolate and cinnamon rolls will forever be my favorite holiday treats because they take me back to my childhood and my roots. Even when I am older and have my own family, you can bet we will always have hot chocolate ready to go and will have the simple yet special cinnamon rolls on Christmas morning.",
    {
      format: "byline",
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  [
    {
      format: "section",
      content: "Boxed Desserts!"
    },
    "Though homemade baked goods are something many people crave during the holiday season, not everyone has the talent, skill or patience to make delicious masterpieces. If you\'re anything like me and my family, boxed desserts are much easier.",
    "For some reason, we can never follow a recipe from scratch. Something always takes a turn for the worse because we tend to freestyle, leading to a messy kitchen and inedible \"treats.\"",
    "Betty Crocker has already perfected recipes with easy directions that are much better suited for my family. Luckily, we have many people in our lives who are master bakers with generous hearts, sharing their yummy confections throughout the season.",
    "But let me clarify: There\'s no shame in only being a boxed dessert baker. By definition, we are still bakers, too!",
    {
      format: "byline",
      school: "Eisenhower High School",
      grade: "junior",
      name: "Olivia Gonzalez"
    }
  ]
]