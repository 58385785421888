import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { articles } from "../articles";
import { LISTOFSCHOOLS } from "../articleInterfaces"
import { addCategory, addHonor } from "./store";
import { useAppDispatch } from "../../store";

function Card(props: { headline: string; children: ReactNode }) {
  return (
    <div>
      <h2>{props.headline}</h2>
      <p>{props.children}</p>
    </div>
  );
}

export function Cards() {
  const dispatch = useAppDispatch();
  
  return (
    <>
      <Card headline="What is Unleashed?">
        Unleashed is a student journalism program by the Yakima Herald-Republic.
        Students from local high schools can apply for the program. Every
        Sunday, articles those students write appear in dedicated section of the
        newspaper.
      </Card>

      <Card headline="What is the goal of Unleashed?">
        Unleashed is designed to give students a voice. It is a platform for
        high schoolers to raise awareness about the things that matter to them.
      </Card>

      <Card headline="What kinds of articles can Unleashed reporters write?">
        Unleashed reporters can write articles on any topic they want. However,
        they are also encouraged to write about high school and other things
        that are relevant to other people their age. After all, who better to
        cover the teenage perspective than teenagers themselves!
      </Card>

      <Card headline="Where are the articles published?">
        The articles that members write are published both on the{" "}
        <a href="https://www.yakimaherald.com/unleashed/">
          Yakima Herald's website
        </a>{" "}
        and in print every Sunday in the Unleashed section.
      </Card>

      <Card headline="How are article topics chosen?">
        While the Unleashed coordinator will sometimes assign a topic, usually
        for{" "}
        <Link to="" onClick={() => dispatch(addCategory("staff"))}>
          staff bios
        </Link>
        , article selection and coordination is left entirely up to the writer.
      </Card>

      <Card headline="How long can you be a member?">
        You can be a member of Unleashed for as long as you are a student of a
        local high school.
      </Card>

      <Card headline="What schools are represented in Unleashed?">
        While I was with the program, Unleashed has featured writers from{" "}
        {LISTOFSCHOOLS.slice(0, -1).join(", ")}, and {LISTOFSCHOOLS.at(-1)}.
      </Card>

      <Card headline="Do members receive training?">
        Unleashed's student reporters receive a lot of training from both the
        group's coordinator and from the Yakima Herald's own reporting staff.
        Members are taught about conflicts of intrest, bias, how to set up and
        conduct interviews, article structure, what should go into an article,
        the various different kinds of articles you can write, and formatting
        rules. Members are also instructed on photography: the rule of thirds,
        lighting, subject selection, and cutline writing. In short, members are
        taught everything they need to know to be successful reporters.
      </Card>

      <Card headline="Are members paid for their work?">
        Members are paid $15 per article and per photo. While the program was
        originally funded by the Yakima Herald, funding now comes from the local
        schools who choose how many spots to sponser.
      </Card>

      <Card headline="How long was I a member of Unleashed?">
        I got started with Unleashed in 2020. I stayed with the program all four
        years of high school, writing a total of {articles.length} articles.
      </Card>

      <Card headline={'What is "Author\'s Choice" or "Editor\'s Pick"?'}>
        Unleashed has a couple of different ways of recognizing the articles
        that its members write. The first is through{" "}
        <Link to="" onClick={() => dispatch(addHonor("award"))}>
          end of the year awards
        </Link>
        . Some of these awards are chosen by Unleashed members (and are usually
        labeled as "Staff Choice Award"), while others are given by the group's
        coordinator, David Goehner. The{" "}
        <Link to="" onClick={() => dispatch(addHonor("top story"))}>
          "Top Story"
        </Link>{" "}
        recognition is given for an article's popularity on the Yakima Herald
        site.{" "}
        <Link to="" onClick={() => dispatch(addHonor("editor's pick"))}>
          "Editor's Pick"
        </Link>{" "}
        is an honor given by the newspaper's editor.{" "}
        <Link to="" onClick={() => dispatch(addHonor("featured"))}>
          "Featured"
        </Link>{" "}
        highlights articles that were featured on the Yakima Herald website.{" "}
        <Link to="" onClick={() => dispatch(addHonor("author's choice"))}>
          "Author's Choice"
        </Link>{" "}
        is the only distinction chosen by me and I use it to highlight articles
        that I particularly enjoyed, found funny, or that are otherwise
        significant to me.
      </Card>
    </>
  );
}