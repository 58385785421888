import { Article } from "../../articleInterfaces";
import { content } from "./content";
import start from "./start.jpg";

const article: Article = {
  content,
  title: "Inside the online start of area high school students' unusual school year",
  articleType: "news",
  author: [
    {
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "sophomore"
    },
    {
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "sophomore"
    },
    {
      name: "Frankie Olivas Jr.",
      school: "Davis High School",
      grade: "senior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "sophomore"
    }
  ],
  date: new Date(2020, 8, 27),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/inside-the-online-start-of-area-high-school-students-unusual-school-year/article_3e8525a6-0f06-56ee-9dcb-bb86bc279455.html",
  images: [
    {
      src: start,
      caption: "First day of school",
      credit: {
        name: "Maria Jacobson",
        school: "Highland High School"
      }
    }
  ],
  topStory: true
};

export default article;