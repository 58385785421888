import { Article } from "../../articleInterfaces";
import { content } from "./content";
import gotg3 from "./gotg.jpg";

const article: Article = {
  title: "'Guardians 3' is an awesome mix of grounded characters and expanded directions",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  date: new Date(2023, 4, 28),
  articleType: "review",
  content,
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/guardians-3-is-an-awesome-mix-of-grounded-characters-and-expanded-directions/article_13670c79-b040-5b3d-8ca6-886d79d00a78.html",
  images: [
    {
      caption: "Five members of the Guardians of the Galaxy, from left, Mantis (Pom Klementieff); Groot (partially obscured, voiced by Vin Diesel); Star-Lord, aka Peter Quill (Chris Pratt); Drax the Destroyer (background, Dave Bautista); and Nebula (Karen Gillan).",
      credit: "Disney/Marvel",
      src: gotg3
    }
  ],
  award: "Staff Choice Award: Favorite Review"
};

export default article;
