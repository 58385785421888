import { Article } from "../../articleInterfaces";
import { content } from "./content";

//Fun fact, I also made a rabboon (GOTG2 misnomer) bracelet
//Although arguably it should be "badger" or any of his other misnomers in GOTG3
import rabboon from "./rabboon.jpeg";
import nov19 from "../resources/nov-19-23.pdf";

const article: Article = {
  //I call the socks the Rocket Socks (hence the file name) because it's a mutiliation of the company's name
  //Other people have, in horror, called them the Sockets ((S)ock of R(ocket))s
  title: "Heroic footwear from online sock retailer is worth the cost of continued sock ads",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  articleType: "review",
  date: new Date(2023, 10, 19),
  //yakimaHeraldURL: new URL("https://www.youtube.com/watch?v=dQw4w9WgXcQ"),
  content,
  authorsChoice: true,
  images: [
    {
      src: rabboon,
      //I editted the caption slightly because it had several gramatical issues because the layout people had to cram a large caption into a small space.
      caption: "Rock 'Em Socks offers an ever-changing catalog of seasonal, sport, and pop culture-themed socks for $10-$25, including this pair Magnus Fulton purchased of Rocket Raccoon, the gun-slinging raccoon charecter from the \"Guardians of the Galaxy\" movies.",
      credit: {
        name: "Magnus Fulton",
        school: "West Valley High School"
      }
    }
  ],
  clipping: nov19
}

export default article;