import { Article } from "../../articleInterfaces";
import { content } from "./content";
import thor from "./thor.jpg";

const article: Article = {
  title: "Newest 'Thor' hammers out comedy, romance, action, and at times a rushed plot",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming junior"
    }
  ],
  date: new Date(2022, 8, 7),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/newest-thor-hammers-out-comedy-romance-action-and-at-times-a-rushed-plot/article_c82fb5d6-3806-5c5b-8ef1-d39df45a4c47.html",
  content,
  images: [
    {
      src: thor,
      caption: "Tessa Thompson and Chris Hemsworth in \"Thor: Love and Thunder.\"",
      credit: "Marvel Studios"
    }
  ]
};

export default article;