import { useAppSelector } from "../../store";
import { calculateWeeklyBudget } from "../store/persistent";
import { Finance } from "./Finance";

import shared from "../../shared/style.module.css";

export function Remaining() {
  const { overage, net } = useAppSelector(calculateWeeklyBudget);
  const isMobile = useAppSelector(state => state.responsive.isMobile);

  return (
    <>
      <p className={`${shared.center} ${isMobile ? shared.large : shared.huge}`}>
        <b>
          Remaining: <Finance amount={net} />
        </b>
      </p>
      <p className={`${shared.center} ${isMobile ? shared.medium : shared.huge}`}>
        Carry-over from last week: <Finance amount={overage} />
      </p>
    </>
  );
}
