import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Nathan Joyner had been working at the recreation center at Central Washington University when his old boss, Ryan Hopkins, gave him a call. Hopkins invited Joyner to join him and several other climbers who were opening a new climbing gym in Yakima. Joyner accepted.",
  "Joyner is now the general manager at the High Steppe Climbing Gym, which opened Sept. 15 at 1015 E. Lincoln Ave. in Yakima near Interstate 82.",
  "\"All the owners are climbers,\" he said. \"We just wanted to bring a climbing gym to Yakima.\"",
  "He immediately noticed how excited people have been about Yakima\'s new attraction.",
  "\"There were two people waiting outside the door at 6 in the morning when we first opened,\" Joyner said.",
  "Harper Breggs, a third grader at Wide Hollow Elementary School in Yakima, is among those who are glad that the local climbing gym has opened.",
  "\"This is the best climbing gym ever,\" she said during a visit on Sept. 26. \"There are so many options; 100 out of 100!\"",
  "What makes a person a climbing enthusiast? Breggs got her start as a climber after her mom took her on a hike. Her mom noticed that her daughter was particularly skilled at climbing rocks.",
  "\"(I like) the challenge of rock climbing,\" said the third grader. \"You get to climb up really high, and feel the fresh air. It\'s fun!\"",
  "And as for Joyner\'s appreciation of the hobby: \"I love the different places that you get to go when you\'re climbing. The community and friends you make is what makes it really special. I love the challenge of climbing, as well; getting to set difficult goals and achieving them.\"",
  "For Joshiah Watters, a freshman at Yakima\'s West Valley High School, a key draw is the workout. \"You really get to test your finger strength, especially because many of the holds are really small,\" he said.",
  "One of the features at the High Steppe Climbing Gym is an auto belay system, where a climber is clipped into a device that functions similarly to a seat belt and lowers the climber to the ground slowly. Benjamin Koreski, a freshman from West Valley High School, loves the system because it allows him to rappel down the climbing walls quickly.",
  "Nolan Preacher, also a freshman at West Valley, has a different perspective.",
  "\"It\'s terrifying! I like the other belay systems more because there is someone below you holding the rope. When you jump off, the auto belay takes a moment to catch you, so it feels like you\'re falling.\"",
  "Falling from high above the ground isn\'t as much of an issue with another part of the climbing gym\'s course. Known as bouldering, this lower-to-the-ground section allows climbers a chance to test their strength when they are not supported by a rope.",
  "\"Bouldering is good exercise,\" Preacher said. \"I like that it\'s all you; you\'re not being supported by anything but your grip. Bouldering also helps build up calluses on your hands so they don\'t get as beat up later.\"",
  "Watters and Preacher are particularly fond of a section of course they dubbed \"The Overhang.\" This area features a negative slope — one that moves back toward the climber instead of away — providing an experience like climbing the underside of a ladder to reach your roof. This section involves many larger hand holds that build up to a good distance off the ground. It makes it the ideal spot to hang from and swing, Preacher said.",
  "There\'s also a section of the course, which some visitors refer to as \"The Cave,\" that features another negative sloping wall. This section requires climbers to regularly clip themselves into carabiners positioned at various spots along the route.",
  "The climbers who come to High Steppe seem have something in common. As expressed by Watters:",
  "\"Many other climbing gyms are a lot smaller, and not as nice as this one,\" he said. \"This one is way better.\"",
  {
    format: "section",
    content: "If you go:"
  },
  "The High Steppe Climbing Gym at 1015 E. Lincoln Ave, Suite 101, Yakima, is open from 8 a.m. to 8 p.m. weekdays and 10 a.m. to 6 p.m. Saturdays and Sundays. Admission is $18 for adults and $14 for children. The gym also offers rentals of shoes ($4) and harnesses ($3)."
]