import { Reducer, combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import budgetReducer, {
  BudgetStore,
  clear,
  putSubscription,
  putTransaction
} from "../budget/store/persistent";
import transientBudgetReducer from "../budget/store/transient";
import directoryReducer from "../unleashed/Directory/store";
import responsiveReducer from "../shared/Responsive/store";

const reducers = {
  budget: budgetReducer,
  directory: directoryReducer,
  transientBudget: transientBudgetReducer,
  responsive: responsiveReducer
};

type ReducerType = typeof reducers;

export type RootState = {
  [K in keyof ReducerType]: ReducerType[K] extends Reducer<infer R> ? R : never;
};

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer<RootState>(
  {
    key: "com.magnusfulton.this",
    whitelist: ["budget"],
    storage
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});
export const persistor = persistStore(store);

persistor.subscribe(() => {
  store.dispatch(clear());
  const data: BudgetStore = {
    start: "",
    transactions: {
      "2023-09-23T16:14:07.915Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 2.99,
        description: "Dune: no ads",
        time: "2023-09-23T16:14:07.915Z"
      },
      "2023-09-23T16:14:17.536Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 20,
        description: "Rocket Socks",
        time: "2023-09-23T16:14:17.536Z"
      },
      "2023-09-23T16:16:06.121Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 3,
        description: "Jelly Beans",
        time: "2023-09-23T16:16:06.121Z"
      },
      "2023-09-23T16:16:25.267Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 17.79,
        description: "Spore, Ori * 2",
        time: "2023-09-23T16:16:25.267Z"
      },
      "2023-09-23T16:16:55.752Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 23.2,
        description: "Miike Snow, OK Computer",
        time: "2023-09-23T16:16:55.752Z"
      },
      "2023-09-23T16:17:08.115Z": {
        type: "transaction",
        action: "deposit",
        amount: 40,
        description: "Unleashed Paycheck",
        time: "2023-09-23T16:17:08.115Z"
      },
      "2023-09-23T16:17:16.771Z": {
        type: "transaction",
        action: "deposit",
        amount: 20,
        description: "Mom Fair Allowance",
        time: "2023-09-23T16:17:16.771Z"
      },
      "2023-09-23T16:17:27.592Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 10,
        description: "Parking",
        time: "2023-09-23T16:17:27.592Z"
      },
      "2023-09-23T16:17:36.698Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 16,
        description: "Admission",
        time: "2023-09-23T16:17:36.698Z"
      },
      "2023-09-23T16:17:45.535Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 3,
        description: "Ice Cream",
        time: "2023-09-23T16:17:45.535Z"
      },
      "2023-09-23T16:17:51.748Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 47,
        description: "Unlimited Ride Pass",
        time: "2023-09-23T16:17:51.748Z"
      },
      "2023-09-23T16:18:01.279Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 15,
        description: "Teriyaki Bowl",
        time: "2023-09-23T16:18:01.279Z"
      },
      "2023-10-01T20:59:09.537Z": {
        type: "transaction",
        action: "deposit",
        amount: 50,
        description: "Grandma Kate Money",
        time: "2023-10-01T20:59:09.537Z"
      },
      "2023-10-01T20:59:41.578Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 9.45,
        description: "Miggy Charge-off",
        time: "2023-10-01T20:59:41.578Z"
      },
      "2023-10-07T13:54:18.354Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 2.67,
        description: "XC burrito tip",
        time: "2023-10-07T13:54:18.354Z"
      },
      "2023-10-13T18:48:00.006Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 10.51,
        description: "Yogurt Shack",
        time: "2023-10-13T18:48:00.006Z"
      },
      "2023-10-13T18:48:11.602Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 5.99,
        description: "Team Dinner Candy",
        time: "2023-10-13T18:48:11.602Z"
      },
      "2023-10-13T20:53:11.923Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 8.63,
        description: "Tame Impala - Currents",
        time: "2023-10-13T20:53:11.923Z"
      },
      "2023-10-13T20:53:23.992Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 10.79,
        description: "twenty one pilots - Vessel",
        time: "2023-10-13T20:53:23.992Z"
      },
      "2023-10-13T20:53:37.358Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 10.79,
        description: "twenty one pilots - Blurryface",
        time: "2023-10-13T20:53:37.358Z"
      },
      "2023-10-13T20:53:47.806Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 10.79,
        description: "Tame Impala - The Slow Rush",
        time: "2023-10-13T20:53:47.806Z"
      },
      "2023-10-14T14:10:16.803Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 15,
        description: "Breast Cancer Shirt",
        time: "2023-10-14T14:10:16.803Z"
      },
      "2023-10-14T14:10:29.414Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 14.11,
        description: "New York Teriyaki",
        time: "2023-10-14T14:10:29.414Z"
      },
      "2023-10-16T01:45:15.421Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 22.04,
        description: "Fiction Archetypes",
        time: "2023-10-16T01:45:15.421Z"
      },
      "2023-10-16T01:45:28.487Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 17.09,
        description: "Cats in space",
        time: "2023-10-16T01:45:28.487Z"
      },
      "2023-10-16T01:45:36.447Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 4,
        description: "Skittles",
        time: "2023-10-16T01:45:36.447Z"
      },
      "2023-10-17T01:45:36.447Z": {
        type: "transaction",
        amount: 19.69,
        description: "Walmart Pop Tarts, Red Vines, and Rocket stuff",
        action: "withdrawl",
        time: "2023-10-17T01:45:36.447Z"
      },
      "2023-10-20T12:54:41.049Z": {
        type: "transaction",
        amount: 9.52,
        description: "Yogurt Shack",

        action: "withdrawl",
        time: "2023-10-20T12:54:41.049Z"
      },
      "2023-10-20T13:05:41.941Z": {
        type: "transaction",
        amount: 20,
        description: "Grandma Kate money",

        action: "deposit",
        time: "2023-10-20T13:05:41.941Z"
      },
      "2023-10-29T21:46:38.659Z": {
        type: "transaction",
        amount: 30,
        description: "Installed Door Knobs",

        action: "deposit",
        time: "2023-10-29T21:46:38.659Z"
      },
      "2023-10-29T21:47:12.225Z": {
        type: "transaction",
        amount: 15.25,
        description: "Five Nights at Freddy's",

        action: "withdrawl",
        time: "2023-10-29T21:47:12.225Z"
      },
      "2023-10-29T21:47:27.802Z": {
        type: "transaction",
        amount: 7.9,
        description: "Movie Popcorn",

        action: "withdrawl",
        time: "2023-10-29T21:47:27.802Z"
      },
      "2023-10-30T21:47:27.802Z": {
        type: "transaction",
        amount: 37.79,
        description: "Jackbox Party Pack 10",
        action: "withdrawl",
        time: "2023-10-30T21:47:27.802Z"
      },
      "2023-10-30T22:47:27.802Z": {
        type: "transaction",
        amount: 35.27,
        description: "Gloves and utility belt for Rocket costume",
        action: "withdrawl",
        time: "2023-10-30T22:47:27.802Z"
      },

      "2023-10-30T20:47:27.802Z": {
        type: "transaction",
        amount: 11.87,
        description: "After the Disco - Broken Bells",
        action: "withdrawl",
        time: "2023-10-30T20:47:27.802Z"
      },
      "2023-10-30T19:47:27.802Z": {
        type: "transaction",
        amount: 10.79,
        description: "Dream Your Life Away - Vance Joy",
        action: "withdrawl",
        time: "2023-10-30T19:47:27.802Z"
      },
      "2023-10-30T18:47:27.802Z": {
        type: "transaction",
        amount: 10.79,
        description: "Lucid Dreams - BoyWithUke",
        action: "withdrawl",
        time: "2023-10-30T18:47:27.802Z"
      },
      "2023-11-11T18:46:27.802Z": {
        type: "transaction",
        amount: 25,
        description: "Driving Max home",
        action: "deposit",
        time: "2023-11-11T18:46:27.802Z"
      },
      "2023-11-11T18:45:27.802Z": {
        type: "transaction",
        amount: 1,
        description: "Max Arcade",
        action: "withdrawl",
        time: "2023-11-11T18:45:27.802Z"
      },
      "2023-11-16T18:45:27.802Z": {
        type: "transaction",
        amount: 1.25,
        description: "Beethoven Music",
        action: "withdrawl",
        time: "2023-11-16T18:45:27.802Z"
      },
      "2023-11-24T23:08:30.413Z": {
        type: 'transaction',
        action: "deposit",
        amount: 40,
        description: "Grandma Kate Money",
        time: "2023-11-24T23:08:30.413Z"
      },
      "2023-11-24T23:08:31.413Z": {
        type: 'transaction',
        action: "withdrawl",
        amount: 8.63,
        description: "In Our Own Sweet Time - Vance Joy",
        time: "2023-11-24T23:08:31.413Z"
      },
      "2023-11-24T23:08:32.413Z": {
        type: 'transaction',
        action: "withdrawl",
        amount: 9.71,
        description: "INTO THE BLUE - Broken Bells",
        time: "2023-11-24T23:08:32.413Z"
      },
      "2023-11-24T23:08:33.413Z": {
        type: 'transaction',
        action: "withdrawl",
        amount: 12.95,
        description: "More Than Just a Dream - Fitz and The Tantrums",
        time: "2023-11-24T23:08:33.413Z"
      },
      "2023-11-24T23:08:34.413Z": {
        type: 'transaction',
        action: "deposit",
        amount: 6,
        description: "Won CLR",
        time: "2023-11-24T23:08:34.413Z"
      },
      "2023-11-25T23:08:34.413Z": {
        type: 'transaction',
        action: "deposit",
        amount: 10,
        description: "Fixed a doorknob and had good teeth",
        time: "2023-11-25T23:08:34.413Z"
      },
      "2023-12-01T00:27:13.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 30.68,
        description: "Bought a whole bunch of songs",
        time: "2023-12-01T00:27:13.598Z"
      },
      "2023-12-07T00:27:13.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 10.79,
        description: "Bought Lethal Company",
        time: "2023-12-07T00:27:13.598Z"
      },
      "2023-12-12T00:27:13.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 82.43,
        description: "Bought lock picks",
        time: "2023-12-12T00:27:13.598Z"
      },
      "2023-12-12T00:26:13.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 14.03,
        description: "Father of the Bride - Vampire Weekend",
        time: "2023-12-12T00:26:13.598Z"
      },
      "2023-12-12T00:27:12.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 1.39,
        description: "Shelter - Broken Bells",
        time: "2023-12-12T00:27:12.598Z"
      },
      "2023-12-16T00:27:12.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 5,
        description: "Ice Breakers - Sours, Watermelon",
        time: "2023-12-16T00:27:12.598Z"
      },
      "2023-12-16T00:28:12.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 9.2,
        description: "Water and gummy bears",
        time: "2023-12-16T00:28:12.598Z"
      },
      "2023-12-16T00:27:11.598Z": {
        type: "transaction",
        action: "deposit",
        amount: 3.43,
        description: "Mom paid me back for water",
        time: "2023-12-16T00:27:11.598Z"
      },
      "2023-12-17T00:27:14.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 4.02,
        description: "Water",
        time: "2023-12-16T00:27:14.598Z"
      },
      "2023-12-17T00:27:15.598Z": {
        type: "transaction",
        action: "withdrawl",
        amount: 4.48,
        description: "Eggs and a cheese stick",
        time: "2023-12-16T00:27:15.598Z"
      }
    },
    subscriptions: {
      "2023-10-07T13:54:29.882Z": {
        type: "subscription",
        action: "withdrawl",
        amount: 21.6,
        description: "ChatGPT Plus",
        renews: "monthly",
        time: "2023-10-07T13:54:29.882Z"
      },
      "2023-09-19T18:46:27.125Z": {
        type: "subscription",
        action: "deposit",
        amount: 20,
        description: "Allowance",
        renews: "weekly",
        time: "2023-09-19T18:46:27.125Z"
      },
      "2023-09-19T18:46:47.662Z": {
        type: "subscription",
        action: "deposit",
        amount: 30,
        description: "Checking Draw Down",
        renews: "weekly",
        time: "2023-09-19T18:46:47.662Z"
      }
    }
  };

  Object.values(data.transactions).forEach((t) =>
    store.dispatch(putTransaction(t))
  );

  Object.values(data.subscriptions).forEach((t) =>
    store.dispatch(putSubscription(t))
  );
});
