import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";

import product from "./product.jpg";
import use from "./use.jpg";

import oct15 from "../resources/oct-15-23.pdf";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "senior"
}

const article: Article = {
  title: "The LifeStraw is a lightweight lifesaver for hiking and camping trips",
  author: [
    ME
  ],
  articleType: "review",
  date: new Date(2023, 9, 15),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/review-the-lifestraw-is-a-lightweight-lifesaver-for-hiking-and-camping-trips/article_4e54b164-c3ef-5feb-9828-609c8dfb4f45.html",
  images: [
    {
      src: product,
      caption: "Originally designed for humanitarian aid, LifeStraws are small, lightweight water filters.",
      credit: ME
    },
    {
      src: use,
      caption: "Using a Lifestraw.",
      credit: ME
    }
  ],
  content,
  clipping: oct15
}

export default article;