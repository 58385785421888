import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Water is heavy. Like, really, really heavy. I had a chance to discover this firsthand while lugging a gallon of water both up and down a ridge while backpacking along the Yakima River.",
  "So I was easily persuaded that there had to be a better way to hydrate in the back country.",
  "I decided to try LifeStraws, which a friend had used before. LifeStraws are an easy-to-use water filter, about the length of a pencil and an inch wide in diameter. They are light and easily stashable in a backpack or around your neck with the carrying cord that's included.",
  "To use a LifeStraw, dip the filter end into any source of water that is a little too alive for comfort and take sips of fresh, clean water from the other end. When you\'re done, blow air through the same end that you sip through to in order to empty out any extra water left in the filter. The caps on both ends of the straw help prevent leakage and keep your bag dry.",
  "A LifeStraw will filter up to 1,000 gallons of water before needing to be replaced. Compared to other water treatment methods I\'ve used, including boiling the water, a LifeStraw is small and simple.",
  "LifeStraw was originally designed around 20 years ago as a tool to help those in developing nations get access to clean drinking water. The devices have been used to help filter out Guinea worm larva as well as other parasites and bacteria. The manufacturer claims that LifeStraws can now remove more than 99.999% of bacteria — including E. Coli, cholera, and typhoid — and more than 99.999% of parasites and microplastics.",
  "The straw's manufacturer continues its humanitarian mission, and promises that a child in need receives safe water for an entire year with every product sold.",
  "You can purchase a LifeStraw for yourself online or at stores that sell camping supplies. I got mine for $20 at a store in Yakima to be ready for my next camping trip. ",
]