import { Article } from "../../articleInterfaces"
import { content } from "./content";

const article: Article = {
  title: "Untraditional features make Discord a sleek instant messaging app",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  date: new Date(2021, 5, 27),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/untraditional-features-make-discord-a-sleek-instant-messaging-app/article_143ba382-ee92-587c-9908-8590ab720cf6.html",
  content
};

export default article;