import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { store } from "../../store";

interface ResponsiveStore {
  isMobile: boolean
}

type ReducerFunction<T> = (
  state: ResponsiveStore,
  payload: PayloadAction<T>
) => void;


interface Reducers {
  //Unused, mostly to get Redux to shut up
  [k: string]: ReducerFunction<any>;

  setIsMobile: ReducerFunction<boolean>;
}

const responsiveSlice = createSlice<ResponsiveStore, Reducers>({
  name: "responsive",
  initialState: {
    isMobile: isMobile()
  },
  reducers: {
    setIsMobile: (s, p) => {s.isMobile = p.payload}
  }
});

export default responsiveSlice.reducer;

export const { setIsMobile } = responsiveSlice.actions;

function isMobile() {
  return window.innerWidth / window.innerHeight < 1;
}

window.addEventListener("resize", () => store.dispatch(setIsMobile(isMobile())))