import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "On Feb. 2, DTG Recycle closed access to some of the trails in the Rocky Top trails system west of Yakima as the organization expanded the operations of its limited-use landfill there.",
  "Part of the Cowiche Canyon Conservancy\'s trail system, Rocky Top has been a system of trails for biking and hiking that I have enjoyed since I learned how to ride a bike. For me, the trails were a great way to escape from everyday life to relax, think and exercise.",
  "On Feb. 3, I drove there, hoping to get in a last goodbye before part of the trails closed forever.",
  "Unfortunately, it was clear the damage was already done. The Horse Trail was fenced off from its intersection with Green Harvester and onward uphill, extending to the halfway mark of a section of the Tooled and Schooled and the Orange Harvester trails. A large mound of dirt had also been erected near the Horse Trail in the fenced-off area a ways off uphill from the fence. \"No Trespassing\" signs dotted the fence line.",
  "So how did a part of Rocky Top go from hiking trails to an ominous dirt mound? Anderson Rock and Demolition Pit founder Ron Anderson (not to be confused with a former Yakima County commissioner of the same name) operated a limited-purpose landfill for some time. This landfill sought to salvage or recycle concrete, asphalt, metal, plastic and wood waste.",
  "Anderson gave the Cowiche Canyon Conservancy a conservation easement on 42 acres around the trailhead. Anderson also gave the Single Track Alliance of Yakima (a local single track mountain biking and trail conservancy group) permission to build trails on an unused portion of his land.",
  "In 2019, DTG Recycle bought the property from Anderson and indicated that it intended to keep the trail\'s arrangement as is. However, as the company sought to expand operations, it ran into problems with regulations and complaints from neighbors. After moving operations around several times throughout their property, DTG settled on closing off a portion of its part of the Rocky Top trails.",
  "It was sad to see a portion of the trails that had welcomed me and many others become fenced off. My family has made frequent trips to the Rocky Top trails over the years. We would walk along the Walk N Roll trail and enjoy the view down into the valley. Occasionally, I would bring my bike along and race my dog as I rode the Green Harvester and Wholly Moses trails.",
  "On Feb. 16, the Cowiche Canyon Conservancy announced that DTG had closed another 80 acres of land. This latest closure affects a large part of the Dumb & Dumber trail and cuts off access to the end of Tooled-n-Schooled. A small part of Orange Harvester was also cut off in the latest closure.",
  "In a news release, DTG signaled that it intends to work with the William O. Douglas Trail Foundation \"to maintain safe access to this historic trail\" around the landfill.",
  "However, the future of the Rocky Top trail system is still uncertain. The recent DTG closures, covering about 160 acres or 20% of the trail system, significantly reduce access to about 50% of the trails.",
  "Moreover, the closures make accessing the rest of the trail system much more challenging. The Walk N Roll trail is the primary means that hikers and bikers have to access trails such as Dynamite, Mojo Rising, TNT, and Taco Traverse.",
  "The larger Cowiche Canyon Conservancy trail system that included Rocky Top is a cherished part of my early memories. I remember taking field trips down to Snow Mountain Ranch during my elementary school years and walking the riparian trail. Cub Scout trips to the Cowiche Canyon Trail and the Uplands fostered a love of hiking and the outdoors. A piece of that quilt of trails and memories is now missing.",
  "The Rocky Top trails were a place to escape to and enjoy nature. They were a place to get out, move a little and relax. I\'m going to miss those trails. At least I was able to enjoy them while they lasted.",
  {
    format: "unordered-list",
    content: "Magnus Fulton is the son of Nate Fulton, a member of the Cowiche Canyon Conservancy Board."
  }
]