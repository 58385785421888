import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/summer-cell-phone-tips-touching-on-contactless-payment-apps/article_154570c9-c61b-5f5b-ba4c-eda955b5ac21.html",
  title: "Summer cell phone tips: Touching on contactless payment apps",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming sophomore"
    }
  ],
  date: new Date(2021, 7, 22),
  content
}

export default article;