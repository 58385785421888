import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "Nothing says summer vacation like a blockbuster movie. It\'s no secret that I\'m a huge Marvel fan and, while I\'ve rewatched all the movies more times than I\'d like to admit, \"Guardians of the Galaxy\" and \"Thor\" have always been some of my favorite characters in the franchise.",
  "In \"Thor: Love and Thunder,\" the \"ragtag motley crew of misfit desperadoes\" (Thor\'s words) and the God of Thunder himself join forces in a thunderous fourth installment of lead actor Chris Hemsworth\'s series.",
  "There was an electric buzz in the theater as the lights dimmed. The opening scene shows Gorr, the film\'s soon-to-be antagonist, as a caring father seeking help for his sick daughter. Shortly after, he has an unsuccessful meeting with his people\'s unhelpful patron god, a meeting that ultimately arms him with a new weapon, the god-killing Necrosword, as well as a new murderous intention.",
  "This opening scene is so unlike anything I was expecting that I thought it was another preview until I realized just how long it was going. This isn\'t to say that it was bad. Quite the contrary. It really helped build out the background of Gorr so you know who he is and where he\'s coming from.",
  "As with Thanos from earlier Marvel movies, Gorr is complicated because you can see he has good intentions. But his execution of that is ... undesirable. After all, Gorr ultimately just wants all gods everywhere to learn a little humility and to retake their roles as stewards of their people. While the character does swing wildly at times, sometimes to the point of being unnecessarily over the top, Gorr is still one of my favorite Marvel antagonists (can we really call these complex characters \"villains\"?) from the more recent movies.",
  "Gorr also introduces some interesting mechanics to the film. I liked this because it allows for a couple of fights that couldn\'t be won instantly with just a hammer or a sword. Many fight scenes draw on some elements of horror, as well, mixing in aspects of what you can see and, more importantly, what you can\'t.",
  "Thor, on the other hand, has been through some rough patches. It\'s understandable that he might be just a little bit shaken up on the inside after being banished from his home world, losing his friends and family, and fighting countless battles.",
  "So as we get started with \"Love and Thunder,\" the god of thunder seems just a little ... lost. Whether he\'s stoically meditating on a hillside or creatively riding into battle, it\'s clear from the start that Thor just needs to figure some stuff out.",
  "The reappearance of Dr. Jane Foster (portrayed by Natalie Portman), his old flame from the first two movies in the Thor series, initially does little to help re-center Thor — especially at a time when his whole armor getup screams \"overcompensating.\" However, her reappearance is accompanied by some long-awaited answers to the questions that surrounded their relationship, and why they broke up.",
  "In this latest Marvel installment, the lead character is the first boom of thunder, but the movie is a chaotic energy storm. As a whole, \"Thor: Love and Thunder\" takes it all on: comedy and romance, action and horror, social commentary and self-discovery. In whiplash succession, the movie shifts from deep, serious questions about Jane Foster\'s future, to Thor\'s bumbling comedy, to horror that will glue to you to your seat.",
  "It\'s no wonder that screaming goats, space dolphins and a love triangle between weapons fit right into this action-packed adventure.",
  "Unfortunately, while these lightning-quick reversals and action make the film riveting to watch, it also creates a rushed feel and opens enough plot holes to sink a boat. The rush of the film also cuts into the screen time of the Guardians of the Galaxy, who are a major component of the trailer but receive a less-than-major role in the movie. The film also sneaks in a couple of Easter eggs, as well (so keep your eyes peeled if you were a fan of \"The Eternals\").",
  "Nonetheless, \"Thor: Love and Thunder\" is an entertaining movie that I had fun watching, and re-watching, on the big screen."
]