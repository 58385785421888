import { Article } from "../../articleInterfaces";
import { content } from "./content";
import avatar from "./avatar.jpg";

const article: Article = {
  title: "Off The Chain: Unleashed's picks for February",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  date: new Date(2023, 1, 19),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/off-the-chain-unleasheds-picks-for-february/article_b4089465-11eb-5813-9904-8e547bdc196f.html",
  images: [
    {
      credit: "Courtesy photo",
      caption: "\"Avatar: The Way of Water\"",
      src: avatar
    }
  ],
  content
};

export default article;