import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "The tiny computers we carry in our back pockets always seem to have another trick up their protective cases. While mastering it all would likely require the dedication and knowledge of a Ph.D., there are a few features you might not know about that can save you some hassle.",
  {
    format: "section",
    content: "Contactless payment"
  },
  "We have all seen the signs encouraging us to use \"contactless payment.\" But what is it, and how do you set it up?",
  "Many newer iPhones, Android-based devices and even some credit cards allow you to tap them directly on the pay terminals, instead of the usual swiping, chip reading,and pin panic remembering routine we are used to.",
  "On many newer iPhones, you can open the built-in \"Wallet\" app, push the plus icon in the top right corner, and enter your credit card details. Once complete, anytime you go to pay for something, rather than whipping out that shiny plastic square, you can instead click your phone\'s power button twice in a row, enter your password, and tap it to the card reader.",
  "On newer Android-based devices, credit cards can be added through the \"Google Pay\" app. Once added, you can pay by unlocking your phone, and tapping it on the card reader. Your phone will launch the Google Pay app, prompt for a password, and will complete the transaction, all without having to touch a pin pad that has been touched by the thousands of people who paid before you."
]