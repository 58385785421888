import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import band from "./band.jpg";
import heirloom from "./heirloom.jpg";
import booth from "./info-booth.jpg";
import market from "./market.jpg";
import pavilion from "./pavilion.jpg";
import sep3 from "../resources/sep-3-23.pdf";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School"
};

const article: Article = {
  title: "Vendors at Downtown Yakima Farmers Market are loving the change to Rotary Marketplace",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  articleType: "news",
  date: new Date(2023, 8, 3),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/vendors-at-downtown-yakima-farmers-market-are-loving-the-change-to-rotary-marketplace/article_4806431a-9805-54d5-9b62-b28ab620f333.html",
  images: [
    {
      caption: "The Downtown Yakima Farmers Market moved to 15 W. Yakima Ave. earlier this year. This pavilion, built with the help of Yakima's three Rotary clubs, helps shade customers and vendors alike.",
      src: pavilion,
      credit: ME
    },
    {
      credit: ME,
      caption: "Valentina of European Heirloom Produce lived in Ukraine before moving to the United States.",
      src: heirloom
    },
    {
      caption: "Carol Minear (left) and Melissa Gorham (right) help answer questions at the Downtown Yakima Farmers Market on Aug. 20, 2023. They also help customers redeem and match their EBT/SNAP benefits through the Market Match program and help save vendors on their card transaction fees through the Market Token program.",
      src: booth,
      credit: ME
    },
    {
      caption: "Whether you want fresh produce, personal care products or artisanal crafts, you can find them at the farmers market.",
      src: market,
      credit: ME
    },
    {
      caption: "Ryan Lambert (right) and Robert \"the Bear\" Herbert (left) perform \"House of the Rising Sun\" as \"Ryan and the Bear\" at the Downtown Yakima Farmers Market. The group has been performing at the market for three years.",
      src: band,
      credit: ME
    }
  ],
  content,
  editorsPick: true,
  authorsChoice: true,
  clipping: sep3
};

export default article;