import { Article } from "../../articleInterfaces";
import { content } from "./content";

import ms from "./ms.webp";
import jh from "./jh.webp";
import cb from "./cb.jpg";

const article: Article = {
  articleType: "review",
  author: [
    {
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "senior"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "senior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  date: new Date(2023, 4, 21),
  title: "Ten years later: Why we still love 'The Office'",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/ten-years-later-why-we-still-love-the-office/article_30052464-e5e5-574d-b394-3496a34c2d0e.html",
  images: [
    {
      credit: "NBC photo",
      caption: "Steve Carell as Michael Scott in \"The Office\"",
      src: ms
    },
    {
      credit: "NBC photo",
      caption: "Jenna Fischer as Pam Beesly and John Krasinksi as Jim Halpert in \"The Office\"",
      src: jh
    },
    {
      credit: "NBC photo",
      caption: "Creed Bratton as, um, Creed Bratton in \"The Office\"",
      src: cb
    }
  ],
  content,
  topStory: true,
  award: "Best TV Review"
};

export default article;