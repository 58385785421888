import { Article } from "../../articleInterfaces";
import leia from "./leia.jpg";
import landfill1 from "./landfill-1.jpg";
import landfill2 from "./landfill-2.jpg";
import { content } from "./content";

//This article was really neat for me because it was the first time that the Yakima Herald had published me outside the Unleashed section. They used one of my images for an editorial.
//Here's the link to that editorial: https://www.yakimaherald.com/opinion/editorials/opinion-dtg-should-try-harder-to-open-trail-access-in-cowiche-canyon/article_34b4384c-686c-11ee-830e-bf2cb5e0ac0b.html
const article: Article = {
  title: "For local hikers, a piece of our beloved trail system is now missing",
  articleType: "column",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  date: new Date(2023, 2, 19),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/commentary-for-local-hikers-a-piece-of-our-beloved-trail-system-is-now-missing/article_86f3493d-9b9b-5781-a746-b43780f4ae19.html",
  images: [
    {
      credit: "Magnus Fulton",
      caption: "Leia, a chocolate Labrador, mourns the closure of part of the lower Rocky Top trail system on Friday, Feb. 3, 2023, after DTG Recycle expanded operations in Yakima, Washington.",
      src: leia
    },
    {
      caption: "A fence closes off a portion of the lower Rocky Top trails on Friday, Feb. 3, 2023, after DTG Recycle closed public access to part of their portion of the Rocky Top trail system in Yakima, Washington.",
      credit: "Magnus Fulton",
      src: landfill1
    },
    {
      caption: "A fence closes off a portion of the lower Rocky Top trails on Friday, Feb. 3, 2023, after DTG Recycle closed public access to part of their portion of the Rocky Top trail system in Yakima, Washington.",
      credit: "Magnus Fulton",
      src: landfill2
    }
  ],
  content,
  authorsChoice: true
};

export default article;