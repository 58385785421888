import { ChangeEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ArticleType } from "../../articleInterfaces";
import { Honors, Sort, addCategory, addHonor, removeCategory, removeHonor, setQuery, setSortDates } from "../store";
import style from "./index.module.scss";

const ARTICLE_TYPES: ArticleType[] = ["column", "staff", "news", "review"];
const SORT: Sort[] = ["ascending", "descending"];
const HONORS: Honors[] = [
  "top story",
  "editor's pick",
  "award",
  "author's choice",
  "featured"
];

interface FilterProps<T> {
  type: "radio" | "checkbox";
  name: "sort" | "honor" | "category";
  value: T;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function ConditionalSpace() {
  const isMobile = useAppSelector(state => state.responsive.isMobile);

  if (isMobile) {
    return null;
  } else {
    return <span>&nbsp;&nbsp;</span>;
  }
}

function FilterOption<T extends string>(props: FilterProps<T>) {
  return (
    <div>
      <ConditionalSpace />
      <label htmlFor={props.value}>
        {props.value.at(0)?.toUpperCase() + props.value.slice(1)}{" "}
      </label>
      <input
        name={props.name}
        type={props.type}
        id={props.value}
        onChange={props.onChange}
        checked={props.checked}
      />
    </div>
  );
}

export function SearchMenu() {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(state => state.responsive.isMobile);

  const query = useAppSelector((state) => state.directory.query);
  const honors = useAppSelector((state) => state.directory.honors);
  const categories = useAppSelector((state) => state.directory.category);
  const sortDates = useAppSelector((state) => state.directory.sortDates);
  
  return (
    <>
      <div className={`${style.searchMenu} ${isMobile ? style.mobile : style.desktop}`}>
        <div>
          <input
            name="search query"
            type="search"
            placeholder="Search"
            onChange={(e) => dispatch(setQuery(e.target.value))}
            value={query}
          />
        </div>
        <div className={style.optionList}>
          <span>Sort published date: </span>
          {SORT.map((s) => (
            <FilterOption
              name="sort"
              type="radio"
              onChange={() => dispatch(setSortDates(s))}
              value={s}
              checked={sortDates === s}
              key={s}
            />
          ))}
        </div>
        <div className={style.optionList}>
          <span>Honor: </span>
          {HONORS.map((h) => (
            <FilterOption
              name="honor"
              type="checkbox"
              onChange={(e) =>
                dispatch(e.target.checked ? addHonor(h) : removeHonor(h))
              }
              value={h}
              checked={honors.includes(h)}
              key={h}
            />
          ))}
        </div>
        <div className={style.optionList}>
          <span>Category: </span>
          {ARTICLE_TYPES.map((a) => (
            <FilterOption
              name="category"
              type="checkbox"
              onChange={(e) =>
                dispatch(e.target.checked ? addCategory(a) : removeCategory(a))
              }
              value={a}
              checked={categories.includes(a)}
              key={a}
            />
          ))}
        </div>
      </div>
    </>
  );
}
