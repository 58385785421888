import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "A candy connoisseur's guide to Halloween goodies",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  date: new Date(2020, 9, 25),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/a-candy-connoisseurs-guide-to-halloween-goodies/article_5445c585-0e6c-5062-a667-2b8fe20eb8ab.html",
  content,
  award: "Best Humor Writing"
}

export default article;