import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "Summer cell phone tips: A focus on camera features",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/summer-cell-phone-tips-a-focus-on-camera-features/article_ad5818d8-4c53-5b4a-96bc-a05bff0c1f91.html",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming sophomore"
    }
  ],
  date: new Date(2021, 6, 25),
  content
};

export default article;