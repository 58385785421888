interface ReadItOnProps {
  url?: string;
  where: string;
  showSep?: boolean;
}

export function ReadItOn(props: ReadItOnProps) {
  if (!!props.url) {
    return (
      <>
        {props.showSep ? " | " : ""}
        Read it <a href={props.url}>{props.where}</a>
      </>
    );
  } else {
    return null;
  }
}
