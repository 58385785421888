import { Article } from "../../../articleInterfaces";
import { extractText } from "../../processing/extractText";

//Based on the common knowlege of the internet
const AVERAGE_READING_SPEED = 250;

export function ReadingTime(article: Article) {
  const readingTime = Math.ceil(
    extractText(article.content).split(" ").length / AVERAGE_READING_SPEED
  );
  
  return <>~{readingTime} minute read</>;
}