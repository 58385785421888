import { Author, JSONDSL } from "../../articleInterfaces";

import ghouls from "./ghouls.jpg";
import rocks from "./rocks.jpg";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "junior"
}

export const content: JSONDSL = [
  {
    src: ghouls,
    caption:
      "Ghosts, ghouls, giants, oh my! A dark spirit has clearly taken residence at this abode at the crossroads of Yakima's 54th Avenue and Whitman Street.",
    credit: ME
  },
  {
    credit: ME,
    src: rocks,
    caption:
      "No pumpkins? No problem! This house just off of West Prasch and South 72nd Avenue in Yakima rocks its own Halloween style with colorful spray-painted stones."
  }
]