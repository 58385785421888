import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Really, it\'s quite simple: Cadbury caramel eggs are the best kind of Easter egg. These eggs combine two of humanity\'s greatest inventions — milk chocolate and caramel — into a treat that almost everyone can enjoy.",
  "But how do these enticing ellipsoids stack up against the competition?",
  "At first glance, it\'s easy to dismiss these eggs as deviating too far from Easter egg hunting traditions. After all, how can we call it a true egg if it hasn\'t been painted carefully by hand with friends and family?",
  "While the foil packaging is festive enough in its own right, these eggs offer an attractive alternative. They allow families to get straight to the part of the holiday that their children will remember most — the hunt itself — while also providing a delicious snack for those who have graduated from their egg-hunting years.",
  "But as far as the egg hunt itself goes, what purpose does it serve if all the eggs are the same? As delectable as it might be, a hunt with only Cadburys would hardly be a hunt at all.",
  "Plastic eggs are a great and necessary component to any egg hunt. However, while they add variety in sweets, they lack in the capacity and dependability of a Cadbury.",
  "All in all, Cadbury caramel eggs are a guaranteed crowd-pleaser for any Easter celebration."
]