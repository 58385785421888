import { Article } from "../../articleInterfaces";
import { content } from "./content";
import stopsign from "./stopsigns.jpg";

const article: Article = {
  title: "In praise of our special roadside friend, the stop sign",
  articleType: "column",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/commentary-in-praise-of-our-special-roadside-friend-the-stop-sign/article_011b3e6f-c7a8-51a6-a86b-61a2b1b7c903.html",
  date: new Date(2022, 10, 6),
  images: [
    {
      src: stopsign,
      caption: "This four-way stop brings order out of chaos at the intersection of North 56th and Englewood in Yakima.",
      credit: "Magnus Fulton, West Valley High School / Unleashed"
    }
  ],
  content,
  authorsChoice: true
};

export default article;