import { Article } from "../../articleInterfaces";
import apr20 from "../resources/apr-20-24.pdf";
import kenyon from "./kenyon.webp";
import bracamontes from "./bracamontes.webp";
import { content } from "./content";

const abell = "Evan Abell / Yakima Herald-Republic"

const article: Article = {
  articleType: "news",
  author: [
    {
      school: "Yakima Herald-Republic",
      name: "Jasper Kenzo Sundeen"
    }, 
    {
      name: "Sebastian Smartlowit",
      school: "Wapato High School",
      grade: "senior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  date: new Date(2024, 3, 20),
  title: "Nationwide FAFSA issues hit colleges and students in Central Washington",
  authorsChoice: true,
  clipping: apr20,
  featured: true,
  spotlight: true,
  yakimaHeraldURL: "https://www.yakimaherald.com/news/local/education/nationwide-fafsa-issues-hit-colleges-and-students-in-central-washington/article_325e1730-fc1d-11ee-ba51-77b27779c99c.html",
  images: [
    {
      credit: abell,
      caption: "Central Washington University TRIO Educational Opportunity Center advisor Arturo Santana, left, helps Madison Kenyon during a FAFSA workshop at Yakima Valley College Wednesday, April 17, 2024, in Yakima, Wash.",
      src: kenyon
    },
    {
      credit: abell,
      caption: "Yezenia Bracamontes, program specialist, right, answers financial aid questions for Carlos Cortes, left, during a FAFSA workshop at Yakima Valley College Wednesday, April 17, 2024, in Yakima, Wash.",
      src: bracamontes
    }
  ],
  content
};

export default article;