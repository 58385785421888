import { Grade, JSONDSL, Name, Schools } from "../../articleInterfaces";

function person(
  name: Name,
  school: Schools,
  grade: Grade,
  yearInUnleashed: "first" | "second" | "third" | "fourth",
  aboutMe: string,
  book: string
): JSONDSL {
  return [
    {
      format: "section",
      content: name
    },
    {
      format: "italics",
      content: `${school}, ${grade}, ${yearInUnleashed} year in Unleashed`
    },
    {
      format: "block-with-inline",
      content: [
        {
          format: "bold",
          content: "About me: "
        },
        aboutMe
      ]
    },
    {
      format: "block-with-inline",
      content: [
        {
          format: "bold",
          content: "Favorite childhood book: "
        },
        book
      ]
    }
  ];
}

export const content: JSONDSL = [
  "The milestone 25th team of Unleashed has arrived!",
  "The 2023-24 group is the present-day legacy of 35 young people who were recruited by the Yakima Herald-Republic from 13 area high schools in the spring of 1999 to begin its weekly teen journalism section, which debuted Sept. 7, 1999, originally appearing on Tuesdays.",
  "It\'s two dozen years later, and Unleashed is still offering student-generated features, columns, photography and illustrations for our readers — now on every Sunday, and with a reach that includes online content and a Facebook site. Since 2009, Unleashed has also involved a partnership of the newspaper with Educational Service District 105 and participating high schools.",
  "Plus, the 25th team arrives with another notable milestone: It\'s the first one to include a second-generation member of the program. First-time member Elise Klepach of Highland is the daughter of Scott Klepach Jr., who was a senior at Eisenhower High School as part of Unleashed\'s original group in 1999.",
  "Meet the full 2023-24 team:",
  [
    person(
      "Axel Fulton",
      "West Valley High School",
      "junior",
      "first",
      "Some of my hobbies are going golfing every weekend with my family and playing tennis with some amazing people at the Yakima Tennis Club every week. I also have a very energetic 5-year-old chocolate Lab named Leia who loves to play fetch and stampede around my house. What drew me to this wonderful program was my love of writing Yelp reviews, which prompted me to want to do food reviews in the paper. My uncle Matt was the one who introduced me to Yelping after a fun dining experience we had on a trip a few years back.",
      "One of my favorite books when I was very little was \"The Very Hungry Caterpillar\" by Eric Carle, which is a children\'s book about, well, a very hungry caterpillar. Looking back at it now, I can see some definite foreshadowing for my future. I look forward to writing for the paper, and I hope all of you can enjoy what I write!"
    ),
    person(
      "Magnus Fulton",
      "West Valley High School",
      "senior",
      "fourth",
      "Senior year! It still hardly feels real. I remember thinking back in second grade how far away senior year seemed. This year I\'m involved with ASB, helping to plan the assemblies, dances and spirit activities that make high school fun. I also serve as a student representative on the West Valley school board, helping provide a student perspective in policy decisions. In the Running Start program at Yakima Valley College, I\'m working on an associate degree that I\'m hoping to transfer as I work toward a bachelor\'s degree in computer science.",
      "I loved the \"Guardians of Ga\'Hoole\" series when I was growing up. Written by Kathryn Lasky, the 16-book series focuses on a band of owls as they grow up in a magical world, fight evil, explore and navigate life. Owls were my favorite animal when I was younger, so naturally I was hooked. Sometime in the second or third grade, I remember my dad bought me the entire series at a bookstore. Owning and reading the entire series made the books an early prized possession and the series retains a spot on my bookshelf to this day."
    ),
    person(
      "Natalie Hernandez",
      "Eisenhower High School",
      "junior",
      "first",
      "I am taking part in programs to help achieve my goal of a profession in the medical field. I joined the Unleashed program to help me continue my love for writing and to share my world views with strangers. Writing has been something I have found to be a safe place for myself, and I am excited to bring new views to you.",
      "\"Love You Forever\" by Robert Munsch. My mother started reading this book to me from a very early age, and since then it has created a bond between us. No matter how far I am, or where I am in life, my heart\'s quote to my mother is: \"I\'ll love you forever, I\'ll like you for always, as long as I\'m living, my baby you\'ll be.\""
    ),
    person(
      "Indiana Hilmes",
      "Selah High School",
      "senior",
      "fourth",
      "This marks the fourth and final staff bio of my Unleashed career! Over the last few years I have been so blessed to cover everything from the latest music release to breaking national news — and I\'ve grown tremendously as both a writer and a person. When I\'m not writing, I compete with my knowledge bowl team (2023 national champions!), run cross country, play tennis and listen to a lot of weird music. Unleashed has become a safe space for me to share my thoughts with the community, so thank you for welcoming my words regardless of how messy and unorganized they might\'ve been over the years. Here\'s to the 25th Unleashed team!",
      "\"Warriors\" by Erin Hunter. It was about a society of feral cats and their adventures. I feel like that says a lot about me."
    ),
    person(
      "Mallory Keller",
      "Selah High School",
      "sophomore",
      "first",
      "I am excited to be able to step outside of my comfort zone and be part of the Unleashed team this year! I hope to cover topics such as music, sports and traveling, and really just anything that sparks my interest. At school, I play basketball, run cross country and track, and serve as my DECA chapter\'s treasurer. During my free time, I enjoy listening to music and spending time with family.",
      "\"The Chocolate Touch\" by Patrick Skene Catling. The interesting fantasy plot made the book impossible to put down."
    ),
    person(
      "Maham Khan",
      "West Valley Virtual Academy",
      "junior",
      "third",
      "Hi! I am entering my third year of Unleashed, an incredible program that has helped me meet new people, share my thoughts and experiences and grow as a writer. From my favorite foods to special holidays, Unleashed has also provided a platform to share gems from my culture. A big thank you to the program coordinator, David Goehner, who has helped make all of this possible. I am also entering my second year of online school, working through a couple of different programs in partnership with the West Valley School District. When I\'m not working on my courses, I enjoy trying new things (especially new foods), reading a variety of materials, baking delicious treats and spending time with my amazing family.",
      "It\'s hard to choose a single book as there are so many good ones. I love many different books for different reasons so I simply can\'t pick one over the others."
    ),
    person(
      "Elise Klepach",
      "Highland High School",
      "junior",
      "first",
      "Ever since I was a tiny child I have always loved to read, write, draw, sing and act. I love all things creepy, weird, out-there and even just plain humorous. Some of my other favorite pastimes are playing my ukulele, binging TV shows or movies and hanging out with my dog and chickens. I am involved in drama, Knowledge Bowl, DECA, and now Unleashed. I\'m excited to be a part of this team and help get my own voice — and other voices — on a page for all to see.",
      "My favorite book as a little kid was \"The Kissing Hand,\" written by Audrey Penn and illustrated by Ruth Harper. I loved this book because my mom would read it to me and gave me my own \"kissing hand\" when I felt sad or anxious."
    ),
    person(
      "Rosie Pandit",
      "Selah High School",
      "sophomore",
      "first",
      "I love to read and paint. I am not good at painting at all, but I thoroughly enjoy the art of it. I picked up painting about three summers ago, and I\'ve made many paintings of sunsets, flowers and beaches. Painting is a rare thing for me to do nowadays, but I love the moments when I can just sit down with a paintbrush and some paint. I also love playing in the band, specifically the jazz band. I frequently solo on alto saxophone in jazz band, and the people in the band always bring a smile to my face. My life truly has been changed for the better because of the band.",
      "\"The Tale of Despereaux.\" I loved the little adventures the tiny mouse went through, but the main thing that stuck out to me was the little breaks in the story where the author would dedicate a chapter to talk to the reader. I strongly recommend the book for all ages."
    ),
    person(
      "Cameron Pelson",
      "Selah High School",
      "junior",
      "first",
      "I enjoy playing guitar. I have played guitar since I was 11, so that makes almost six years now. I play on my church\'s worship team and love using my gifts for God. I am an integral member of my youth group, in which I have made some of my best friends. My favorite subject is math and, after high school, I plan to go to college to study engineering. I joined Unleashed because I love writing and telling stories. Despite having what I have been told is a \"math brain,\" I like to be creative, and playing guitar and writing stories are great ways to do that.",
      "\"The Very Hungry Caterpillar.\" As a kid, I think I liked the book for its incredible illustrations. But now I find the idea of growth and change as you eat your way through life to relatable to a teen in his last couple of years in high school."
    ),
    person(
      "Mya Rankin",
      "Eisenhower High School",
      "sophomore",
      "first",
      "I\'m the youngest sister of three. When I get to see my older sisters, one of the things we enjoy doing most is to go shopping together. Halloween is my all-time favorite holiday because I like dressing up as different characters and decorating my room. I\'m a photographer and enjoy taking pictures of nature and my cat, Stewart. One of my go-to pastimes is doing artistic makeup on myself of all kinds when I find the right picture for inspiration.",
      "\"Bear Snores On\" by Karma Wilson. This is one of my favorite childhood books because my parents used to read it to me all the time and it is nostalgic for me."
    ),
    person(
      "Ava Schoonover",
      "West Valley High School",
      "junior",
      "first",
      "Writing an introduction about yourself can be unnecessarily complicated. In this instance, finding where to start is the most complex. However, I believe an easy way to get a sense of who someone is would be to know that person\'s goals, since that can provide a good trajectory about a person. With that being said, I hope this year\'s Unleashed program will help me gain a better sense of community by acting as an open door to opportunity. Most of all, I\'m putting my hope in the God who will send me through that door, continuing to purposely place me around his loved people of Yakima. I pray God gives me the words and the courage to make his glory known through my work.",
      "I\'d have to go with \"Green Eggs and Ham\" by Dr. Seuss. I can remember my mom surprising me with the book after a long Sunday church service. I was about 5 years old, and it was the first book that taught me how to read. I re-read the book about eight times on a 40-minute drive to Wapato, and when I arrived at my grandparent\'s house I had my first live audience to read to. Now the book brings me a sense of accomplishment, and I deeply enjoy reading it to my little brothers!"
    ),
    person(
      "Sebastian Smartlowit",
      "Wapato High School",
      "senior",
      "first",
      "Beyond my academic studies, I enjoy hunting, fishing, reading, writing, and running track and field.",
      "\"Just One More Story.\" This book is enjoyable because it describes a family of pigs as the mother is doing her best to put her child to bed but the child wants to keep reading. However, the cute story is not the sole reason I appreciate this book. It\'s also the detailed interior with textured tiny stories woven into the text that still wins me over."
    ),
    person(
      "Jillian Strother",
      "Riverside Christian School",
      "senior",
      "third",
      "When I was entering ninth grade, I got halfway through the Unleashed application but didn\'t ever submit it. It\'s weird to think that I almost never applied, especially since now I\'m starting my third year in the program. I feel that I\'ve grown a lot in my writing since I joined the Unleashed team. Not only have I learned how to better voice my thoughts, but I\'ve been able to really channel my creativity. Outside of Unleashed, I\'m a part of my school\'s ASB, cheer and golf programs. I also love listening to and making music, writing poetry, thrifting for sweaters, and drinking tea (with honey, of course).",
      "I have vivid memories of reading \"Love That Dog\" by Sharon Creech in elementary school. It\'s a clever book that\'s supposed to be the writing journal of a boy named Jack. Each entry, almost all of which is some form of poetry, crafts a heartwarming and (for me) nostalgic story. I also really love its sequel, \"Hate That Cat.\""
    ),
    person(
      "Ellie Suhm",
      "Eisenhower High School",
      "sophomore",
      "second",
      "Hi! I\'m 15 years old, a self-diagnosed coffee addict, love 2000s sitcoms and shows, and never fail to follow a strict layout for thank you cards. I\'m so thrilled to be able to continue sharing in Unleashed my thoughts on current events and what keeps me interested throughout my teenage years. I\'ve grown so much as a person and a writer and I\'m eternally grateful that I can continue on this journey!",
      "My favorite from childhood would easily be \"The Magic Finger\" by Roald Dahl. It was one of the first chapter books that I independently read, and I finished it so quickly! To this day, I credit my passion for reading and literary abilities to that book."
    ),
    person(
      "Carson VanEaton",
      "East Valley High School",
      "junior",
      "second",
      "I am an avid video game fan. I am part of my school\'s esports team, and I also love producing and editing videos. When I graduate I want to attend a college for a degree in video production.",
      "My favorite childhood book is a Disney Cars book called \"Tour the Town.\" What made this book interesting to me is that it had different buttons attached to the bottom of the book that you can press that made sounds. While reading the book, you would press the sound pictured on that specific page that goes along with the story. It also had a plastic steering wheel with a car horn button in the middle, making it not only fun to read but to play with, as well."
    ),
    person(
      "Annaka Yockey",
      "Eisenhower High School",
      "sophomore",
      "first",
      "Hey, everyone! As a journalist, I have experience being a writer on my school\'s paper, The Five Star Journal. When I have downtime I love to crochet; I\'ve made tote bags, clothing pieces and blankets. My other sources of downtime consist of artistic pursuits like embroidery and playing the guitar. My sport is fast-pitch softball, so you can see me right now (in the off-season) working on it.",
      "As a kid my favorite books were the \"Big Nate\" comic strip books because they\'re extremely laughable and have a good foundation of interesting characters."
    )
  ]
];
