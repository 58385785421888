import { Article } from "../../articleInterfaces";
import { content } from "./content";

import eggs from "./easter.jpg";

const article: Article = {
  title: "The Cadbury caramel egg: A foil-wrapped necessity of any complete egg hunt",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  articleType: "column",
  date: new Date(2022, 3, 17),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/the-cadbury-caramel-egg-a-foil-wrapped-necessity-of-any-complete-egg-hunt/article_0150c055-d594-5930-8ccb-570474f503de.html",
  images: [
    {
      caption: "An assortment of the springtime season's confectionery finest.",
      src: eggs,
      credit: {
        name: "Anabelle Kollman",
        school: "Eisenhower High School"
      }
    }
  ],
  content,
  award: "Best Food Story"
}

export default article;