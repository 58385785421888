import { Fragment } from "react";
import { Author } from "../articleInterfaces";
import { useAppSelector } from "../../store";

import style from "../../shared/style.module.css";

interface AuthorProps extends Author {
  format?: "long";
}

function AuthorSchool(a: AuthorProps) {
  const isMobile = useAppSelector((state) => state.responsive.isMobile);

  //If this is on mobile, don't wrap the school
  if (a.format === "long") {
    return (
      <>
        {" "}&#x2014;{" "}
        <span className={isMobile ? style.nowrap : ""}>{a.school}</span>
      </>
    );
  } else {
    return null;
  }
}

function UnleashedAuthor(a: AuthorProps) {
  const isMobile = useAppSelector((state) => state.responsive.isMobile);

  const title = `${a.name} \u2014 ${a.school}${
    !!a.grade ? ` \u2014 ${a.grade}` : ""
  }`;

  //If on desktop, don't wrap anything
  return (
    <span rel="author" title={title} className={isMobile ? "" : style.nowrap}>
      {a.name}
      <AuthorSchool {...a} />
    </span>
  );
}

export function DisplayManyAuthors(props: {
  authors: Author[];
  format?: "long";
}) {
  if (props.authors.length < 5) {
    return (
      <>
        {props.authors.map((a, i) => (
          <Fragment key={a.name}>
            <UnleashedAuthor {...a} format={props.format} />
            {i === props.authors.length - 1 ? "" : " & "}
          </Fragment>
        ))}
      </>
    );
  } else {
    return (
      <>
        <UnleashedAuthor
          name="Magnus Fulton"
          school="West Valley High School"
        />{" "}
        & other Unleashed staff
      </>
    );
  }
}
