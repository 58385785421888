import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "How are area high school students readjusting after the lifting of the masks mandate?",
  author: [
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    },
    {
      name: "Kate Bethel",
      school: "Riverside Christian School",
      grade: "senior"
    }
  ],
  articleType: "news",
  date: new Date(2022, 2, 27),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/how-are-area-high-school-students-readjusting-after-the-lifting-of-the-masks-mandate/article_f4110cf1-592b-55b3-9b64-ce8d0eaeac91.html",
  content,
  topStory: true
}

export default article;