import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";

import nolan from "./nolan-1.jpg";
import nolan2 from "./nolan-2.jpg";
import nolan3 from "./nolan-3.jpg";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School"
};

const article: Article = {
  title: "Climbers find new challenges at High Steppe Climbing Gym in Yakima",
  articleType: "news",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  date: new Date(2021, 0, 24),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/climbers-find-new-challenges-at-high-steppe-climbing-gym-in-yakima/article_502ce6a4-efa9-5aec-b576-0443f54c244f.html",
  images: [
    {
      src: nolan,
      caption:
        "West Valley High School freshman Nolan Preacher boulders at High Steppe Climbing Gym in Yakima, Wash., on Sept. 26, 2020.",
      credit: ME
    },
    {
      src: nolan2,
      caption: "West Valley High School freshman Nolan Preacher boulders on an overhang at High Steppe Climbing Gym in Yakima, Wash., on Sept. 26, 2020.",
      credit: ME
    },
    {
      src: nolan3,
      caption: "West Valley High School freshman Nolan Preacher boulders on an overhang at High Steppe Climbing Gym in Yakima, Wash., on Sept. 26, 2020.",
      credit: ME
    }
  ],
  content,
  topStory: true,
  authorsChoice: true
};

export default article;