import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    "During World War II, the challenge was not just getting messages to the front lines. It was doing so quickly and secretly.",
    "So the U.S. turned to the Navajo Code Talkers.",
    "Researcher Zonnie Gorman, the daughter of Carl Gorman, one of the original 29 recruits, spoke about the Code Talkers during an appearance at the Yakima Town Hall series March 20 at The Capitol Theatre.",
    "The Code Talkers had their origin with a man named Philip Johnston. He was the son of a white missionary on the Navajo reservation and had grown up playing with Navajo children. He is credited with bringing the idea of using Navajo and code talkers to the Marine Corps, although other branches, like the Army, had used native people in both world wars.",
    "In his discussions with the Marine Corps, Johnston presented the idea to Maj. Gen. Clayton B. Vogel during a demonstration with four or five Navajos who were living in Los Angeles at the time. The demonstration was simple: Messages were coded by substituting Navajo words.",
    "Vogel was impressed and wrote a two-page memorandum to the commandant of the Marine Corps, Lt. Gen. Alexander A. Vandegrift, that recommended the Marine Corps take a closer look at using the Navajo language in the conflict.",
    "Orders were given to recruit 30 Navajos for a pilot group. Recruiters targeted boarding schools and Navajos already working for the federal government. However, only 29 men showed up.",
    "\"Among code talkers, there was this joke of \'Oh, I was supposed to be the 30th man, but I wanted to finish the football season,\'\" Gorman recounted to the Yakima audience at Town Hall. \"There were these jokes, these excuses, but no one knew for sure who the 30th man was.\"",
    "That is, until she found the missing man\'s name while doing research in the National Archives in St. Louis. Crossed out on a two-page memorandum, the 30th man was found: George Clinton. According to his record, he was around 20 years old when the Code Talkers formed in 1942 and likely had some medical issues. A trained medical technician, he was eventually wounded in a battle in Normandy before being killed in action in the Battle of the Bulge.",
  ],
  [
    {
      format: "section",
      content: "The Navajo code",
    },
    "With the men recruited, attention turned to developing the code itself.",
    "\"These men talked about it because there were concerns about using their language,\" Gorman said in her Town Hall speech. \"Because to us, our language was sacred. There's a whole lot of deeper meaning. Everything in our culture is interconnected and intertwined and you can't really separate things.\"",
    "However, Navajo was appealing for military use because of its complexity and uniqueness.",
    "Navajo is polysympathetic language. This means that words are built up from smaller pieces, which when used together can hold the equivalent meaning of an entire sentence or two.",
    "Gorman used the word \"Nishch\'id\" as an example during her speech. It can be broken down into \"Ni-\" (which means \"you\"), \"-sh-\" (which means \"I\") and \"ch\'id,\" which means \"to scratch.\" Put together, the meaning is: \"I am scratching you.\"",
    "The code famously substitutes common words for other words in the Navajo language. In the code, observation planes were associated with owls and fighter planes with hummingbirds.",
    "The code also uses a phonetic alphabet to spell out words that don\'t have a substitution. \"A\" became \"wollachee,\" the Navajo word for \"ant.\" \"B\" became \"shush,\" the word for \"bear.\"",
    "The third technique was what Gorman called \"compounding.\"",
    "\"What they did is they took that alphabet and plain, spoken Navajo and they pushed the words together,\" Gorman said in her speech. \"You will not find these words if you look for them in the code because they were colloquial terms they created that never got into the formal code. This particular technique was very popular.\"",
    "One example she gave of this was with the word \"secured.\" Some code talkers split the word up into \"s,\" \"e,\" and \"cured.\" \"S\" became \"sheep,\" \"e\" became \"eye,\" and \"cured\" was translated as is.",
    "So, the word \"secured\" would be coded as \"sheep eye cured\" in Navajo.",
    "Together, these techniques and the language itself made the code unbreakable.",
  ],
  [
    {
      format: "section",
      content: "The irony of the code",
    },
    "However, there was some irony to using the Navajo language. The Code Talkers were born during the federal government\'s assimilation policy for  Native people.",
    "\"They went to federal boarding schools where they were told not to speak their languages,\" Gorman explained at Town Hall. \"And then they go to war and that same government is going, \'Hey! Can you use your language to help win this war?'\"",
  ],
  [
    {
      format: "section",
      content: "Preserving their history",
    },
    "Gorman pointed out that there was only one photograph of the Code Talkers taken by a family member. The other photos were taken by a photographer who was hired by the Bureau of Indian Affairs.",
    "\"The whole idea of the Bureau of Indian Affairs was to eventually assimilate us into American society,\" Gorman said during her speech. \"So, photographs were often the way to show that this progress was happening.\"",
    "These photographs also had another impact.",
    "\"When a person dies, traditionally their materials are burned,\" Gorman noted during a press conference prior to her main speech. \"Now I will have grandchildren, great-grandchildren come up to me and ask, \'Can you find a picture of my grandpa?\' And luckily, the Marine Corps took pictures.",
    "\"So, in their file, it looks like a mugshot, but there is a photograph, and sometimes that's the only photograph that some family members have of their Code Talker.\"",
  ]
];