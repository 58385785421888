import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "I wasn\'t sure what to expect when I walked into Climate Pledge Arena, the sporting and music venue at Seattle Center that opened in October after a major renovation. I\'d heard a lot about the new arena, but I had only seen photos online. Now, I was about to experience it first-hand at an Imagine Dragons concert.",
  "Climate Pledge Arena is a stadium dedicated to sustainability. The arena is net zero carbon, using no fossil fuels for things like the venue\'s mechanical systems, heating and cooking. The stadium also boasts a solar array and buys carbon credits to offset the fossil fuels burned for things like transportation. It also collects rainwater to use for its hockey rink and plans to eliminate single-use plastics by 2024.",
  "Walking through the front doors on March 7, Climate Pledge almost felt like an airport. After passing a towering facade of arches and windows, we joined a crush of a thousand others in one of many slow-moving lines. But, after putting our valuables in a tray, scanning our tickets and walking through a metal detector, we were inside.",
  "The walls of the main level danced with videos of rivers and tropical rain forests, painted not as murals but rather with large floor-to-ceiling LED displays. The effect was mesmerizing. Farther down the concourse, the walls were replaced once again, giving way to plastic greenery. Little portholes had been cut through the greenery to reveal more LED signs that showed more videos of nature and also explained what Climate Pledge was.",
  "The seating at Climate Pledge is different from other stadiums I had been to. The arena felt very tall versus the shallower incline of other stadiums. This made it easier to see over the person in front of you.",
  "That night, MØ opened for Imagine Dragons. She played songs like \"Blur,\" \"Lean On\" and \"Final Song.\"",
  "During the main show, Imagine Dragons played hits such as \"Believer,\" \"Thunder,\" \"Whatever It Takes,\" \"Demons\" and \"Radioactive.\" The group also played songs from its new album, \"Mercury — Act 1,\" such as \"My Life,\" \"Wrecked,\" \"Lonely\" and \"One Day.\" It also covered \"Three Little Birds\" by Bob Marley and the Wailers.",
  "What made this concert so special was how much energy both bands brought to the stage. Karen Marie Aagaard Ørsted Andersen, the lead singer for MØ, was dancing and jumping across the stage during her entire performance.",
  "Normally, that kind of energy is hard to beat. Still, Dan Reynolds, the lead singer for Imagine Dragons, found a way. He could often be seen genuinely smiling and giving crowd members high-fives. He was clearly enjoying himself, which made the performance even more enjoyable for everyone watching.",
];
