import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Discord is not your average instant messaging app.",
  "Even though the app has been out since May of 2015, I first found out about Discord last August as a way to keep in touch with friends during quarantine.",
  "I was immediately impressed by the app's sleek and intuitive interface and how it structured group chats differently than other messaging services. Discord allows its users to create \"servers,\" or dedicated spaces where they can talk. Unlike a traditional group chat, these servers can be further divided into \"channels\" to help organize the conversation by topic. Each channel functions similarly to a traditional text message chain in that users can send images, videos, and text.",
  "But my favorite feature is that, unlike a conventional group chat, Discord allows users to retroactively edit and delete their messages - no more embarrassing typos! Users can even format their messages with bold, italicized, and underlined text! The app also allows users to hide parts of their messages as a spoiler, with the recipient having to tap on the message to read the hidden text.",
  "Discord also acknowledges the fact that you can never have enough emojis - users can even upload their own pictures to be used as the tiny, brightly colored icons we know and love.",
  "Still, there is one more trait that puts this app above the rest:",
  "The app is free with no ads. However, it does also offer an optional bundle of premium features like higher upload limits and additional chat features, like animated custom emoji, as a subscription. One of these subscription options, Nitro Classic, which has the aforementioned benefits, can be purchased for $4.99 per month. The other, called Nitro, costs $9.99 per month and includes the same benefits as Nitro Classic, plus it allows subscribers to share some of their benefits with a server of their choice.",
  "Ready to try for yourself? Discord can be downloaded on most mobile and desktop devices and can even be accessed through a web browser!",
]