import { Article } from "../../articleInterfaces";
import { content } from "./content";

import barbenheimer from "./barbenheimer.jpg";

const article: Article = {
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "incoming senior"
    }
  ],
  date: new Date(2023, 6, 30),
  title:
    "Commentary: 'Barbenheimer' a surprisingly sensational cinematic experience (in pink and grays)",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/commentary-barbenheimer-a-surprisingly-sensational-cinematic-experience-in-pink-and-grays/article_80e644a4-54c3-5df0-ae66-3aacc728f2e6.html",
  content,
  images: [
    {
      caption:
        'Advertisements for the films "Oppenheimer" and "Barbie" appear at AMC Theaters at The Grove on July 20 in Los Angeles.',
      credit: "Chris Pizzello / Associated Press",
      src: barbenheimer
    }
  ]
};

export default article;
