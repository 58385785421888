import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "A concise guide to personal time management",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    }
  ],
  date: new Date(2021, 11, 26),
  articleType: "column",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/a-concise-guide-to-personal-time-management/article_2a2e52c8-b805-5da9-9be8-16e346720077.html",
  content
};

export default article;