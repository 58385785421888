import { Article } from "../../articleInterfaces";
import { content } from "./content";

import self from "./this.jpg";

const article: Article = {
  title: "Magnus Fulton — West Valley High School",
  content,
  date: new Date(2020, 8, 27),
  articleType: "staff",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/staff/magnus-fulton---west-valley-high-school/article_6a32f5dc-72ec-56c7-a97c-1fb8f7e225ec.html",
  images: [
    {
      src: self,
      caption: "West Valley High School\'s Magnus Fulton.",
      credit: "Courtesy / Erin Fulton"
    } 
  ]
}

export default article;