import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "The tiny computers we carry in our back pockets always seem to have another trick up their protective cases. While mastering it all would likely require the dedication and knowledge of a PhD, there are few features you might not know about that can save you some hassle.",
  {
    format: "section",
    content: "Camera"
  },
  "Most of us do not think twice when we click that round shutter button, but sometimes even the simple camera app causes problems.",
  "Most phone cameras seem like magic these days. You point your magic rectangle at something, it automagically finds the perfect settings, and whatever you are looking at appears on screen. Sometimes the part of the scene you want captured is blurry or covered in shadow. The solution is simple enough: You just tap that part of the screen. And voila! Problem solved.",
  "Well, sometimes.",
  "For the power users among us, sometimes our phones will stop focusing on what we want and go back to putting the camera\'s focus on whatever it was on before.",
  "On iOS, this is a simple problem to solve. Rather than tapping where you want the camera to focus, instead do a press and hold. The phone will once again focus how you want it to and, a moment later, a banner declaring \"AE/AF Lock\" will appear. The camera\'s automatic exposure and automatic focus features are now locked and will not change until you tap to put the focus somewhere else, making it easier to snag that perfect photo without risking everything blurring again.",
  "On Android-based devices, the procedure is almost the same, but with a catch. When activating AE/AF Lock mode, instead of placing the focus where you press and hold, the camera app will shift the focus continuously. While this gives you greater control over where the focus is, the new challenge becomes releasing your finger at the exact moment when your target is in focus.",
  "While getting the perfect focus for your next picture might not be an everyday struggle, our camera apps still have a few more tricks you might not be too familiar with:",
  {
    format: "unordered-list",
    content: [
      "The first is QR code scanning. Many iOS and Android-based devices can scan and open QR codes for you, without the use of a third-party app. Simply point your phone of choice with the built-in camera app open at the QR code, wait a moment, and a prompt asking you if you want to open the QR code will appear.",
      "And last but not least, sometimes you can even use your camera to check your remote control\'s batteries. Many phone cameras can detect a little bit of the infrared part of the electromagnetic spectrum (the light that is invisible to the human eye). Some devices, like TV remotes, use this kind of light to communicate. So next time your remote control does not seem to be working, point your camera at it, push a few buttons, and see if there are any faint purple flashes coming out the front."
    ]
  }
]