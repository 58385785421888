import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "I do not cry very often, but \"Guardians of the Galaxy Vol. 3\" made me well up again and again through writer-director James Gunn\'s masterful storytelling.",
  "The previous two films in the series, as well as our Guardians\' cameos in other projects, established a deep well of history and characterization. Over the years, fans have watched our misfit band of antiheroes grow, change, bicker and overcome. For the finale of the trilogy, it is clear that Gunn was ready to draw from this well in spectacular fashion.",
  "Part of what attracted me to this film is that it calls back to what made Marvel movies great to begin with. Some of the more recent MCU films oftentimes felt too big, with characters who have been too perfect and problems that were too grand. While fun to watch, those characters and events often felt too outside of our own experiences to have that deeper connection that made other Marvel movies great.",
  "However, the Guardians movies have always been about staking out a unique corner of the MCU for themselves, much like the misfit band of heroes that gives the series its name. After a string of disappointments with those other MCU films, \"GOTG3\" reminds me why this franchise is my favorite.",
  "\"GOTG3\" also pushes just how wacky sci-fi can get. It features brightly colored spacesuits, a telekinetic dog, planets with animal-human hybrids, and databases that are just a little too alive for comfort.",
  "The soundtrack of the movie is another highlight. \"Awesome Mix Vol. 3\" features songs from Radiohead, The Flaming Lips, Beastie Boys, and Florence + the Machine. Renowned for his use of well-known songs in his works to full effect, Gunn\'s selection for the trilogy\'s finale really fits the soul of the film. \"GOTG3\" is written with a heavier feeling to it and the soundtrack underscores it nicely. The score for the film is also nicely done, giving the scenes an accent without being too overbearing.",
  "However, \"GOTG3\" is just as much about where the Guardians are physically going as it is about the emotional journeys. It\'s clear that the facade that each character has put up — hiding past trauma and hurt behind a wall — is cracking and the internal struggles are being laid bare.",
  "\"GOTG3\" features one scene with Rocket Raccoon that is one of the most raw and powerful moments for a character that I have ever seen in a movie. I was taken aback by just how genuine Bradley Cooper was in voicing the character and how sincerely he cries out.",
  "It helps that, cinematically, the shot is done perfectly. In a movie of mostly smooth transitions and stable frames, the camera suddenly switches to a handheld, giving everything a shaky and off-kilter feeling that truly drives home the horror and shock of the scene.",
  "The raw emotions are a breath of fresh air for Marvel, where tragic losses are the unfortunate norm. Still, other emotional scenes feel a little heavy-handed at times in this movie. Several of the scenes feature animal abuse in stark relief, the horror of which at times feels as though Gunn himself is shouting in your ear.",
  "Other scenes use this heavy-handedness to their advantage as our Guardians have to question just how far they are willing to go. The film also brings some of the shock and horror of death back into a genre that tends to numb audience members to it.",
  "Of course, it would not be a Marvel movie without a villain. Gunn weaves a story here out of two antagonists whose interests sometimes align and sometimes oppose, all without overshadowing the Guardians. The primary antagonist is the High Evolutionary, whose stated goal is to create a utopia of perfect beings, no matter how many he must kill and torture to get there. The Sovereign from the second Guardians movie also get screen time, seeking to eliminate the Guardians as retribution for Rocket having stolen some batteries in the earlier film.",
  "The first two Guardians movies were some of my favorites in the MCU. And, it is clear that Gunn was determined to make the trilogy\'s finale a fitting sendoff to both our Guardians and his career at Marvel before shifting his focus to the DC film universe at Warner Bros.",
  "Gunn had a story to tell with \"Guardians of the Galaxy Vol 3,\" and the result is a powerful sendoff to my favorite trilogy in the Marvel Cinematic Universe.",
  {
    format: "unordered-list",
    content: "\"GOTG3\" has a runtime of 2 1/2 hours, and is rated PG-13 for intense sequences of violence (including animal abuse) and action, as well as strong language."
  }
]