import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "./App";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { HelmetProvider } from "react-helmet-async";

function Root() {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  );
}

if (process.env.NODE_ENV === "development") {
  const root = createRoot(document.getElementById("root") as HTMLElement);
  root.render(<Root />);
} else {
  hydrateRoot(document.getElementById("root") as HTMLElement, <Root />);
}
