import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Deciding which restaurant to eat at is hard. To help narrow the field of already outstanding contenders, I decided to consult the experts on the topic: some of my fellow teenagers at West Valley High School.",
  "For freshman Owen Glenn, it is hard to beat Cowiche Canyon Kitchen. When he\'s there, Glenn typically orders the Hawaiian chicken sandwich. For him, the sandwich\'s crispiness is what really sets it apart.",
  "Glenn feels that in addition to having great food, Cowiche Canyon Kitchen offers outstanding staffing and service. To try a Hawaiian chicken sandwich for yourself, you can visit the restaurant at 202 E. Yakima Ave. in Yakima. The restaurant offers indoor and outdoor dining.",
  "Another freshman, Areli Escamilla, took things in a different direction. She enjoys Joy Garden\'s beef pho, which she says has a great flavor. She also loves that the restaurant gives customers a large helping of the star ingredient, beef. To try some of that beef pho for yourself, you can find Joy Garden at 5606 Tieton Drive in Yakima.",
  "But for freshman Eric Eichler, Olive Garden is the place to be. One dish in particular stands out for Eichler: \"The seafood alfredo is good,\" he said. \"It has shrimp and scallops, everything!\"",
  "Whether shrimp and scallops are your thing, or if what you desire are the bottomless breadsticks, you can find Olive Garden at 222 E.Yakima Ave. in Yakima for both indoor dining and curbside delivery.",
  "Cole Cleaver, another ninth grader, loves MOD Pizza. He typically customizes a barbecue pizza, and says he appreciates the speed and amazing tastes the restaurant offers. To get your own slice of Cleaver\'s recommended paradise, check out MOD Pizza at either of its two Yakima locations: 2550 W. Nob Hill Blvd., Suite 100, or 1905 S. First St., Unit 100.",
  "Sophomore Maxwell Fleming praises Five Guys. He described the restaurant\'s offerings as \"messy in a good way\" because \"they don\'t go skimpy on anything. You get tons of everything,\" pointing to the restaurant\'s generous portions of fries as one example. Fleming loves to order the double bacon cheeseburger and pairs it with a peanut butter milkshake, which he describes as \"amazing.\" If you want to try that pairing for yourself, Five Guys offers indoor dining and can be found at 1901 S. First St. in Yakima.",
  "Another freshman, Elijah Krueger, has similar tastes. He loves to order the garden burger at Miner\'s Drive-In. He has a hard time trying to pin down exactly what makes it so awesome. He adds that the restaurant\'s service is also really good. Miner\'s offers indoor dining and is at 2415 S. First St. in Yakima.",
  "Last but not least, freshman Lauren Farr recommends Tea Garden, where the restaurant\'s orange chicken earns high praise. She also loves the restaurant\'s tofu. You can find Tea Garden at 110 S. Fourth St. in Yakima. The restaurant offers indoor dining.",
  "Bon appétit!"
]