import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";

import dec2 from "../resources/dec-2-23.pdf";
import payne from "./payne.jpg";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "senior"
};

const article: Article = {
  title: "At 14 years old, WVHS student enjoys the adrenaline of drag racing",
  articleType: "news",
  author: [ME],
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/at-14-years-old-wvhs-student-enjoys-the-adrenaline-of-drag-racing/article_03d34185-b1a6-5150-a3f5-4e2b7c1c21a3.html",
  clipping: dec2,
  date: new Date(2023, 11, 3),
  editorsPick: true,
  content,
  images: [
    {
      caption: "West Valley freshman Samantha Payne has been racing for two years. On top of her racing Subaru, she has her third place Summit series trophy (left) and first place Gamblers series trophy (right).",
      src: payne,
      credit: ME
    }
  ],
  authorsChoice: true
}

export default article;