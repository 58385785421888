import { Author, Grade, JSONDSL } from "../../articleInterfaces";

interface AboutMe extends Author {
  grade: Grade;
  yearInUnleashed: string;
  favoriteAlbum: string;
  aboutMe: string[] | string;
}

const aboutMe: AboutMe[] = [
  {
    name: "Abi Longbottom",
    school: "Naches Valley High School",
    grade: "junior",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum:
      "\"Midnight Memories\" by One Direction. No further explanation needed.",
    aboutMe:
      "My name is Abigail but everyone calls me Abi — unless you are my mother. I\'m just a German-born Canadian-American who happens to be vegetarian with octagonal glasses. My hobbies consist of paddleboarding, working and daydreaming."
  },
  {
    name: "Anabelle Kollman",
    school: "Eisenhower High School",
    grade: "junior",
    yearInUnleashed: "Third year in Unleashed",
    favoriteAlbum:
      "\"Typical of Me\" by Laufey has been my favorite album this summer. I started following the artist on Instagram when we were in the midst of the pandemic-induced lockdown. At the time she only had two singles released. This is her first album. I enjoy her music because it reminds me of book shops and cafes, two places I really missed during that time.",
    aboutMe: [
      "Anne Shirley, my parents, Laura Ingalls Wilder, my sister. Some of these people are real, some fictional, but I grew up with all of them and they are the reason I love reading and writing, which is the reason I am part of Unleashed. My parents read to me a lot growing up, which led to me meeting Anne and Laura, both characters in my favorite childhood book series, \"Anne of Green Gables\" and \"Little House on the Prairie.\" We grew up a 5-minute walk to the library, so my sister and I had lots of reading material. Both Anne and Laura are also writers, so it wasn\'t long until I started mimicking them and writing, as well.",
      "Another love instilled in me as early and deeply as reading is a love of nature. My family and I are outside all year round, whether we\'re hiking, camping, swimming, backpacking or skiing. Now that I\'m in high school, my interests have grown to include baking, tennis, community service, thrifting and photography."
    ]
  },
  {
    name: "Andrew Cabbage",
    school: "West Valley High School",
    grade: "sophomore",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum: "\"Carrie & Lowell\" by Sufjan Stevens.",
    aboutMe: [
      "Hi! I love making art, biking around town, and hanging out with my friends. This is my first year writing for Unleashed, but I\'ve been writing for a long time. I work as a lifeguard for the parks department and have been programming since I\'ve been able to write.",
      "One thing that\'s really important to me is nature, especially hiking. Current events have never been more interesting, so I can\'t wait to start writing this year for Unleashed."
    ]
  },
  {
    name: "Gracie Miller",
    school: "Eisenhower High School",
    grade: "senior",
    yearInUnleashed: "Third year in Unleashed",
    favoriteAlbum: "\"Lover\" by Taylor Swift.",
    aboutMe: [
      "I can\'t believe this is my last year on the team. I joined this group my sophomore year and filling out that application was one of the best decisions I\'ve ever made. The atmosphere at meetings is so comfortable, like we\'ve known each other for ages when for the most part we only see each other twice a month.",
      "I love writing. (I mean, I\'d probably be making a mistake by being here if I didn\'t.) I like movies and books; after I read a good book or watch a good movie, the need to talk about how good they are builds up inside me until I can find someone who will listen to explode it all onto. I like drawing, and I hope to bring more of my art into Unleashed this year. I like doing things with my friends such as getting frozen yogurt, standing up front in the student section at football games, and thrift shopping. I\'m very excited for my final year on this team and I can\'t wait to see what opportunities come my way."
    ]
  },
  {
    name: "Indiana Hilmes",
    school: "Selah High School",
    grade: "sophomore",
    yearInUnleashed: "Second year in Unleashed",
    favoriteAlbum:
      "A tie between Olivia Rodrigo\'s \"Sour\" and Stray Kids\' \"Noeasy.\" Rodrigo\'s voice perfectly captures teenage angst. And the tracks on \"Noeasy\" range from emotional ballads to high-energy tunes with speedy rhythms and sharp lyrics.",
    aboutMe:
      "Hey there! I\'m absolutely thrilled to be able to keep writing for the Herald-Republic. Last year was such a special and crazy experience. You never really know how cool it is to be in a room with a bunch of word nerds until you join Unleashed. In my free time I really enjoy reading, writing poetry, and listening to music. I\'m also a member of Selah\'s girls cross country team and I dance ballet for a good portion of my week at Footlites Dance Studio in Selah. My favorite subjects in school are history and English, and I have a goldendoodle named Tillie who might actually be the most spoiled dog in the world. I look forward to sharing more stories with you. Thanks for tuning in to my adventures so far!"
  },
  {
    name: "Jillian Strother",
    school: "Riverside Christian School",
    grade: "sophomore",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum: "\"Red\" by Taylor Swift.",
    aboutMe:
      "Writing is a great outlet for my creativity, and I am very excited to be a correspondent for Unleashed this year! I enjoy reading and writing, from informative and narrative to creative writing, poetry, and songwriting. My favorite genres of literature to read include fantasy, dystopian, and contemporary young adult. \"Harry Potter\" by J.K. Rowling and \"Percy Jackson\" by Rick Riordan are some of my favorite series. I\'ve taken piano lessons for almost 10 years and love playing the guitar and ukulele, and singing, too."
  },
  {
    name: "J.J. Montelongo",
    school: "Sunnyside High School",
    grade: "senior",
    yearInUnleashed: "First year of Unleashed",
    favoriteAlbum:
      "\"Take Care\" by Drake. \"Take Care\" contains every emotion from overflowing excitement and hype to deep sadness. \"Take Care\" also has a very impressive feature list.",
    aboutMe:
      "I am the current senior class president at Sunnyside High School and enjoy competing in multiple sports. My hobbies are spending time with family, volunteer work, and watching college football on Saturdays and NFL on Sundays. I root for the Seattle Seahawks and Mariners and am a die-hard Lakers fan. Go Hawks! Go Dawgs!"
  },
  {
    name: "Kate Bethel",
    school: "Riverside Christian School",
    grade: "senior",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum:
      "Jack Johnson\'s \"Sing-A-Longs and Lullabies for the Film Curious George.\"",
    aboutMe:
      "I am a very creative and, occasionally, an outgoing individual. I\'ve had a passion as long as I can remember for making art, and I hope to study art and psychology at college next year. I\'m also a big fan of anything Marvel, so naturally I\'m very excited about all of the new movies being added to the MCU in the next couple of years. If you catch me in my free time, I\'m likely to be reading, drawing, chatting with friends, or completing a variety of logic puzzles. Finally, one fun fact about me is that I learned how to make balloon animals a few years ago and I\'ve gotten pretty good at it since! I can\'t wait to have my writing and artwork published in the paper this year and I\'m excited to see what the future holds for me."
  },
  {
    name: "Lexi Barbee",
    school: "West Valley High School",
    grade: "junior",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum:
      "My favorite music album is way too hard to choose, so I\'ll mention my favorite artist, which is Hillsong United. I love the group\'s music because it combines worship with pop music and the message is always so good!",
    aboutMe:
      "I love mint chocolate chip ice cream and vanilla shakes. I made varsity my freshman year for volleyball. However, my favorite sport to watch is hockey. I want to go to a four-year university and major in psychology or children\'s ministry. My favorite Bible verse is Isaiah 40:31."
  },
  {
    name: "Lucas Cone",
    school: "West Valley High School",
    grade: "senior",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum: "\"A Rush of Blood to the Head\" by Coldplay.",
    aboutMe:
      "I joined Unleashed because of my passion for writing, but I also have a few other passions. I love to act, and I hope to pursue acting as a career when I\'m older. You can see me in the West Valley Drama Club\'s production of \"Everything I Needed to Know I Learned in Kindergarten\" on Nov. 5-7. I\'m a huge Seahawks fan, and I enjoy playing fantasy football with my friends. I also love to travel. This past summer I went on my school\'s German American Partnership Program trip to Germany, which was an incredible experience. I\'m a big Star Wars and Marvel fan; really, just a fan of movies in general. I also love to play basketball, and I\'m a member of my school\'s Associated Student Body leadership. Well, I think that covers most of my bases. I\'m excited to start writing for Unleashed!"
  },
  {
    name: "Macie Ladd",
    school: "Selah High School",
    grade: "sophomore",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum:
      "\"Positions\" by Ariana Grande. I love the lighthearted theme of this album.",
    aboutMe:
      "Outside of school, I compete in tennis and ski the slopes of White Pass. I seek adventure along with my family and friends by paddling kayaks, backpacking, and camping in the scenic outdoors. I aspire to travel the world and experience new places, languages, and cultures. I enjoy reading and writing so I\'m super excited to write for Unleashed this year!"
  },
  {
    name: "Magnus Fulton",
    school: "West Valley High School",
    grade: "sophomore",
    yearInUnleashed: "Second year in Unleashed",
    favoriteAlbum:
      "\"My Head is an Animal\" by Of Monsters and Men. I couldn\'t believe how many of the songs I recognized the first time I heard this album. \"Mountain Sound,\" \"Little Talks,\" \"Dirty Paws,\" and \"King and Lionheart\" leaped out at me from the start. Those songs stuck out to me for the same reason I like a lot of the work by Of Monsters and Men. The group has a unique and instantly recognizable style and beat, paired with lyrics that carry a deeper meaning.",
    aboutMe:
      "I\'ve always loved going outside, which makes sense because I\'ve been going on hikes, runs, and bike rides with my family since I was very little. Following that passion, I didn\'t need to break a sweat to know I wanted to run for West Valley\'s cross country team this fall. But that\'s not the only passion I\'ve followed this summer. I\'m an avid sci-fi reader. Some of my more recent favorites include Michael Crichton\'s \"Jurassic Park\" and the all-too relevant \"The Andromeda Strain.\" I\'m sure my love for sci-fi is related to my love for technology and programming. I\'ve always enjoyed being able to turn my ideas into reality using those tools. Regardless, I\'m looking forward to a school year with (hopefully) a little less screen time and a little more face time."
  },
  {
    name: "Maham Khan",
    school: "West Valley High School",
    grade: "freshman",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum:
      "Favorite music: I like instrumental music more because I find it more peaceful.",
    aboutMe:
      "Since my early childhood, I would watch the news with my dad and read the comics section in the newspaper. As I got older, I would read the articles on the front page of the newspaper. While reading those articles, I always thought that a reporter\'s job was so cool! I often had ideas on stories that could go in the newspaper. Earlier this year, I submitted a paper on an important event in history for the National History Day competition and placed at the regional level. This year, as a correspondent with Unleashed, I am excited to bring attention to important events in our local area."
  },
  {
    name: "Maria Jacobson",
    school: "Highland High School",
    grade: "junior",
    yearInUnleashed: "Second year in Unleashed",
    favoriteAlbum:
      "\"Wheels Are Turnin\'\" by REO Speedwagon! I really love REO, and \"Wheels are Turnin\'\" is a great album. My favorite song of all time, \"I Do\' Wanna Know,\" is on the album, too.",
    aboutMe:
      "I\'m very happy to be in Unleashed again this year. I\'ve lived in Cowiche my whole life. My passion is drawing, and I really love seeing the image in my head become a digital illustration. I also like playing video games! Some games I really like are Stardew Valley, Pokémon, and Mario Kart. I live with my three dogs and three cats, my brother and my wonderful mom. I hope to draw a lot more this year!"
  },
  {
    name: "Mary Mickelson",
    school: "Eisenhower High School",
    grade: "junior",
    yearInUnleashed: "Third year in Unleashed",
    favoriteAlbum:
      "\"Evermore\" by Taylor Swift. I love Taylor\'s songwriting on this album, and I love the piano. My favorite song in the album is \"Champagne Problems.\"",
    aboutMe:
      "This is my third year in Unleashed, and I am so excited to be here. Along with being a writer, I watercolor and illustrate, and I absolutely love reading. I swim at Eisenhower and throw on the track and field team, and my favorite books to date this year are \"The Invisible Life of Addie Larue\" by V.E Schwab, and \"The Night Circus\" by Erin Morgenstern."
  },
  {
    name: "Natalie Keller",
    school: "Selah High School",
    grade: "junior",
    yearInUnleashed: "Third year in Unleashed",
    favoriteAlbum:
      "Adele\'s \"21.\" Although I initially listened to it when I was younger, many of the album\'s songs remain among my favorites to this day.",
    aboutMe:
      "At school I take part in DECA, National Honor Society and Link Crew, and am a member of the SHS cheer team. Additionally, I am excited to begin working as a student teacher at FootLites Dance Studio this year, which is where I have taken dance lessons for 11 years in styles such as ballet, pointe and lyrical. Outside of school, I enjoy reading and, of course, writing. My current career aspiration is to become a broadcast journalist."
  },
  {
    name: "Nataly Kosevich",
    school: "West Valley High School",
    grade: "junior",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum: "\"Igor\" by Tyler, the Creator",
    aboutMe:
      "I am an exchange student from Ukraine and am delighted to be a part of Unleashed! I became a finalist of the Future Leaders Exchange (FLEX) program, which is a highly competitive, merit-based scholarship program funded by the U.S. State Department, and thanks to this I came to study in the United States for this academic year at no cost to learn more about the culture of America and represent my country. I am passionate about old things; I collect vinyl, take pictures with film cameras and shoot videos with old camcorders."
  },
  {
    name: "Olivia Gonzalez",
    school: "Davis High School",
    grade: "senior",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum: "\"Swimming\" by Mac Miller.",
    aboutMe: [
      "I am beyond excited to start sharing my writing with the Yakima community. With my new home in the Unleashed family, I have added yet another level of \"busy\" to my full schedule! I am a full International Baccalaureate candidate and am going into my third year of varsity competitive dance at Davis High School. Go Pirates! I work closely with my school\'s news network and am a \"full package\" (no pun intended), as editing, producing, directing, and hosting are all integrated in my title on the Davis News Network, where I create my own segment called \"Livin\' With Liv.\" In any spare time I can find in my crazy schedule, I spend most of it with my family and friends, or binge watching \"Gilmore Girls\" on Netflix.",
      "As my senior year starts flying by, I choose to savor every second of it and have caught myself taking mental snapshots of some of my favorite moments; whether it be a beach sunrise with my best friends, my dog sleeping on the corner of my bed, a 100% on a math quiz, or my grandparents dancing in the kitchen. These moments will stay with me when I journey into the next chapter of my life. But for now, this chapter is far from over. In fact, this is only my introduction."
    ]
  },
  {
    name: "Olivia Rankin",
    school: "Eisenhower High School",
    grade: "junior",
    yearInUnleashed: "Second year in Unleashed",
    favoriteAlbum:
      "There are so many great albums to choose from but ultimately \"III\" by The Lumineers takes first place as my favorite. I listened to The Lumineers growing up and this is the group\'s best album yet. There isn\'t a single song I\'d skip.",
    aboutMe:
      "A few of my obsessions are: reading during class, daydreaming cheesy stories then writing them, \"Criminal Minds,\" starting debates with anyone who\'s willing, thrifting incessantly, snacking on Goldfish, and creating insanely specific playlists on Spotify. I play softball at Eisenhower, am part of leadership as my class vice president, and am involved in drama. I\'m excited for my junior year and even more excited to be a part of the 2021-22 Unleashed team."
  },
  {
    name: "Ruby Hoeger",
    school: "West Valley High School",
    grade: "junior",
    yearInUnleashed: "Second year in Unleashed",
    favoriteAlbum: "\"Melophobia\" by Cage the Elephant",
    aboutMe:
      "I love everything to do with music, whether it be listening to my favorite artists, playing piano, viola, guitar and ukulele, singing in my school\'s treble choir, or writing songs. I am involved in ASB as a co-communications director, play on my school\'s golf team, and am involved with YouthWorks. I have always been fascinated with animals and hope to pursue a career in veterinary medicine. I am also an avid baker, and enjoy volunteering, reading and, of course, writing."
  },
  {
    name: "Stella Padilla",
    school: "West Valley High School",
    grade: "junior",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum:
      "My current favorite album is \"Bury Me at Makeout Creek\" by Mitski, but it will probably be different next week.",
    aboutMe:
      "This is my first year doing the Unleashed program, and I couldn\'t be more excited to write things that people will actually read. I am passionate about a lot of things, including poetry, thrifting, reading, plants, and the list goes on. I also love the outdoors and spending time with friends. My current favorite thing is the iced chai with pumpkin cold foam from Starbucks."
  },
  {
    name: "Whit Peters",
    school: "Selah High School",
    grade: "sophomore",
    yearInUnleashed: "First year in Unleashed",
    favoriteAlbum: "\"The Miseducation of Lauryn Hill\" by Lauryn Hill.",
    aboutMe:
      "The best description for my interests would be \"diverse.\" I can be found on a mountain bike trail, at the piano, on the baseball diamond, in the shop, on stage, or behind the camera. I have a passion for trying and perfecting new things and am excited to contribute to Unleashed through digital and written media."
  }
];

function title(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const content: JSONDSL = aboutMe.map<JSONDSL>((m) => {
  let aboutMeInline: string, aboutMeExtra: string[];

  if (Array.isArray(m.aboutMe)) {
    aboutMeInline = m.aboutMe[0];
    aboutMeExtra = m.aboutMe.slice(1);
  } else {
    aboutMeInline = m.aboutMe;
    aboutMeExtra = [];
  }

  return [
    {
      format: "section",
      content: m.name
    },
    {
      format: "italics",
      content: m.school
    },
    {
      format: "italics",
      content: title(m.grade)
    },
    {
      format: "italics",
      content: m.yearInUnleashed
    },
    {
      format: "block-with-inline",
      content: [
        {
          format: "bold",
          content: "Favorite album: "
        },
        m.favoriteAlbum
      ]
    },
    {
      format: "block-with-inline",
      content: [
        {
          format: "bold",
          content: "About me: "
        },
        aboutMeInline
      ]
    },
    aboutMeExtra
  ];
});
