import { Article } from "../../articleInterfaces";

export function Byline(article: Article) {
  if (article.author.length === 1) {
    return (
      <ul>
        <li>{article.author[0].name} is a {article.author[0].grade} at {article.author[0].school}</li>
      </ul>
    )
  } else {
    return null;
  }
}