import { useNavigate } from "react-router-dom";
import { commitTransaction, Renews, TransactionAction } from "../store/common";
import {
  setAction,
  setAmount,
  setDescription,
  setRenews,
  setType
} from "../store/transient";
import { useAppDispatch, useAppSelector } from "../../store";
import { useState } from "react";

import style from "./index.module.scss";
import sharedStyle from "../../shared/style.module.css";

interface Choice<T> {
  value: T;
  label: string;
}
interface MultiSelectProps<T> {
  label: string;
  choices: Choice<T>[];
  onChange: (v: T) => void;
  value: T;
}
function ChoiceSelect<T extends string>(props: MultiSelectProps<T>) {
  return (
    <select
      aria-label={props.label}
      onChange={(e) => props.onChange(e.target.value as T)}
      className={style.choiceSelect}
      value={props.value}
    >
      {props.choices.map((c) => (
        <option value={c.value} key={c.value}>
          {c.label}
        </option>
      ))}
    </select>
  );
}

interface ValueInputProps {
  placeholder: string;
  value: string | number;
  onChange: (v: string) => void;
  label: string;
}
function ValueInput(props: ValueInputProps) {
  return (
    <input
      placeholder={props.placeholder}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      aria-label={props.label}
      className={style.valueInput}
    />
  );
}

type ChargeType = "one-time" | Renews;
const chargeRenewal: Choice<ChargeType>[] = [
  {
    value: "one-time",
    label: "One-Time"
  },
  {
    value: "weekly",
    label: "Weekly"
  },
  {
    value: "monthly",
    label: "Monthly"
  },
  {
    value: "yearly",
    label: "Yearly"
  }
];

const chargeDeposit: Choice<TransactionAction>[] = [
  {
    value: "withdrawl",
    label: "Withdrawl"
  },
  {
    value: "deposit",
    label: "Deposit"
  }
];

const isNum = /^\d+(?:\.\d+)?$/;

export function AddCharge() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const state = useAppSelector((state) => state.transientBudget.writableDraft);
  const isUpdating =
    useAppSelector((state) => state.transientBudget.pointer) !== undefined;
  const isMobile = useAppSelector((state) => state.responsive.isMobile);

  const [localAmount, setLocalAmount] = useState(
    state.amount !== 0 ? String(state.amount) : ""
  );

  const currentChargeType: ChargeType =
    state.type === "transaction" ? "one-time" : state.renews;

  const setChargeType = (v: ChargeType) => {
    switch (v) {
      case "one-time":
        dispatch(setType("transaction"));
        break;

      case "weekly":
        dispatch(setType("subscription"));
        dispatch(setRenews("weekly"));
        break;

      case "monthly":
        dispatch(setType("subscription"));
        dispatch(setRenews("monthly"));
        break;

      case "yearly":
        dispatch(setType("subscription"));
        dispatch(setRenews("yearly"));
        break;
    }
  };

  const commit = () => {
    dispatch(commitTransaction);
    navigate("/budget");
  };

  const amountIsValid = () => {
    const a = parseFloat(localAmount);
    return isNum.test(localAmount) && isFinite(a) && a > 0;
  };

  const setChargeAmount = (v: string) => {
    setLocalAmount(v);
    if (amountIsValid()) {
      dispatch(setAmount(parseFloat(v)));
    }
  };

  return (
    <>
      <h1 className={sharedStyle.center}>
        {isUpdating ? "Update" : "Add"} transaction
      </h1>
      <div className={`${isMobile ? style.mobile : ""} ${style.container}`}>
        <ValueInput
          placeholder="$ 0.00"
          value={localAmount}
          onChange={setChargeAmount}
          label="Transaction amount."
        />
        <ValueInput
          placeholder="Description"
          value={state.description}
          onChange={(v) => dispatch(setDescription(v))}
          label="Description for the transaction."
        />
        <ChoiceSelect
          label="Selects how often this transaction should recur."
          choices={chargeRenewal}
          onChange={setChargeType}
          value={currentChargeType}
        />
        <ChoiceSelect
          label="Selects what is happening with this money."
          choices={chargeDeposit}
          onChange={(a) => dispatch(setAction(a))}
          value={state.action}
        />
        <button
          className={style.commit}
          onClick={commit}
          disabled={!amountIsValid() || !state.description}
        >
          {isUpdating ? "Update" : "Add"}!
        </button>
      </div>
    </>
  );
}
