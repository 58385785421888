import { Helmet } from "react-helmet-async";
import { Outlet, type RouteObject } from "react-router-dom";
import { Interface } from "./Interface";
import { AddCharge } from "./AddCharge";

function MyHelmet() {
  return (
    <Helmet>
      <title>Budget</title>
    </Helmet>
  );
}

export const routes: RouteObject[] = [
  {
    path: "/budget",
    element: (
      <>
        <MyHelmet />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "",
        element: <Interface />
      },
      {
        path: "record",
        element: <AddCharge />
      }
    
    ]
  }
];
