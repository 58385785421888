import { JSONDSL } from "../../articleInterfaces";

function subheading(title: string, content: string, bullets?: string[]): JSONDSL {
  return {
    format: "block-with-inline",
    content: [
      {
        format: "bold",
        content: title
      },
      content,
      bullets !== undefined ? {
        format: "unordered-list",
        content: bullets
      } : []
    ]
  }
}

export const content: JSONDSL = [
  [
    "This year\'s college-bound high school seniors are facing more stress than usual as they figure out how they\'re going to pay for a higher education.",
    "Just like their peers across the country, Yakima Valley students have experienced glitches, delays and errors using the Free Application for Federal Student Aid, or FAFSA. Many, from local counselors and colleges to federal officials and state institutions, have been scrambling to help students navigate the FAFSA process.",
    "FAFSA is a critical part of the college application and acceptance process and needs to be filled out by students and parents. The application is used to determine which students get federal financial aid – thousands of dollars that can be critical for students paying for college.",
    "Congress passed the FAFSA Simplification Act in 2020 and ordered updates to the application. In December 2023, the Department of Education launched a new FAFSA process meant to make the application easier, especially for mixed-status families, where a student with U.S. citizenship or documentation may have a parent who is undocumented or without a social security number.",
    "Many local college counselors praised the work behind the new FAFSA and said it should easier to fill out in the future.",
    //I helped with the College Success Foundation interview
    "\"When the FAFSA application has worked, it\'s worked well. It\'s more streamlined,\" said Amber Goodrich, a regional officer for the College Success Foundation, an organization that helps Yakima Valley youth apply for college.",
    "But Goodrich and many others said the rollout for the new FAFSA has been a bumpy one and actually made the process harder for students from mixed-status families.",
    "The Department of Education has taken steps to correct the problems and clear the backlog of FAFSA issues. In an open letter sent on April 9, Secretary of Education Miguel Cardona said his department has identified and fixed many of the issues.",
    "\"Again, we recognize the hardship the delays have caused. We see your tremendous efforts, and we appreciate your patience. We want to help every student and every college that requests it — and we'll continue to do so,\" Cardona said in the letter.",
    "The Department of Education worked to fix the issues affecting undocumented parents and mixed-status families. Colleges and universities said they\'ve noticed the changes as applications filter in.",
    "Goodrich said there are still challenges, though, particularly for students who need to make updates or changes to their FAFSA.",
    "\"Some students are having an easier time, others are still plagued with issues and workarounds that don\'t work,\" Goodrich said in an email. \"According to the Department of Education, students will finally be able to make corrections to their FAFSA applications sometime next week. After months of delays, I won\'t believe it until I see it.\"",
  ],
  [
    {
      format: "section",
      content: "Concerned counselors"
    },
    "A litany of issues plagued the new FAFSA. An informational page from the Department of Education shows more than a dozen problems that are being tackled, as well as the many issues that federal officials say have been solved.",
    "Elvia Valdovinos, a financial aid specialist for the College Success Foundation, said some of the problems are just glitches in the system. Web pages wouldn't load or people would be redirected incorrectly. But there were also specific issues for students with undocumented family members.",
    "At Wapato High School, guidance specialist Anahi Lopez outlined significant challenges faced by students. A primary concern is the difficulties encountered by students without Social Security numbers.",
    "\"One of the issues has been that students with undocumented parents cannot create an account without the hassle of contacting FAFSA, filling out additional documents. That wasn\'t supposed to happen,\" Lopez said in an interview March 8.",
    "When those parents tried to contact the Department of Education, there were often lengthy delays.",
    "\"The FAFSA glitches and the delays align with the actual process of students determining where they want to go,\" Lopez said. \"Telling students to wait makes some lose hope or not follow back up with me. Compared to last year, I was able to help students finish their FAFSA in a day, but now it can take up to weeks to even get it done.\"",
    "In February, press releases announced increased staffing and funding to help universities and nonprofits tackle FAFSA issues. Officials said that many of the issues for parents without Social Security numbers have been solved.",
    "Goodrich said there are still issues, though. For example, students who need to make changes to their applications have had difficulty accessing the application after they fill it out.",
  ],
  [
    //My part
    {
      format: "section",
      content: "Uncertainty"
    },
    "It's leaving many students stressed and in the dark.",
    "\"I think the biggest thing is the uncertainty. So I have a senior right now and I can log in and see that (the FAFSA web page) says \'in review\' – that causes anxiety,\" said Heather Clark, a counselor at West Valley High School.",
    "In previous years, students already knew their financial aid numbers by this time, which drove decisions about what college to attend.",
    "\"It's kind of backwards now. You're having to take a leap of faith in the school you want to go to, hoping that the FAFSA is going to come through,\" Clark said.",
    "Sometimes, the question is not which school to attend, but whether college is an option at all.",
    "\"Some families know that they want their kid to go to college no matter what. But some families, some kids, have to convince their parents that they can go to college and make it work based off of those numbers that they're receiving,\" said Cory Kingsbury, a counselor at West Valley.",
    "Sarah Rinaldi, another counselor at West Valley, said that even experienced financial aid teams were still in the dark about how to approach some of the new challenges, especially before the Department of Education\'s updates.",
    "Local counselors and organizations are working to bridge the gaps. West Valley is among the schools that has offered financial aid help nights and brought in help from local colleges, Rinaldi said.",
    //End of my part
    "Counselors are feeling the burdens, though, said Wapato High School college adviser Jailene Parroquin. The challenges students face are also stressful for counselors.",
    "\"It has been complicated and frustrating. It got complicated with the account creation, especially for those who don\'t have (Social Security numbers). FAFSA has made us feel like it\'s our fault because we\'re unable to help students advance in certain parts of the application,\" Parroquin said in a March 8 interview.",
  ],
  [
    {
      format: "section",
      content: "Colleges respond"
    },
    "Colleges and universities are also working around the delays. The University of Washington and Washington State University both moved deadlines to help students. Incoming freshmen can wait until June 1 to decide if they\'re attending UW and the university moved its FAFSA priority deadline from January to February. WSU moved its application deadline to May 1.",
    "Local institutions have not had to adjust deadlines – Central Washington and Heritage universities both have rolling admissions deadlines that are more flexible. Yakima Valley College admission deadlines are not until later in the summer.",
    "But there\'s still a lot of issues, said Margaret Ortega, vice president of student engagement and success at CWU. The delays have affected the entire admissions process.",
    "\"This is part of the compressed timeline that all colleges and universities are experiencing,\" she said. \"We\'re probably five to six months behind.\"",
    "Ortega said CWU is processing completed applications and sending information to students as soon as it can, but delays mean they can\'t get complete information to many students.",
    //Another of my parts
    "At Yakima Valley College, for example, the need to rewrite software to process this data means that YVC won\'t be able to notify students of their financial aid information until mid-June at the earliest. Washington\'s community and technical colleges need to update software for the new FAFSA applications, said Dustin Wunderlich, director of community relations at YVC.",
    "\"All higher education institutions are facing software predicaments, regardless of which software they use to deliver and process financial aid,\" Wunderlich said.",
    "He added that YVC\'s financial aid and admissions decision deadlines should not be affected. The school awards financial aid on an ongoing basis and enrolls students every quarter.",
    "The impact on scholarship opportunities varies by institution. Some, like YVC and UW, kept deadlines in place for merit-based scholarships.",
    "YVC, for example, also provides scholarships through the YVC Foundation. Applications for the scholarships, which are funded by community donors, closed in February.",
    "\"The YVC Foundation Scholarship has not been affected,\" Wunderlich said. \"We can say that the number of applications we received for YVC Foundation Scholarships was very strong.\"",
    "Wunderlich still encouraged students to fill out a FAFSA.",
    //The end of another of my parts
    "Other institutions, like CWU and Heritage University, have delayed all scholarships. CWU moved its scholarship application from the beginning to the end of April. David Wise, a vice president at Heritage University, said scholarship money is put together with federal and state financial aid to give students the best opportunity to attend college.",
    "\"A student\'s total financial aid package of which Heritage scholarships are an important part is dependent upon knowing how much federal and state aid the student will receive,\" Wise said in an email. \"Until we have an accurate federal and state aid calculation, we are unable to know how much Heritage aid needs to be applied.\"",
  ],
  [
    {
      format: "section",
      content: "Looking forward"
    },
    "Colleges and counselors have worked overtime to help students complete the FAFSA process and turn in their applications. Many are offering opportunities to connect with experienced professionals and get help.",
    "Wise said Heritage University is seeing results.",
    "\"Students for whom a parent or parents may be undocumented or have complex employment situations have been especially challenged but we are starting to see progress,\" he said in an email.",
    "It\'s still worth applying, Ortega said. The same amount of funding as previous years should be available, even if it takes longer than usual to get to students.",
    "She urged students to contact counselors with questions. The application could take as little as 30 minutes for parents and students, she added.",
    "\"We do have the aid, we do have the ability to process,\" she said. \"If you\'ve applied to Central, it\'s still very timely … to apply for financial aid.\"",
  ],
  [
    {
      format: "section",
      content: "FAFSA and financial aid workshops"
    },
    "Many resources and workshops are available for families that need assistance with the FAFSA or Washington Application for State Financial Aid (WASFA).",
    [
      {
        format: "bold",
        content: "Yakima Valley College"
      },
      subheading(
        "Where: ",
        "Yakima Valley College, Deccio Building, Building 8, Room 210"
      ),
      subheading(
        "When: ",
        "2 to 6 p.m. May 22"
      ),
      subheading(
        "Where: ",
        "YVC Grandview Campus, Grandview Main, Building 56, Room 123"
      ),
      subheading(
        "When: ",
        "May 21, 1:30 to 4:30 p.m."
      )
    ],
    [
      {
        format: "bold",
        content: "Perry Tech"
      },
      subheading(
        "Where: ",
        "Learning Resource Center in the Jim Honeyford Student Center at Perry Technical Institute, 2011 W. Washington Ave., Yakima"
      ),
      subheading(
        "When: ",
        "4:30 to 7 p.m. May 15"
      )
    ],
    [
      {
        format: "bold",
        content: "College Success Foundation"
      },
      subheading(
        "What: ",
        "FAFSA assistance, as well as housing and resource information and free food"
      ),
      subheading(
        "Where: ",
        "Maggie Perez Center, Educational Service District 105, 112 S. 2nd Ave., Yakima"
      ),
      subheading(
        "When: ",
        "5:30 to 7:30 p.m. May 16"
      )
    ]
  ],
  [
    {
      format: "section",
      content: "Completing FAFSA and WASFA"
    },
    "Central Washington University has been distributing assistance and instruction for filling out the FAFSA and the Washington Application for State Financial Aid (WASFA), which is available for undocumented students.",
    subheading(
      "Step one: ",
      "Create your FSA ID at www.studentaid.gov. All 2024-25 FAFSA contributors need a unique FSA ID to complete the application.",
      [
        "If you are a dependent student, your parent(s) must create their own FSA ID with a unique email address and password.",
        "If you are independent who is married and filed taxes separately from your spouse, your spouse must create an FSA with a unique email address and password.",
        "Contributors without social security numbers are able to create an FSA ID without an SSN.",
      ]
    ),
    subheading(
      "Step two: ",
      "Complete your 2024-25 FAFSA at www.studentaid.gov",
      [
        "The 2024-25 FAFSA will ask questions about your dependency status, tax information, and family size. This video helps explain what to expect.",
        "You, and all contributor(s), must provide consent and sign the FAFSA form electronically.",
        "Find your school code and enter it. YVC's school code is 003805. Perry Tech's school code is 009387. CWU's school code is 003771. Heritage University's school code is 003777.",
      ]
    )
  ],
  [
    {
      format: "section",
      content: "More Information"
    },
    {
      format: "link",
      content: "Washington universities extend decision deadlines due to problematic new FAFSA rollout",
      src: "https://www.yakimaherald.com/news/local/education/washington-universities-extend-decision-deadlines-due-to-problematic-new-fafsa-rollout/article_bbb13b04-d812-11ee-98dc-fbdcdd378c15.html"
    },
    {
      format: "link",
      content: "Major WA scholarship program extends portion of deadline",
      src: "https://www.yakimaherald.com/news/local/education/major-wa-scholarship-program-extends-portion-of-deadline/article_8c2862d2-d50a-11ee-a3a3-5b978152e779.html"
    }
  ],
  "Jasper Kenzo Sundeen's reporting for the Yakima Herald-Republic is possible with support from Report for America and community members through the Yakima Valley Community Fund. For information on republishing, email news@yakimaherald.com."
]