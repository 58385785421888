import { DateDisplay } from "../../../shared/DateDisplay";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setWeekTo } from "../../store/transient";
import { ReactComponent as Left } from "./caret-left-fill.svg";
import { ReactComponent as Right } from "./caret-right-fill.svg";

import style from "./index.module.scss";
import shared from "../../../shared/style.module.css";
import { useSwipeable } from "react-swipeable";
import { Responsive } from "../../../shared/Responsive";

function DesktopWeekOf() {
  const weekOf = useAppSelector((state) => state.transientBudget.weekOf);
  const dispatch = useAppDispatch();

  return (
    <p className={`${shared.center} ${shared.huge}`}>
      <b className={style.weekof}>
        <Left
          className={style.weekselector}
          onClick={() => dispatch(setWeekTo("-1"))}
        />
        Week of: <DateDisplay date={new Date(weekOf)} />
        <Right
          className={style.weekselector}
          onClick={() => dispatch(setWeekTo("+1"))}
        />
      </b>
    </p>
  );
}

function MobileWeekOf() {
  const dispatch = useAppDispatch();
  const handlers = useSwipeable({
    onSwipedLeft: () => dispatch(setWeekTo("+1")),
    onSwipedRight: () => dispatch(setWeekTo("-1"))
  });
  const weekOf = useAppSelector((state) => state.transientBudget.weekOf);

  return (
    <p className={`${shared.center} ${shared.large}`} {...handlers}>
      <b>
        <DateDisplay date={new Date(weekOf)} />
      </b>
    </p>
  )
}

export const WeekOf = Responsive(MobileWeekOf, DesktopWeekOf);