import { JSONDSL } from "../../articleInterfaces";

import kollman from "./kollman.jpg";
import barbee from "./barbee.jpeg";
import longbottom from "./longbottom.jpg";
import fulton from "./fulton.jpeg";
import keller from "./keller.jpeg";
import kosevych from "./kosevych.jpg";
import hilmes from "./hilmes.webp";
import gonzales from "./gonzales.jpg";
import strother from "./strother.jpg";
import miller from "./miller.jpg";

export const content: JSONDSL = [
  {
    format: "div-clear",
    content: [
      {
        src: barbee, 
        caption: "Unleashed reporter Lexi Barbee with her mom, Angie Barbee.",
        credit: {
          name: "Lexi Barbee",
          school: "West Valley High School"
        },
        float: "left"
      },
      {
        format: "section",
        content: "Dive in on your decisions"
      },
      "The door opens and I am consumed with the smell of Cascade Ice Cream. I run to the freezer to see the thousands of unique and colorful ice cream varieties that are all laid before my tiny eyes.",
      "Standing on my tiptoes, I peer down, saying: \"I want this one, Mom! No, wait, this one looks better … but chocolate is always the way to go.\"",
      "The decision process never ended. Growing up, I had no clue what choice to make about practically anything, and it became frustrating the older I got. That day at the ice cream store, my mom grabbed my hand and showed me a mix of both flavors that I had wanted. I was truly ecstatic and had the smile of a kid in a candy store on my face.",
      "The older I got, the more I struggled with making decisions, and I still do to this day. At the ice cream shop that day, my mom and I sat outside on an oceanside bench facing the beach. We were laughing and enjoying our ice cream and I just remember how such an insignificant decision turned into pure bliss and joy.",
      "While it seemed insignificant at the time, I specifically remember that day for one reason. That day at the beach showed how every decision has a consequence. Granted, I was a young kid and would have ended up happy with any flavor, but it made me realize that as we get older our decisions define us as people.",
      "My mom is my biggest supporter and my biggest confidante to this day. I always go to her when I have my daily struggle of decision-making, whether it be a seemingly insignificant decision such as which outfit to wear or a much bigger decision such as which college to attend in only a year\'s time.",
      "That seemingly insignificant memory from a much simpler time taught me that my mom will always be there for me to help me navigate my options. The biggest piece of advice she taught me was to not suppress my feelings and problems and to instead dive headfirst into them. The more that we hesitate over something, the bigger it becomes. If we let our worry build up, it will become an obstacle that we cannot mentally overcome.",
      "But as my mom showed me in the ice cream shop, there is so much more to life than simple black-and-white decisions. Sometimes you just have to dive headfirst to the front of the ice cream line and find your perfect mix of vanilla and chocolate.",
      {
        format: "byline",
        name: "Lexi Barbee",
        school: "West Valley High School",
        grade: "junior"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: longbottom,
        caption: "Abi Longbottom, Unleashed reporter, and her mom, Alison Longbottom on Monday, April 4, 2022.",
        credit: {
          name: "Abi Longbottom",
          school: "Naches Valley High School"
        },
        float: "right"
      },
      {
        format: "section",
        content: "You are important"
      },
      "There are roughly 7.9 billion people on this planet. Half of them are women. Reportedly, 2 billion are mothers. A fourth of that group — 500 million — have three children. My wonderful mother is a part of that lucky 500 million.",
      "Surprisingly, in a world with that many people, it is easy to feel alone. It is easy to feel lost in the shuffle, insignificant. The best advice my mother gave me is that you are never alone and you are important.",
      "She is my mother, so I suppose she has a requirement to say that. Even so, my mother has stood by me in my ups and downs, my successes and mistakes. Even when my brain told me I had no one, she was there. When I doubted the purpose of my life on this Earth, she reminded me of my significance.",
      "When I thought she was the bad person for not letting me jump on the trampoline in the rain or when I split my forehead open too many times to count, she was always there.",
      "The joke is on her. She is now my best friend and always will be. As she watched me grow from 60 centimeters to 68 inches, I watched her grow into someone with a soon-to-be-achieved doctorate who is the passionate and strong woman she is today.",
      {
        format: "byline",
        name: "Abi Longbottom",
        school: "Naches Valley High School",
        grade: "junior"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: fulton,
        caption: "Erin Fulton and her son, Unleashed reporter Magnus Fulton, at the Skagit Valley Tulip Festival.",
        credit: {
          name: "Magnus Fulton",
          school: "West Valley High School"
        },
        float: "left"
      },
      {
        format: "section",
        content: "Take time to relax!"
      },
      {
        format: "italics",
        content: [
          "There once was a mom who would say",
          "You daren\'t let this take all day;",
          "My advice you will hear",
          "So come, lend an ear",
          "Or else near and dear you will pay."
        ]
      },

      "But, in all seriousness, I appreciated my mom\'s doctrine of \"If it\'s not a \'Let\'s do this!\' it\'s an \'Absolutely not!\'\" This isn\'t to say that you shouldn\'t take risks, seize opportunities, and push yourself to grow. Rather, it was her way of reminding me to listen to myself and that sometimes it is OK to just take a break and relax.",
      "My mom and I enjoy the Skagit Valley Tulip Festival together. Born and raised in that valley, she used to bring me and my brother there every year to appreciate the flowers. Her advice has been that you should stop and smell the roses every now and then. Or, perhaps more accurately, the tulips!",
      {
        format: "byline",
        name: "Magnus Fulton",
        school: "West Valley High School",
        grade: "sophomore"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: keller,
        caption: "Denise Keller and her daughter, Unleashed reporter Natalie Keller on Friday, Nov. 12, 2021.",
        credit: {
          name: "Natalie Keller",
          school: "Selah High School"
        },
        float: "right"
      },
      {
        format: "section",
        content: "Give 100%"
      },
      "My mom is easily the hardest-working and most driven person I know; she juggles a lot in her everyday life, from taking care of my sister and me to working full time as a magazine editor.",
      "Honestly, I can\'t quite comprehend how she does it all.",
      "My mom has instilled in me many valuable lessons throughout my 17 years. Yet one that sticks out to me is the value of hard work. When it comes to having a strong work ethic, my mom has led by example by putting 100% into her many commitments and responsibilities. She is very much an inspiration in my life and has motivated me to put my best foot forward and achieve all I can.",
      "I always appreciate how supportive my mom is of my goals and ambitions. She pushes me to reach my full potential by posing the question \"Why not you?\" whenever I doubt myself.",
      "In the coming years, I hope to emulate my mom\'s tenacity, wit and strong moral compass. After all, she always has been and always will be one of the biggest role models in my life.",
      {
        format: "byline",
        name: "Natalie Keller",
        school: "Selah High School",
        grade: "junior"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: kosevych,
        caption: "Unleashed reporter Nataly Kosevych, right, with her sister Anastasia, left, and mother Natalia, center.",
        credit: "Contributed photo",
        float: "left"
      },
      {
        format: "section",
        content: "Take care of yourself first"
      },
      "\"If everyone starts caring about themselves first, it will be better for everyone.\" These are the words that my mother always told me and I believe that this phrase is applicable in all areas of our life, be it friendship, relationships, or philosophy, or even politics.",
      "I believe that everyone must take care of themselves before they can care about others.",
      "Very often we hear about the importance of showing love and respect for other people, and usually we put love for ourselves in second or even last place, equating this feeling with selfishness.",
      "But is true love for others possible if a person does not love and accept themself? I do not think so.",
      "Loving yourself means living the fullest life you ever could live. So, give yourself the right to be human, to experience, and to have fears, beliefs, delusions, desires and hopes!",
      {
        format: "byline",
        name: "Nataly Kosevich",
        school: "West Valley High School",
        grade: "junior"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: kollman,
        caption: "Lindy Kollman with her daughter, Unleashed reporter Anabelle Kollman.",
        credit: {
          name: "Anabelle Kollman",
          school: "Eisenhower High School"
        },
        float: "right"
      },
      {
        format: "section",
        content: "Be your multifaceted self"
      },
      "My mom has always shown me that a person doesn\'t have to be defined by any one trait and that you don\'t need to make sense to anyone else but you. The example she set showed me that you can be an \"outdoorsy girl\" and still love sparkly dresses. In fact, she often says something to the effect of \"My perfect day is one where I\'m in hiking boots on the trail and then in fancy heels for dinner.\"",
      "Another thing about my mom: She loves lipstick. Like, seriously, she has a full-on collection. On family hikes, ski days, or even summiting Mount Adams you can find her wearing some bright pink lipstick (or red, or nude, or coral, or any of her dozen other shades).",
      "With that same attitude, I learned that you can be a bookworm and still like sports. You can love and support people in your life and still recognize and honor your own needs. You can be smart and still need to ask for help. You can be strong and still be kind.",
      "As I grew older, I realized the much larger lesson that allows for that self-assuredness — that when the only approval you look for is God\'s, many opportunities open up. At the same time that happens, life becomes much simpler.",
      {
        format: "byline",
        name: "Anabelle Kollman",
        school: "Eisenhower High School",
        grade: "junior"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: hilmes,
        caption: "Jennifer Hilmes with her daughter, Unleashed reporter Indiana Hilmes.",
        credit: {
          name: "Indiana Hilmes",
          school: "Selah High School"
        },
        float: "left"
      },
      {
        format: "section",
        content: "Face challenges with courage"
      },
      "As much as it might sound like something a third-grader would write, the best advice my mom ever gave to me was unspoken: When you face a challenge with courage, nothing can get in your way.",
      "This past fall, my mom was moved from her normal job as a diabetes educator for Yakima Valley Memorial Hospital and became a floor nurse at the height of the COVID-19 pandemic. She hadn\'t worked directly with hospital-admitted patients in close to 20 years, but she dove in headfirst. She began working 12-hour shifts three times a week, which was a dramatic change from her normal work schedule.",
      "I found this admirable because she was able to temporarily sacrifice time to herself as well as time with her family in order to support our community.",
      "As someone who often struggles with being brave and facing my fears, watching my own mother put her needs aside to instead support the greater good around her was incredibly inspirational. Since then, I\'ve found that it\'s easier for me to be a little less afraid, too.",
      {
        format: "byline",
        name: "Indiana Hilmes",
        school: "Selah High School",
        grade: "sophomore"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: gonzales,
        caption: "Noelia Gonzalez and her daughter, Unleashed reporter Olivia Gonzalez.",
        credit: {
          name: "Olivia Gonzalez",
          school: "Davis High School"
        },
        float: "right"
      },
      {
        format: "section",
        content: "Appreciate life!"
      },
      "Make sure to travel. Shower steam will take wrinkles out of anything. Always carry a bright lipstick in your purse. To-do lists are a must.",
      "My mom has instilled these snippets of advice into my daily life for years now and I can\'t help but think that, without them, I would be so much different.",
      "I grew up in a single-mother household and have learned the most valuable lessons from my mother (and had the most fun). My mom isn\'t a mom; she\'s more like a best friend. Think \"Gilmore Girls\" but with bigger hair.",
      "She filled our house with love and music and all types of books, teaching me — not directly, but through actions — that life is short and we all deserve the opportunity to appreciate it.",
      "Taking mental notes wherever we went, I like to believe I turned out quite a bit like her. I\'ve learned to fight for what I believe is right, protect my own, and to be respectful and courageous while doing it.",
      "I learned quickly that I always had her on my side. She is my true inspiration in all things I do and I look up to her in more ways than I can even count.",
      "My best friend, guidepost, and road trip confidante, you are my everything.",
      {
        format: "byline",
        name: "Olivia Gonzalez",
        school: "Davis High School",
        grade: "senior"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: strother,
        caption: "Unleashed reporter Jillian Strother with her mom, Carla Strother.",
        credit: {
          name: "Jillian Strother",
          school: "Riverside Christian School"
        },
        float: "left"
      },
      {
        format: "section",
        content: "Doing your best matters most"
      },
      "I don\'t think it\'s an exaggeration to say that my mom has been an influential figure in my life for all 16 years. She\'s always been there and looks out for me, even when I don\'t really realize it.",
      "Throughout the years, I\'ve learned a lot from my mom, and one of the best pieces of advice she\'s given me is this: Your best is enough.",
      "During a stressful time when I was worried about failing to meet expectations, my mom assured me that the results did not really matter as long as I had tried my best.",
      "I am incredibly grateful to have her in my life and I\'m thankful that she\'s put up with me and my antics for so long.",
      {
        format: "byline",
        school: "Riverside Christian School",
        name: "Jillian Strother",
        grade: "sophomore"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      //No photo
      {
        format: "section",
        content: "It all happens for a reason"
      },
      "My mom has taught me a lot. From simple things to important life lessons, she\'s given me a lot of knowledge. The best advice she has given me is that everything happens for a reason, and if things don\'t go your way, that\'s OK. That just means that Allah (the Arabic word for \"God\") has something better planned for you.",
      "This piece of advice has really helped me a lot. It\'s taught me to not be upset over missing some event or not winning some competition because, later, I always find out that it was a good thing I missed that opportunity and that something better has always come my way. Her advice has taught me to be grateful in every situation and to be patient because the next opportunity is always better than the one before.",
      "I want to thank my mom for all that she has done for me. I feel blessed to have such a marvelous mom who has always supported me and told me to never give up. Her advice has really helped me to become a happier and more optimistic person. Thank you, Ammi (the Urdu word for \"Mom\")!",
      {
        format: "byline",
        name: "Maham Khan",
        school: "West Valley High School",
        grade: "freshman"
      }
    ]
  },

  {
    format: "div-clear",
    content: [
      {
        src: miller,
        caption: "Amber Miller with her daughter, Unleashed reporter Gracie Miller.",
        credit: {
          name: "Gracie Miller",
          school: "Eisenhower High School"
        },
        float: "right"
      },
      {
        format: "section",
        content: "It\'s OK to not always be perfect"
      },
      "From my mom I\'ve gained a lot of things, a few of those being: my hazel eyes, my sarcastic and witty humor, and my love for romantic comedies. She has also given me some great advice, the best of it being to understand that you can\'t be perfect at everything.",
      "I\'ve always had this unrealistic expectation of myself that I need to be perfect at everything I try, even if it\'s my first time doing it. When I started driving I kept beating myself up for not immediately being good at it, which is ridiculous.",
      "My mom was there the whole time reminding me it was perfectly OK to be imperfect.",
      {
        format: "byline",
        name: "Gracie Miller",
        school: "Eisenhower High School",
        grade: "senior"
      }
    ]
  }/*,

  {
    format: "div-clear",
    content: [
      [
        [
          [
            "Some text!"
          ],
          "Still block level"
        ],
        {
          format: "bold",
          content: "Bold, but that's cool"
        }
      ],
      {
        format: "inline",
        content: [
          "Whole ",
          "lotta ",
          "inline ",
          {
            format: "italics",
            content: "italics!"
          },
          {
            format: "block",
            content: [
              "Back to blocks!",
              "YEAH!"
            ]
          }
        ]
      }
    ]
  } */
];