import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "The tiny computers we carry in our back pockets always seem to have another trick up their protective cases. While mastering it all would likely require the dedication and knowledge of a Ph.D., there are a few features you might not know about that can save you some hassle.",
  {
    format: "section",
    content: "Autocorrect"
  },
  "Autocorrect is a lifesaver. While it has saved us all from misspelling important words in our day-to-day lives, sometimes it can be a little overzealous, or downright unhelpful. Thankfully, like with everything else, there is a buried page in the settings app that can help.",
  "On iOS devices, autocorrect can be configured in Settings > General > Keyboard. In this page, you can enable and disable auto-capitalization, spellcheck, next word predictions, and, if you are brave enough to try it, the autocorrect feature itself.",
  "On Android-based devices, many of the same options are available in Settings > System > Languages and Input > Virtual Keyboard > Gboard > Text Correction, although the exact location varies from device to device. Here you can also enable and disable auto-capitalization, spellcheck, next word predictions, and the autocorrect feature.",
  "While such sweeping changes to autocorrect are sometimes helpful, often it\'s just a word or two that is problematic. Thankfully, our phones have a solution. Why learn the correct way to spell some of those irksome words, like February or Wednesday, when you can add the spellings that SHOULD be correct and have your phone make the changes for you?",
  "On iOS, the relevant settings can be found under Settings > General > Keyboard > Text Replacement. On this page, you can push the plus icon in the top right corner, add your version of the worrisome word, and then add the correct spelling.",
  "For Android-based devices, the settings can be found under Settings > System > Languages and Input > Virtual keyboard > Gboard > Personal Dictionary, although the exact location varies from device to device. From there, you can push the plus icon in the top right corner, add the correct version of the word in the first box, and put the version that should be correct in the second box that is labeled \"Shortcut.\""
];