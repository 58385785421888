import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "Off The Chain: Unleashed's picks for September",
  articleType: "review",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  content,
  date: new Date(2020, 8, 27),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/off-the-chain-unleasheds-picks-for-september/article_58fad372-3ca5-5cb6-8608-9b4909dd0286.html",
  award: "Best \"Off The Chain\" Review"
}

export default article;