import { Article } from "../../articleInterfaces";
import { content } from "./content";

import twists from "./twists.jpg";
import almond from "./almond.jpg";
import blossom from "./blossom.jpg";
import santa from "./santa.jpg";
import zebra from "./zebra.jpg";

const article: Article = {
  title: "An Unleashed guide to the sweets of the season",
  date: new Date(2021, 10, 28),
  articleType: "review",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/reviews/an-unleashed-guide-to-the-sweets-of-the-season/article_02ed99cb-9911-5d4c-a7d1-0c301037722a.html",
  images: [
    {
      src: twists,
      caption: "German sour cream twist.",
      credit: {
        name: "Lucas Cone",
        school: "West Valley High School"
      }
    },
    {
      src: blossom,
      caption: "Peanut butter blossom cookie.",
      credit: {
        name: "Magnus Fulton",
        school: "West Valley High School"
      }
    },
    {
      src: zebra,
      caption: "A plate of zebra cookies.",
      credit: {
        name: "Maria Jacobson",
        school: "Highland High School"
      }
    },
    {
      src: almond,
      caption: "The South Asian almond cookies known as naan khatai.",
      credit: {
        name: "Maham Khan",
        school: "West Valley High School"
      }
    },
    {
      src: santa,
      caption: "It's the season of the Santa diet.",
      credit: {
        name: "Morgan Greene",
        school: "Davis High School"
      }
    }
  ],
  author: [
    {
      name: "Lucas Cone",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    },
    {
      name: "Olivia Gonzalez",
      school: "Davis High School",
      grade: "senior"
    },
    {
      name: "Kate Bethel",
      school: "Riverside Christian School",
      grade: "senior"
    },
    {
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "junior"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "junior"
    },
    {
      name: "Maham Khan",
      school: "West Valley High School",
      grade: "freshman"
    },
    {
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "junior"
    }
  ],
  content
};

export default article;