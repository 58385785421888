import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    {
      format: "section",
      content: "Story time"
    },
    "Books are fantastic. They let you explore worlds you could never otherwise explore. They let you learn from people you\'d never otherwise meet. They can teach you, entertain you, thrill you, and make you think.",
    "Growing up, my mom and I loved sharing the little moments over a good book.",
    "\"One of my favorite memories is reading to you guys when you were little,\" said my mom, Erin Fulton.",
    "\"It was really fun reading the Harry Potter series together and then watching the movie after we finished each book,\" she said. \"You would both get your own bowls of popcorn with the toppings you liked the best and we would settle in to see how the book and the movie compared.",
    "\"I remember lots of laughter when we read the Fudge series by Judy Blume and anything about Hank the Cowdog by John R. Erickson.\"",
    "What better way to unwind after a stressful day than to spend some time laughing and reading together? I loved coming home after a day of school, knowing that last night\'s cliffhanger was about to be resolved. After solving the day\'s little challenges, it was nice to come home and have some of those little moments reading a book together.",
    "\"Sometimes motherhood needs a little levity,\" my mom said. \"And it was nice to share some laughs together while we read stories at the end of a long day.\"",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ],
  [
    {
      format: "section",
      content: "Travel time"
    },
    "My mom\'s most memorable motherhood moment was when I first visited Lake Wenatchee at age 7. This was special for her because she went camping there when she was a kid.",
    "We camped in the same area that she did years ago. The memories she made at Lake Wenatchee came back as she saw me do everything she did when she was younger.",
    "We were there for just less than a week, but our days were jam-packed with activities. I went to The Alps Candy Store just east of Lake Wenatchee, with its variety of fun candies, fudge, and pop. I also went swimming on a sandy shore, which had clear, fairly warm water and a view of mountains in the distance. A horseback trail ride with my family through the forest was another activity I enjoyed. I also liked feeding peanuts to the squirrels at our campsite.",
    "Since my first trip, we\'ve been going to Lake Wenatchee every summer, and there has always been so much to do. Now my two sisters are making memories there, too.",
    "Someday, maybe we\'ll have our most memorable moments with our kids. And, hopefully, my mom will be there to relive her childhood with them, as well.",
    {
      format: "byline",
      name: "Carson VanEaton",
      school: "East Valley High School",
      grade: "junior"
    }
  ]
];