import { Article, Author } from "../../articleInterfaces";

import { content } from "./content";

import staff from "./staff.jpg";
import bethel from "./bethel.jpg";
import barbee from "./barbee.jpg";
import strother from "./strother.jpg";
import cabbage from "./cabbage.jpg";
import kollman from "./kollman.jpg";
import kosevich from "./kosevich.jpg";
import keller from "./keller.jpg";
import khan from "./khan.jpg";
import montelongo from "./montelongo.jpg";
import hilmes from "./hilmes.jpg";
import miller from "./miller.jpg";
import mickelson from "./mickelson.jpg";
import cone from "./cone.jpg";
import fulton from "./fulton.jpg";
import rankin from "./rankin.jpg";
import hoeger from "./hoeger.jpg";
import padilla from "./padilla.jpg";
import peters from "./peters.jpg";
import jacobson from "./jacobson.jpg";
import gonzalez from "./gonzalez.jpg";
import ladd from "./ladd.jpg";
import longbottom from "./longbottom.jpg";

const DAVID: Author = { name: "David Goehner", school: "Unleashed coordinator" };

const article: Article = {
  title: "We're the 2021-22 Unleashed team!",
  articleType: "staff",
  date: new Date(2021, 8, 26),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/staff/were-the-2021-22-unleashed-team/article_247a7e47-d8cc-5379-bb6f-b234f4b8386d.html",
  author: [
    {
      name: "Abi Longbottom",
      school: "Naches Valley High School",
      grade: "junior"
    },
    {
      name: "Anabelle Kollman",
      school: "Eisenhower High School",
      grade: "junior"
    },
    {
      name: "Andrew Cabbage",
      school: "West Valley High School",
      grade: "sophomore"
    },
    {
      name: "Gracie Miller",
      school: "Eisenhower High School",
      grade: "senior"
    },
    {
      name: "Indiana Hilmes",
      school: "Selah High School",
      grade: "sophomore"
    },
    {
      name: "Jillian Strother",
      school: "Riverside Christian School",
      grade: "sophomore"
    },
    {
      name: "J.J. Montelongo",
      school: "Sunnyside High School",
      grade: "senior"
    },
    {
      name: "Kate Bethel",
      school: "Riverside Christian School",
      grade: "senior"
    },
    {
      name: "Lexi Barbee",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Lucas Cone",
      school: "West Valley High School",
      grade: "senior"
    },
    {
      name: "Macie Ladd",
      school: "Selah High School",
      grade: "sophomore"
    },
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "sophomore"
    },
    {
      name: "Maham Khan",
      school: "West Valley High School",
      grade: "freshman"
    },
    {
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "sophomore"
    },
    {
      name: "Mary Mickelson",
      school: "Eisenhower High School",
      grade: "junior"
    },
    {
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "junior"
    },
    {
      name: "Nataly Kosevich",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Olivia Gonzalez",
      school: "Davis High School",
      grade: "senior"
    },
    {
      name: "Olivia Rankin",
      school: "Eisenhower High School",
      grade: "junior"
    },
    {
      name: "Ruby Hoeger",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Stella Padilla",
      school: "West Valley High School",
      grade: "junior"
    },
    {
      name: "Whit Peters",
      school: "Selah High School",
      grade: "sophomore"
    }
  ],
  images: [
    {
      src: staff,
      caption:
        "STANDING: Macie Ladd (Selah), Nataly Kosevich (West Valley), Natalie Keller (Selah), Stella Padilla (West Valley), Lucas Cone (West Valley), Ruby Hoeger (West Valley), Maham Khan (West Valley), Whit Peters (Selah), Lexi Barbee (West Valley), Abi Longbottom (Naches Valley), Olivia Gonzalez (Davis), Mary Mickelson (Eisenhower), Indy Hilmes (Selah), Anabelle Kollman (Eisenhower), Gracie Miller (Eisenhower). FRONT: Joshua Montelongo (Sunnyside), Andrew Cabbage (West Valley), Magnus Fulton (West Valley), Jillian Strother (Riverside Christian), Kate Bethel (Riverside Christian), Olivia Rankin (Eisenhower). NOT PICTURED: Maria Jacobson (Highland).",
      credit: DAVID
    },
    {
      src: bethel,
      caption: "Kate Bethel",
      credit: DAVID
    },
    {
      src: barbee,
      caption: "Lexi Barbee",
      credit: DAVID
    },
    {
      src: strother,
      caption: "Jillian Strother",
      credit: DAVID
    },
    {
      src: cabbage,
      caption: "Andrew Cabbage",
      credit: DAVID
    },
    {
      src: kollman,
      caption: "Anabelle Kollman",
      credit: DAVID
    },
    {
      src: kosevich,
      caption: "Nataly Kosevich",
      credit: DAVID
    },
    {
      src: keller,
      caption: "Natalie Keller",
      credit: DAVID
    },
    {
      src: khan,
      caption: "Maham Khan",
      credit: DAVID
    },
    {
      src: montelongo,
      caption: "JJ Montelongo",
      credit: DAVID
    },
    {
      src: hilmes,
      caption: "Indy Hilmes",
      credit: DAVID
    },
    {
      src: miller,
      caption: "Gracie Miller",
      credit: DAVID
    },
    {
      src: mickelson,
      caption: "Mary Mickelson",
      credit: DAVID
    },
    {
      src: cone,
      caption: "Lucas Cone",
      credit: DAVID
    },
    {
      src: fulton,
      caption: "Magnus Fulton",
      credit: DAVID
    },
    {
      src: rankin,
      caption: "Olivia Rankin",
      credit: DAVID
    },
    {
      src: hoeger,
      caption: "Ruby Hoeger",
      credit: DAVID
    },
    {
      src: padilla,
      caption: "Stella Padilla",
      credit: DAVID
    },
    {
      src: peters,
      caption: "Whit Peters",
      credit: DAVID
    },
    {
      src: jacobson,
      caption: "Maria Jacobson",
      credit: DAVID
    },
    {
      src: gonzalez,
      caption: "Olivia Gonzalez",
      credit: DAVID
    },
    {
      src: ladd,
      caption: "Macie Ladd",
      credit: DAVID
    },
    {
      src: longbottom,
      caption: "Abi Longbottom",
      credit: DAVID
    }
  ],
  content
};

export default article;
