import { ReactNode } from "react";
import { isString } from "../../../../shared/isString";
import { JSONDSL } from "../../../articleInterfaces";
import { DateDisplay } from "../../../../shared/DateDisplay";
import { UnleashedImage } from "../../UnleashedImage";
import { generateKey } from "../generateKey";

import style from "./index.module.css";

function RenderWrapper({
  render,
  children
}: {
  render: "block" | "inline";
  children: ReactNode;
}) {
  if (render === "block") {
    return <p>{children}</p>;
  } else {
    return <span>{children}</span>;
  }
}

function JSONDSLAsArray(obj: JSONDSL): JSONDSL[] {
  if (Array.isArray(obj)) {
    return obj;
  } else {
    return [obj];
  }
}

export function ContentRenderer({
  content,
  render = "block"
}: {
  content: JSONDSL;
  render?: "block" | "inline";
}): JSX.Element {
  //Recursion here
  if (Array.isArray(content)) {
    const renderedContent = content.map((c) => (
      <ContentRenderer content={c} render={render} key={generateKey(c)} />
    ));
    return <>{renderedContent}</>;
  } else if (isString(content)) {
    return <RenderWrapper render={render}>{content}</RenderWrapper>
  } else if ("format" in content) {
    switch (content.format) {
      case "bold":
        return (
          <>{
            JSONDSLAsArray(content.content).map(c => (
              <RenderWrapper render={render} key={generateKey(c)}>
                <b>
                  <ContentRenderer content={c} render="inline" />
                </b>
              </RenderWrapper>
            ))
          }</>
        );

      case "italics":
        return (
          <>{
            JSONDSLAsArray(content.content).map(c => (
              <RenderWrapper render={render} key={generateKey(c)}>
                <i>
                  <ContentRenderer content={c} render="inline" />
                </i>
              </RenderWrapper>
            ))
          }</>
        );

      //Always block level
      case "section":
        return (
          <h2>
            <ContentRenderer render="inline" content={content.content} />
          </h2>
        );

      case "div-clear":
        return (
          <div className={style.clear}>
            <ContentRenderer render={render} content={content.content} />
          </div>
        );

      case "block-with-inline":
        return (
          <p>
            <ContentRenderer render="inline" content={content.content} />
          </p>
        );

      //Always block level, although nothing says it has to be
      case "byline":
        return (
          <p>
            &mdash;{" "}
            <i>
              {content.name}, {content.school}, {content.grade}
            </i>
          </p>
        );

      case "unordered-list":
        return (
          <ul>
            {JSONDSLAsArray(content.content).map(c => (
              <li key={generateKey(c)}>
                <ContentRenderer render="inline" content={c} />
              </li>
            ))}
          </ul>
        );
      
      case "link":
        return <RenderWrapper render={render}><a href={content.src}><ContentRenderer render="inline" content={content.content} /></a></RenderWrapper>

      case "date":
        return <DateDisplay date={content.date} />

      default:
        throw Error("Unacceptable format provided!");
    }
  } else if ("src" in content) {
    return <UnleashedImage {...content} />;
  } else {
    throw Error("Something unexpected happened!");
  }
}