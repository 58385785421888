import { Article } from "../../articleInterfaces";
import { BasicSlideshow } from "../Slideshow";
import { UnleashedImage } from "../UnleashedImage";
import { generateKey } from "../processing/generateKey";

export function Cut(article: Article) {
  if (!!article.images) {
    if (article.images.length === 1) {
      return <UnleashedImage {...article.images[0]}  />
    } else {
      return (
        <BasicSlideshow slideChangeDelay={5000}>
          {article.images.map(i => <UnleashedImage {...i} key={generateKey(i)} />)}
        </BasicSlideshow>
      )
    }
  } else {
    return null;
  }
}