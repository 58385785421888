import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "If a year ago you told me that I would be buying socks for my own enjoyment, I would have asked you where it all went so terribly wrong. So, how did I, as someone with a perfectly neutral opinion about socks, get here? ",
  "As all great purchases do, it started with an Instagram ad. ",
  "As much as it shames me to admit, our artificially intelligent overlords knew just the product to get me to click on the \"checkout\" button: Socks from a company called Rock \'Em Socks that are themed after this year\'s \"Guardians of the Galaxy Vol. 3\" movie. ",
  "With the $20 gone and a shipping notice in hand, I spent the week of waiting for shipping by battling the new tide of sock-themed online advertisements.",
  "Thankfully for a purchase made on impulse, the socks exceeded my expectations. The design that so motivated me to buy has withstood a month of abuse through school days, cross country practices, and many washings with little sign of wearing down. ",
  "The socks themselves are quite breathable as well, but are also warm enough to fight the bite of a cool fall day. The fabric itself is soft and stretchy.",
  {
    format: "block-with-inline",
    content: [
      "If you want a pair for yourself, head over to ",
      {
        format: "link",
        // Yes, I know the bare domain is the correct domain. But I really did
        // not want to fight my editor over this, especially because it 
        // redirects anyway.
        src: "https://rockemsocks.com/",
        content: "www.rockemsocks.com"
      },
      ". The site has socks for every occasion – holidays, sports, and pop culture – running for $10 to $20. You can even design your own socks for about $25 if you so desire."
    ]
  },
  "At least then I won\'t be the only one getting sock ads."
];
