import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "It\'s not every day that you find history in your backyard, but that\'s exactly what happened to me Jan. 11.",
  "I have a very generous fence. It transforms the multitude of flotsam and jetsam that have the audacity to escape our garbage cans into a refreshing take on what a bouquet should be. Sometimes, it even strains out treasures.",
  "So on this day as I gathered the gifts of this plastic plethora into my garbage can, something caught my eye. Folded half over, nestled amongst a bed of leaves and wrappers like an almost-literal diamond in the rough, was a newspaper from 1988. Specifically, it was the Sunday comics section from the Yakima Herald-Republic\'s issue of Jan. 24, 1988. I didn\'t recognize many of the titles.",
  "Its edges were worn and yellowed, but otherwise intact. Dirt had hardly marred its surface. The pages were soft enough that it could have come off the press yesterday.",
  "I\'ve seen copies of books in libraries as old as this comics section in worse shape. And you don\'t often see something twice as old as you blowing around in the wind. So, what was it doing blowing around in the wind?",
  "Was it a commemoration of a special date for someone\'s family member or a friend?",
  "Had the paper been used as padding in some long-forgotten box?",
  "Was it just a really good issue?",
  "And why was it in such good condition, blowing around 34 years later?",
  "Whether or not it\'s the truth, that last theory seems to have some backing to. It was a really good issue.",
  "I was first introduced to Bill Watterson\'s \"Calvin and Hobbes\" a few years back when I found a copy of \"The Days Are Just Packed\" on my family\'s bookshelf. \"Calvin and Hobbes\" follows the antics of Calvin, a young boy, and Hobbes, his all-too-real stuffed tiger, as he navigates life, adventure, and the quest for the perfect opportunity to throw a water balloon. It\'s a shame this legendary strip isn\'t still being produced, and it was nice to see a newspaper from those glorious days when it was.",
  "\"Prince Valiant in the Days of King Arthur\" was another comic strip that caught my eye. Originally written by Hal Foster, the strip was later taken over in 1980 by John Collen Murphy, who wrote the installment from 1988. \"Prince Valiant\" caught my eye because it had a distinctive and detailed style, almost like that of a comic book. \"Prince Valiant\" also employed an overarching storyline, which very few of the comics I am familiar with do. The closest I\'ve seen to something similar is, on occasion, Lynn Johnson\'s \"For Better or for Worse.\"",
  "Art Nugent\'s \"Uncle Art\'s Funland\" also stood out to me. This strip seeks to entertain you not just with humor, but also with a series of games and riddles. It was a fun and unique way to sign off on the comics section.",
  "It was also interesting seeing which of the comics from back then were still in print today. Classics like Jim Davis\' \"Garfield,\" Lynn Johnson\'s \"For Better or for Worse,\" and Art Sansom\'s \"The Born Loser\" have stood the test of time. Charles Schulz\'s \"Peanuts\" and Bob Thaves\' \"Frank and Earnest\" also graced the pages during this time, although they appear in a different section of the paper today. We also have a richer selection of comics available today versus 1988, with today\'s Sunday paper scoring an eight-comic lead over the 15 comics of its 1988 counterpart.",
  "Next time you see a piece of trash blowing around, don\'t be afraid to recycle it if you can. And, hey — you never know if you\'ll find something unexpected along the way.",
]