import { useAppSelector } from "../../store";
import { ComponentType } from "react";

export function Responsive<P>(
  MobileComponent: ComponentType<P>,
  DesktopComponent: ComponentType<P>
) {
  return function ResponsiveComponent(props: P & JSX.IntrinsicAttributes) {
    const isMobile = useAppSelector((state) => state.responsive.isMobile);

    if (isMobile) {
      return <MobileComponent {...props} />;
    } else {
      return <DesktopComponent {...props} />;
    }
  };
}
