import { JSONDSL } from "../../articleInterfaces"

export const content: JSONDSL = [
  "When I was younger, I used to watch the Discovery Channel's \"How the Universe Works\" all the time. The show had a number of hosts, one of whom was NASA\'s planetary defense expert, Dr. Moogega Cooper.",
  "Little did I know that, 10 years later, I\'d be sitting in a row of reporters with Dr. Cooper looking right back at me during a press conference at The Capitol Theatre on Oct. 19, just minutes before she went on stage for Yakima\'s Town Hall series.",
  "Dr. Cooper is one of many researchers looking to answer the question: Is there other life out there in the universe? She is one of many researchers at NASA\'s Jet Propulsion Laboratory looking toward Earth\'s heavenly neighbor, Mars, for an answer.",
  "But why Mars? If you looked at Mars with a telescope today, all you would see is an endless, dusty wasteland, colored red from iron oxides in the soil. Also, its atmosphere has also been almost completely stripped away over the ages.",
  "The makings of a five-star resort for aliens? Maybe not right now as a barren, subzero desert, but Mars was not always this way. Previous missions to Earth\'s sibling have revealed a wetter, and potentially more habitable past. A thicker atmosphere, with rivers, lakes and oceans covering the surface, may have peered back at Earth long ago.",
  "Maybe, just maybe, life could have risen on Mars as well, leaving only faint echos behind to be heard today, much like the fossils of dinosaurs from long ago.",
  "The only question left is: Where to look? After many meetings, the researchers settled on a river delta. The hope is that any traces of ancient Martian life may have been carried downstream and deposited in the sediments, maximizing our chances of finding something, versus strolling across the great expanses of desert.",
  "This is where Dr. Cooper comes in. Her specialty is in planetary protection, or more simply, ensuring that none of Earth\'s many lifeforms hitch a ride across the solar system. After all, it is pretty hard to find aliens if all you see is earthly bacteria smiling back at you.",
  "Her weapon of choice against these freeloaders? A high-energy state of matter, known as plasma, used to cook the mission\'s rover, Perseverance, before it landed on the red planet. After all, most things tend to die when you fry them at high temperatures for about a week.",
  "Her real concern, though, is something known as a bacterial endospore, a tiny \"seed\" that can \"sprout\" into the real-deal bacteria if given the right conditions. NASA\'s standard is to ensure that no more than 300 of these microscopic invaders evade sanitation protocols per square inch.",
  "Dr. Cooper and her team were able to clean Perseverance to 10 times this standard. Her job was to design and implement the testing protocols for making this happen. This meant constantly swabbing and spot checking the rover to ensure that nothing sneaked past.",
  "Since landing on Mars in 2021, the mission of the Perseverance rover is to explore the delta, take samples, and cache them at various \"depots\" for their protection. The last thing NASA wants is for a promising sample to get stuck inside the rover if it were to break down.",
  "This is where you come in. As Dr. Cooper explained during her Town Hall presentation, about 80% of the technology needed for the sample\'s return mission doesn\'t exist. Who knows? Maybe your idea could be the one that helps NASA in the search for life across the universe.",
]