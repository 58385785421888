import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    "On Friday night, June 7, West Valley High School's graduates walked out of the SunDome with their diplomas and into the first moments of the rest of their lives.",
    "However, four of them — Katie Murdock, Charlie Naught, Josh Raj, and Ivy Tweedy — did not just walk out as graduates. They also left as West Valley\'s top seniors from the class of 2024 as valedictorians.",
    "A lot of memories came with their years at West Valley."
  ],
  [
    {
      format: "section",
      content: "Elementary school"
    },
    "The journey across the graduation stage started with the single step that occurred during elementary school.",
    "\"First, I went to Gilbert Elementary School in the Yakima School District and then I moved to (West Valley's) Apple Valley from first to fourth grade,\" Tweedy said.",
    "\"I really lucked out on all my teachers. I liked Mrs. Jetton. She was just kind. I don't remember her for curing cancer or anything. I just remember her from being a solid, all-around good teacher. She was kind, very compassionate, and she knew how to deal with our 6-year-old breakdowns and stuff.\"",
    "Naught also went to Apple Valley.",
    "\"I went to Apple Valley, the best elementary,\" he said. \"First off, it was the only elementary that did not have hallways for the students. It was when you always traveled outside or sometimes inside. It was basically common rooms, like a library.",
    "\"My favorite teacher is a tie between Mrs. McDonald and Mrs. Fanum. Mrs. Fanum had a money system. I think it was called 'Fanum Bucks.' And at the end of the year there was a giant auction. I collected $700 in that class. I think I had the second most money in the class. I bought stuffed animals. I bought a bookshelf.",
    "\"It taught us money management because throughout the year you could buy stuff with the money, like pencils, a pizza party, and bathroom passes. But we always knew at the end of the year there would be a giant auction.\"",
  ],
  [
    {
      format: "section",
      content: "The highlights"
    },
    "\"The best part has definitely been cross country and track, which I\'ve done all four years,\" Murdock said. \"I always liked running and I liked the team aspect of both of them. It's always a good time.",
    "\"I would say a favorite memory was when our cross country team got third in state this year. That was pretty great.\"",
    "Raj, who played tennis all four years for West Valley, enjoyed the activities that make senior year special.",
    "\"I think getting on homecoming court, going to the homecoming game, and driving around the track,\" he said. \"I think that was probably one of like the coolest things like I've done.",
    "\"Along with that, we started raising money for the Maui wildfire. We raised over $1,000. I thought that was really cool how, instead of just standing on a podium, you're actually making some sort of change. I think that was a really different thing that we did this year. I'm so grateful that I was able to be a part of it.\"",
  ],
  [
    {
      format: "section",
      content: "Lessons learned"
    },
    "High school is all about learning, both in the classroom and outside of it.",
    "\"I learned that comparing yourself to others doesn't bring you joy,\" Tweedy said. \"It's never going to give you some satisfaction. Learning to be happy with yourself is really hard. I don't know if high school has taught me that. But on some level I hope it has, maybe.\"",
    "For Murdock, it was all about collaboration.",
    "\"I'd say the most important thing we learned was how to work with people, how to get along with people,\" she said. \"Sometimes, you just get in classes where you have to work with people and they\'ve all just got to get it done.\"",
    "For Naught, who is interested in becoming a veterinarian, the most important lessons came from an advanced placement class.",
    "\"The class was rough, but AP biology (stands out) because we learned about how life works,\" he said. \"My favorite unit was how cells worked. Cells are just one giant, organized mess.\"",
    "For Raj, some important answers about lessons learned were found in the numbers. ",
    "\"I took calculus last year and I think Nordy (Jamie Nordstrom) is one of my favorite teachers. It was one of my favorite classes, even with the difficulty,\" he said. \"It really made me see how interesting math can be. I think with science I've had Mr. Noll for three years in a row. I feel like I really made a strong connection with him.\"",
  ],
  [
    {
      format: "section",
      content: "The road ahead"
    },
    "With graduation behind them, what happens next for these four seniors?",
    "Murdock is looking into becoming a dietitian through Brigham Young University in Provo, Utah.",
    "\"I've always enjoyed science, anatomy, and that type of thing,\" she said. \"But I also enjoy the fitness and athletic part of it. So, I think it's just a good combo of all of that. I took anatomy and I enjoyed it. I also really enjoy chemistry.\"",
    "Raj wants to major in bioengineering at the University of Washington.",
    "\"I plan on getting my bioengineering degree and then work in that industry by designing medical instruments and implementing my expertise that I get in math, science and engineering into the health and medicine field it in any way I can,\" he said.",
    "\"I've seen biology because of my mom, who\'s in the medical field, and my sister, who\'s going in the medical field, as well. I've always felt a strong connection to that. I've also always felt a connection to math and science, and I feel like I'm just better at math and science. I feel like this major was a way for me to like include both of these passions that I have.\"",
    "Naught plans to attend Central Washington University and run for the cross country and track teams, which he hopes will help pay for college. Then he wants to attend Washington State University for graduate work.",
    "\"I\'m interested in becoming a veterinarian,\" he said. \"There are small animals, which is like your house pets and stuff, which I\'ll probably do. Then there are big animals like horses and cows. I guess there are technically zoo animals, which is another whole category.\"",
    "Naught hopes to eventually return to the rural areas of eastern Washington to start his own practice.",
    "\"So, I might need to get a business degree to do my own business,\" he added.",
    "However, not having everything mapped out is also totally fine. After high school, Tweedy is set to enroll at Colorado State. And what is she interested in pursuing?",
    "\"Probably a career,\" she joked. \"My career is to go to college actually. That's my five-year plan.\"",
  ]
]