import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Every year, millions of people across the country celebrate the holidays and the end of the year by bringing a Christmas tree into their homes.",
  "However, soon after they burden its boughs with ornaments and lights and sing it a carol or two, the consensus is unanimous: The tree has overstayed its welcome.",
  "For most families, the question then becomes: What to do with the remains?",
  "One sensible option is to chop the tree into small pieces and burn them over the next several weeks. Its branches and twigs make great kindling. The needle-bearing outer branches are a fantastic way to rev a fire into a bright cackle. The trunk provides a nice, long-lasting burn.",
  "The tree may have warmed our hearts metaphorically over the previous weeks, but it now has a chance to do so literally as the winter progresses into its full-blown chill. Chestnuts, anyone?",
  "Another popular option for disposal is the wood chipper. This is a fantastic way to get some extended use of your tree when wood burning is not an option. The wood chips make great ground cover, can act as fill in large pots, and offer an attractive alternative to grass or gravel in flower beds. The pine needles also make good mulch, especially for your more acidic soil-loving plants.",
  "However, another and oft-overlooked option is to turn your tree into a catapult. The central trunk of the tree is a solid candidate with which to build a small but respectable tool for fun, neighborly competition. As an added bonus, you can cut postage costs by hurling any last-minute gifts with ease!",
  "The branches can also be used for wreaths, and other arts and crafts as well, such as decorations for bird feeders or houses. Or the wood from the trunk can be used to sharpen your carving and whittling skills.",
  "Regardless of where your creativity takes you, tree disposal is an excellent opportunity for fun with the whole family."
]