import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";
import mar23 from "../resources/mar-23-24.pdf";
import balltoss from "./balltoss.jpg";
import latanisha from "./latanisha.jpg";
import bowling from "./bowling.jpg";

const MAGNUS: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "senior"
};

const AVA: Author = {
  name: "Ava Schoonover",
  school: "West Valley High School",
  grade: "junior"
};

const nordstromCaption = "Wearing a T-shirt that says \"Oh the rarity of me,\" Peter Nordstrom engages in a game with West Valley ASB's communications co-director Dallin Pond, a senior, during a birthday party activity organized by the high school's ASB program on Feb. 29.";

const article: Article = {
  articleType: "news",
  author: [ AVA, MAGNUS ],
  date: new Date(2024, 2, 23),
  title: "West Valley High School celebrates the birthdays of its leap day students",
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/west-valley-high-school-celebrates-the-birthdays-of-its-leap-day-students/article_a89417f8-5179-50bc-8d57-67e8e35449d9.html",
  clipping: mar23,
  images: [
    {
      src: balltoss,
      caption: nordstromCaption,
      credit: MAGNUS
    },
    {
      src: latanisha,
      caption: "Latanisha Brown holds a birthday card signed by the West Valley High School's ASB team outside her RAM Strong advisory classroom on Feb. 29.",
      credit: AVA
    },
    {
      src: bowling,
      caption: nordstromCaption,
      credit: MAGNUS
    }
  ],
  content
};

export default article;