import { Article, Author } from "../../articleInterfaces";
import { content } from "./content";

import sign from "./sign.jpg";
import flag from "./flag.jpg";
import nov26 from "../resources/nov-26-23.pdf";

const ME: Author = {
  name: "Magnus Fulton",
  school: "West Valley High School",
  grade: "senior"
};

const article: Article = {
  title: "West Valley JROTC-photography teacher talks about perspectives",
  author: [ ME ],
  articleType: "news",
  //yakimaHeraldURL: "" //The Yakima Herald site is kinda borked rn and at the time of this comment, none of my recent articles have been uploaded
  content,
  date: new Date(2023, 10, 26),
  clipping: nov26,
  authorsChoice: true,
  images: [
    {
      src: sign,
      caption: "Master Sergeant Gregory Bynum stands under a welcome banner that his students painted in preparation for West Valley's Veteran's Day assembly. Bynum is West Valley's ROTC and photography instructor. However, Bynum hopes to teach his students more than just the skills they'll learn from organizing the assembly. After working as a college and career advisor for the military, he hopes that when his students walk across the graduation stage, they don't just leave with a diploma - they leave with a plan.",
      credit: ME
    },
    {
      src: flag,
      credit: ME,
      caption: "Master Sergeant Gregory Bynum considers stronger tape for a flag that his students painted in preparation for West Valley's Veteran's Day assembly. Bynum is West Valley's ROTC and photography instructor, and also helps to organize West Valley's Veteran's Day assembly. Before that, Bynum worked as a college and career advisor for the military, with a focus on retention. He's always looking for the \"carrots\" that will keep students engaged and at the high school. Through ROTC, he's able to offer new technologies and pathways to students, like learning to be a drone pilot. In his photography class, one of his greatest joys is when students come back to him a year or two later to check in or show him some photos they took."
    }
  ],
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/4-questions-west-valley-jrotc-and-photography-teacher-gregory-bynum/article_a19ecdf5-0c87-5d50-83ec-7e47023ca23e.html",
  featured: true
}

export default article;