import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  [
    {
      format: "section",
      content: "Why online shopping is better"
    },
    "The debate of online vs. in-person gift shopping is not a straightforward topic, and there are a lot of layers to be examined.",
    "Online shopping is undisputedly cheaper, easier, and has way more variety. However, in-person buying allows support for local businesses, and you can see the product with your own eyes or try on clothes before you buy them.",
    "The convenience factor heavily tilts in favor of online shopping. The ability to shop from virtually anywhere, and at any time, eliminates the constraints of physical location and operating hours. Say goodbye to long checkout waits and parking hassles. Online shopping streamlines the entire process, allowing individuals to browse, purchase, and receive goods with utmost ease, all from the comfort of their own space.",
    "Online platforms provide an expansive array of choices, offering a diverse selection from niche products to global brands. Sophisticated algorithms tailor product suggestions, ensuring a personalized shopping experience that spans a helpful range of styles and price ranges, delivering a world of options at the fingertips of consumers.",
    "Online shopping also presents a greener alternative by minimizing the carbon footprint that is associated with travel to physical stores. This eco-conscious approach aligns with a growing trend toward sustainability and mindful consumption.",
    "In the ongoing debate surrounding online shopping and in-person retail, digital commerce ultimately offers the advantages of cost-effectiveness, unmatched convenience, and expansive variety. And this all adds to its appeal to modern consumers who seek a seamless, diverse, and eco-friendly shopping experience.",
    {
      format: "byline",
      name: "Axel Fulton",
      school: "West Valley High School",
      grade: "junior"
    },
  ],
  [
    {
      format: "section",
      content: "Why in-person shopping is better"
    },
    "I disagree with my brother. Not only is in-person gift shopping better, but it is easier, faster, and more enjoyable.",
    {
      format: "bold",
      content: "Try before you buy"
    },
    "The biggest benefit to in-person shopping has got to be the ability to actually see the product you are buying. Sure, online shopping gives you photos, but nothing beats getting to hold the product in your hands before you swipe \"Yes\" at the checkout terminal.",
    "If you are buying clothes, you can make sure they fit, are a good color and are not stained. With other products, you can test their construction and make sure they are a good size. Online shopping is just an unnecessary gamble.",
    {
      format: "bold",
      content: "Instant gratification"
    },
    "Online shopping is absolutely more convenient, allowing you to shop from anywhere at any time. However, what it saves you in travel time gets counteracted by shipping delays.",
    "Once you\'ve pushed that \"Checkout\" button, you\'ve just signed up for a days- or weeks-long wait as your package trudges its way through the mail. Not only that, but you might have to pay extra on top of the listing price just for the shipping. If you have an immediate deadline for needing something, such as for a birthday gift, that ticking clock as the calendar's days tick off can be stressful. With in-person shopping, the only wait you\'ll have is the trip back home.",
    {
      format: "bold",
      content: "Greenbacks and green thoughts"
    },
    "Online shopping can admittedly offer a slight reduction in some vehicle emissions over in-person shopping because of the ability for online orders to batch orders and optimize delivery routes in certain situations. Despite this slight environmental disadvantage, in-person shopping improves lives in other ways.",
    "In-person shopping can be a social experience. Browsing the aisles with some friends is a great way to spend an afternoon. Thrift stores provide a rotating selection of clothes and novelties. For high school students, grocery stores are perfect for some post-practice perusing.",
    "Depending on how you do it, in-person shopping could also support the local economy, whether it is through the wages of the employees who stock the shelves, clean the floors, or ring you up as you leave. If you are buying from a local business, all the better!",
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "senior"
    }
  ]
]