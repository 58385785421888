import { Article } from "../../articleInterfaces";
import { content } from "./content";

import khan from "./khan.jpg";

const article: Article = {
  title:
    'Program helps "Ignite" the possibility of STEM careers for junior high girls',
  articleType: "news",
  author: [
    { 
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  date: new Date(2021, 1, 28),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/program-helps-ignite-the-possibility-of-stem-careers-for-junior-high-girls/article_afac6261-1960-58af-a30f-1346d6cf8165.html",
  images: [
    {
      caption: "West Valley Junior High School teacher Devina Khan",
      credit: "Courtesy / West Valley School District",
      src: khan
    }
  ],
  content
};

export default article;
