import { AppDispatch, RootState } from "../../store";
import { deleteSubscription, deleteTransaction, putSubscription, putTransaction } from "./persistent";
import { clearDraft } from "./transient";

export type TransactionAction = "deposit" | "withdrawl";
export type TransactionType = "transaction" | "subscription";

interface MoneyThing {
  action: TransactionAction;
  time: string;
  amount: number;
  description: string;
}

export interface Transaction extends MoneyThing {
  type: "transaction"
}

export type Renews = "weekly" | "monthly" | "yearly";

export interface Subscription extends MoneyThing {
  renews: Renews;
  stop?: string;
  type: "subscription"
}

export function addWeeks(date: Date, delta: number) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + delta * 7
  );
}

export function thisWeek(): Date {
  const x = new Date();
  return new Date(
    x.getFullYear(),
    x.getMonth(),
    x.getDate() - (x.getDay() || 7) + 1 //Week starts on Monday
  );
}

export function commitTransaction(dispatch: AppDispatch, getState: () => RootState) {
  const orig = getState().transientBudget.pointer;
  const newTransaction = getState().transientBudget.writableDraft;

  if (!!orig) {
    if (orig.type === "transaction") {
      dispatch(deleteTransaction(orig.time));
    } else if (orig.type === "subscription") {
      dispatch(deleteSubscription(orig.time));
    }
  }

  if (newTransaction.type === "transaction") {
    dispatch(putTransaction(newTransaction));
  } else if (newTransaction.type === "subscription") {
    dispatch(putSubscription(newTransaction));
  }

  dispatch(clearDraft());
}