import { Outlet, type RouteObject } from "react-router-dom";
import { Directory } from "./Directory";
import { Article } from "./articleInterfaces";
import { GetArticle } from "./Article";
import { Helmet } from "react-helmet-async";

import appletouch from "./icon-package/apple-touch-icon.png";
import favicon32 from "./icon-package/favicon-32x32.png";
import favicon16 from "./icon-package/favicon-16x16.png";
import mask from "./icon-package/safari-pinned-tab.svg";

//Shared functions
export function urlForArticle(a: Article) {
  return a.title.toLowerCase().replaceAll(/[^a-z0-9 ]/g,'').replaceAll(' ','-')
}

//Not shared
function MyHelmet() {
  return (
    <Helmet>
      <title>Unleashed</title>
      <link rel="apple-touch-icon" sizes="180x180" href={appletouch} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="icon" type="image/svg+xml" sizes="any" href={mask} />
      <link rel="mask-icon" href={mask} color="#5bbad5" />
    </Helmet>
  )
}

export const routes: RouteObject[] = [
  {
    path: "/unleashed",
    element: (
      <>
        <MyHelmet />
        <Outlet />
      </>
    ),
    children: [
      {
        path: "",
        element: <Directory />
      },
      {
        path: ":title",
        element: <GetArticle />
      }
    ]
  }
]