import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "Eisenhower High School sophomore Alissa Martinez remembers her reaction to hearing the news that school would be starting online this fall. She was relieved to know she would be able to avoid the potential health hazards of being inside a school classroom.",
  "But while she is glad to be safe at home, Martinez still misses the days of in-person school, where she could hang out with friends and interact with teachers and classmates.",
  "Besides missing friends, the new practice of taking classes over Zoom or Google Meets presents its own challenges. She\'s seen two main difficulties: \"Having to learn a class by yourself with just videos, and not feeling motivated to do assignments.\"",
  "Friends can serve as a way for accountability, though.",
  "\"We can\'t really hang out a lot, but we motivate each other to get our work done,\" Martinez explains. \"We usually call each other and check up to see how we\'re doing.\"",
  "There are some benefits to the routine. She enjoys simpler mornings, not having to worry about getting dressed, and the comfort of her own room.",
  "But, she adds: \"People just need to take online school a lot more seriously. It isn\'t an option, and this can make or break our grades, especially for high school students. We can take advantage of the time we have, but this (school) is still just as important as it is in person.\"",
  "One phrase that fellow Eisenhower High School sophomore Katie Suhm has often been using to describe the situation lately is \"This is difficult.\" That\'s a sentiment that many students can relate to.",
  "Among the difficulties Suhm cites is not being able to talk to teachers in person. She describes how difficult it is to ask in-depth questions while in a Google Meet session that is pressed for time.",
  "Reflecting on previous school years, Suhm says: \"I could go in before school and after school, but now I can\'t. If I send a teacher an email, I need an answer now, but teachers have lives. Teachers have things to do. I can\'t just sit there and expect for them to be waiting for my email. That\'s not fair to them. The communication is so delayed, unlike in-person class.\"",
  "Meanwhile, over at Davis High School, senior Gabriel Briceno says he\'s been finding that he actually prefers online school over in-person classes, explaining that he doesn\'t like talking in his classes anyway. It hasn\'t been challenging for him to transition into online instruction because, he says, \"It takes no effort.\"",
  {
    format: "section",
    content: "Online with West Valley High School"
  },
  "Trenton Durham, a freshman at the West Valley High School, appreciates the work his instructors have done for this school year.",
  "\"The teachers have definitely had more time to prepare for this coming school year instead of just getting thrown into it all of a sudden,\" he said, adding that things have improved overall compared to last spring.",
  "Durham as well as classmate Andrew Cabbage feel the biggest challenges now are procrastination and distraction. Cabbage adds that he\'s had issues in knowing where his assignments are within the array of online resources students are now using.",
  "As Cabbage explains: \"I have to check Skyward, Gmail and Google Classroom every day just to know if I have any assignments. Just the other day, I missed an assignment just because it showed up as not having a due date, which put the assignment in a whole other tab of Google Classroom even though my teacher wanted it back by the end of the day.\"",
  "However, Durham and Cabbage say they appreciate the flexibility that comes with online learning. As Cabbage observes: \"Online learning is like the personalized learning we did last year, because you can go at your own pace.\"",
  "Despite the added flexibility, Durham misses talking in person with his teachers and other people. Cabbage does also, and points out that it\'s hard to make new friends while online.",
  {
    format: "section",
    content: "Online with Selah High School"
  },
  "For Selah High School sophomore Allison Moultray, remote learning has been an opportunity for growth. Rather than lamenting the loss of social and extracurricular activities that has come with remote learning, the student has found several silver linings to the situation.",
  "Moultray has found that she is able to accomplish just as much academically from home as she would in person.",
  "\"I feel like I\'ve been learning things at a deeper level online,\" she said.",
  "She has also found ways to combat the deprivation of social interaction. Moultray has enjoyed collaborating with her friends on various assignments, not only providing an opportunity to socialize, but enhancing her learning, as well.",
  "While Moultray has found her experience with remote learning positive thus far, she has also faced her share of challenges. She\'s found that working on a computer for long periods of time can be tiring and that remote learning simply cannot replicate the experience of attending school in person. Nonetheless, Moultray intends to maintain a positive outlook.",
  "\"My hope is to try to make the most of it and to use the good things about it to my advantage instead of being sad about the things I\'m missing out on,\" she says.",
];
