import { Article } from "../../articleInterfaces";
import { content } from "./content";

const article: Article = {
  title: "As on-site classes resume, WVHS students discuss the challenges of online learning",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "freshman"
    }
  ],
  articleType: "news",
  date: new Date(2021, 1, 28),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/news/as-on-site-classes-resume-wvhs-students-discuss-the-challenges-of-online-learning/article_50478ca7-7b4a-59f2-aa2f-dcc33a625d1f.html",
  content
};

export default article;