import { Article } from "../../articleInterfaces";
import { content } from "./content";
import pot from "./pot.jpg";
import table from "./table.webp";

const LUNGCOLE_CAPTION = "Typically served with ham and bratwust, this Christmastime combination of kale, potatoes, and ham juice, which Magnus Fulton's familly calls 'lungcole,' has served as both a feast and a fear in the Unleashed reporter's household for generations.";
const ME = "Magnus Fulton of West Valley High School / Unleashed";

const article: Article = {
  title: "This Christmas meal tradition truly tastes better than it sounds",
  articleType: "column",
  author: [
    {
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ],
  date: new Date(2022, 11, 18),
  yakimaHeraldURL: "https://www.yakimaherald.com/unleashed/columnists/this-christmas-meal-tradition-truly-tastes-better-than-it-sounds/article_6f691c46-22ff-501d-a1d5-0bc5b7a74464.html",
  content,
  images: [
    {
      src: pot,
      caption: LUNGCOLE_CAPTION,
      credit: ME
    },
    {
      src: table,
      caption: LUNGCOLE_CAPTION,
      credit: ME
    }
  ]
};

export default article;