import { JSONDSL } from "../../articleInterfaces";

function boldCallout(bold: string, text: string): JSONDSL {
  return [
    {
      format: "block-with-inline",
      content: [
        {
          format: "bold",
          content: bold
        },
        text
      ]
    }
  ];
}

export const content: JSONDSL = [
  'TV viewers may have thought they were saying farewell to NBC\'s "The Office" after its final episode on May 16, 2013. But the staff of the Dunder Mifflin Paper Co. has never really gone away. Actually, the show seems to be even more popular now than it ever was.',
  "A large part of its current fan base includes young people who not only have solely known the program through reruns but have never even held a job in an office. So what's the explanation for the show's continuing popularity? A few teens from the Unleashed staff have offered up their insights — all of which are worthy of a Dundee Award.",
  [
    {
      format: "section",
      content: "Great characters create great humor"
    },
    '"The Office" ended 10 years ago, but people are still talking about it today, especially members of Gen Z. As a member of Gen Z, I think I know why, since I have watched the show in its entirety twice now.',
    '"The Office" has some of the best characters on television, and there are many fan favorites. Some people love Dwight Schrute, some prefer Creed Bratton, and others might find Meredith Palmer the funniest.',
    "The characters are unique and diverse. Kelly Kapoor is peppy and talkative, while Toby Flenderson is mellow and mannered and Kevin Malone is a little slow but still lovable.",
    "\"The Office\" has some off-the-wall humor, which is another reason it's so popular among Gen Z. There are so many inside jokes that it's hard to keep count.",
    "Some great scenes will forever be funny, like Kevin's chili pot falling all over the floor, manager Michael Scott making up random characters that he includes in conference meetings, and Meredith with a bat flying around her head.",
    "The show has a bit of everything: perfect comedy, a slow romance, serious heartfelt moments and much more. It always amazes me that the show is still going strong today. People still know the famous lines, talk about the best episodes, and watch the show regularly.",
    'There aren\'t many TV shows like this. What makes "The Office" so appealing is its mix of great characters and humor.',
    {
      format: "unordered-list",
      content: [
        boldCallout(
          "Favorite charecter: ",
          "My favorite character is Andy Bernard. He is expressive, and I really like the way he interacts with everyone. He is like the underdog of the office, but that's why he is my favorite. I also enjoy Dwight because of his great quotes and his character development near the end of the series."
        ),
        boldCallout(
          "Favorite line: ",
          'Stanley Hudson in "Initiation" from Season 3: "I wake up every morning in a bed that\'s too small, driving my daughter to a school that\'s too expensive and then I go to work to a job for which I get paid too little. But on pretzel day? Well, I like pretzel day."'
        ),
        boldCallout(
          "Favorite episode: ",
          'My favorite episode is probably "Money" from Season 4. This two-part episode has multiple plots. One is about Michael Scott as he slowly succumbs to his debt problem after his new girlfriend, Jan, wants to buy a new car and renovate their condo. Michael, who is slowly going crazy thinking about all the money going down the drain, takes some advice from Creed and, as a solution, publicly says to the office: "I declare bankruptcy!"'
        )
      ]
    },
    "Another plot in the episode involves receptionist Pam Beesly and salesman Jim Halpert going to Dwight's farm, which he has transformed into a bed and breakfast. The couple has a strange but exciting time together. This leads to Jim eventually realizing that he wants his relationship with Pam to be real, and he kisses her in front of the whole office and asks her out for a nice dinner.",
    "I really love this episode because it has a great ending, and everyone helps each other out.",
    {
      format: "byline",
      name: "Maria Jacobson",
      school: "Highland High School",
      grade: "senior"
    }
  ],
  [
    {
      format: "section",
      content: "Making the ordinary extraordinary"
    },
    'During the days of the COVID-19 pandemic lockdown, I decided to begin watching "The Office." By that point, I had heard lots of positive things about the show and thought I would likely enjoy it.',
    'My theory proved to be correct. Today, "The Office" is one of my all-time favorite shows.',
    'I think one of the reasons "The Office" has remained so popular is that it captures the extraordinary in the ordinary. A paper company is just about as dull and mundane as it gets, yet the show\'s writers highlighted the beauty of simple, everyday life. That, I believe, is why so many people continue to relate and identify with the show after all these years.',
    {
      format: "unordered-list",
      content: [
        boldCallout(
          "Favorite episode: ",
          'While "The Office" includes many great episodes, there is one from the fifth season that I believe stands out above the rest: "Stress Relief." In particular, I find the fire drill scene from this episode hilarious. From Kevin stealing all of the snacks out of the vending machine to Angela throwing her cat into the ceiling, the chaos of the scene never fails to crack me up.'
        ),
        boldCallout(
          "Favorite charecter: ",
          "There is also a particular character that the series simply wouldn't be the same without: Jim Halpert. While Jim is arguably one of the more normal Dunder Mifflin employees, he still adds many comedic moments to the show with his pranks, office shenanigans, and confused glances at the camera."
        )
      ]
    },
    {
      format: "byline",
      name: "Natalie Keller",
      school: "Selah High School",
      grade: "senior"
    }
  ],
  [
    {
      content: "A universal relatability",
      format: "section"
    },
    '"The Office" captures a joyful embrace of the randomness of our day-to-day lives. Whether it\'s through inappropriate absurdities or a heartwarming love story, "The Office" finds a way to be relevant even to those who have never worked an 8-to-5 job.',
    "However, there is something about this show that makes it so infinitely re-watchable. Maybe it has something to do with the stability that the show maintained. Sure, people come and go and have new experiences, but there is a predictability to how Michael Scott will make you question how he became a manager, Dwight Schrute's competitive antics and Stanley Hudson's tired response to it all.",
    'The show\'s characters are also relatable. With social media putting on a barrage of perfect-looking models, extravagant adventures and wealth beyond attainability, "The Office" goes the other way. Its characters are plausible, imperfect and relatable to our everyday experiences. They react in the ways we secretly always wished to. They both realize and fall short of their dreams.',
    'The internal struggles and successes in "The Office" escape the bounds of a classic white-collar job. They are applicable to school, to our own lives, and our own jobs, even if those look completely different to those in the show.',
    'Most important, the show is timeless. You can come back a week or a month later and pick up where you left off, or skip around. This is very much unlike Greg Daniels\' later work in "Parks and Recreation," where each episode was interconnected and little details come back into play later.',
    {
      format: "unordered-list",
      content: [
        boldCallout(
          "Favorite character: ",
          "Creed Bratton embodies that chaotic energy that defines Scranton's Dunder Mifflin branch. His enigmatic character hides a cunning intellect that is clearly put toward ... something. His character was equally likely to be found growing mung beans in his desk or flying a drone on the roof of the building. His true identity, however, is always a mystery."
        )
      ]
    },
    "Little is ever certain with Creed, but his final sendoff from the show felt fitting and in line with the character we had come to know and love.",
    {
      format: "unordered-list",
      content: [
        boldCallout(
          "Favorite line: ",
          'So, technically they are doing parkour, as long as point A is delusion and point B is the hospital." This line from Jim Halpert in "Gossip" from Season 6 is my favorite because it really captures the essence of "The Office."'
        )
      ]
    },
    "The scene opens with Michael Scott, Dwight Schrute and Andy Bernard haphazardly tumbling through the Dunder Mifflin building, much to everyone else's restrained annoyance. To the backdrop of kicked doors, climbed desks, and upended office furniture, Jim calmly comments on the trio's obsession with the fad. The scene's slapstick finale of Andy jumping onto an empty refrigerator box and just disappearing into it seemed only fitting.",
    'For "The Office," delusion and the hospital seem to be the destination for every episode.',
    {
      format: "byline",
      name: "Magnus Fulton",
      school: "West Valley High School",
      grade: "junior"
    }
  ]
];
