import { JSONDSL } from "../../articleInterfaces";

export const content: JSONDSL = [
  "I have yet to meet someone who disliked Master Sgt. Gregory Bynum\'s photography class at West Valley High School. That\'s because it is all about perspectives — not just how to take a photo, but why you chose to take that photo.",
  "However, this critical thinking lens isn\'t the only thing Bynum brings to the school\'s students. He is also one of West Valley\'s Junior Reserve Officer Training Corps (JROTC) instructors. He teaches his classes for the real world, connecting students to the jobs, technologies and skills they need to succeed. Before West Valley, he served as a career adviser.",
  "However, while speaking with Bynum recently, one thing remained constant as it was when I took his class two years ago: How genuinely his eyes light up at the prospect of thinking about what he can do for his students.",
  {
    format: "section",
    content: "How has your experience as a military, college and career adviser shaped your approach to educating?"
  },
  "We had a focus on retention — keeping the people that you have invested in. To this day, that is probably my greatest joy and greatest struggle. I think it\'s making me a better educator with regards to understanding that I only have a student for a limited amount of time.",
  "As much as I love coming up with 450 new ideas and projects, I have to face reality. I need to tailor that down just a little bit so that not only is the classroom exciting and comes to life with some real-world relevancy, but that there\'s always a culminating project at the end so that students have that sense of accomplishment.",
  {
    format: "section",
    content: "What 21st century skills do you think are most important for students to practice and master?"
  },
  "Ironically enough, I still believe that one of the key areas that we have to focus on is soft skills. Being able to look a person in the eye, being able to have a handshake that means something, being able to communicate in front of audiences aren\'t going to change. I watched today where they just released ChatGPT-4. But you know what? As much as that engineer is proud of his baby, he still had to have the ability to communicate with an audience and to make eye contact.",
  "I think the human relationship will always remain the greatest technology ever created. There still needs to be that human interaction to make sure that we are still upholding those things that mean the most to us — respect for one another, appreciating what we have, having a sense of gratitude, a sense of humility, and being in a position to raise up the next generation.",
  {
    format: "section",
    content: "What are some of the most interesting changes you have seen in recent years?"
  },
  "I think we are moving forward in STEM with more offerings. For example, next year through our Innovation Center partnership with the high school, I just found out that we will be offering drone license certifications through the FAA.",
  "When I started my military career, I\'ve always kind of found myself in that lane of being fortunate enough to be around technology through aviation or combat arms or different military equipment, where we were always seeing the changes in research and development moving forward. When I was a young kid joining, I wanted to be a B-52 tail gunner. And by the time I joined, that had become automated.",
  "I always believe in researching to find the positives with technology, but also hoping that we understand the risk involved with technology, that we have to approach things in moderation. By embracing it, we are truly empowering our students to be marketable in this new era of tech.",
  {
    format: "section",
    content: "Why is teaching students to look through different perspectives so important?"
  },
  "I think it\'s important, because that\'s what education is all about. The ironic thing is that with photography we always talk about using lenses. So having the ability to look at life through different lenses gives you greater perspective and appreciation.",
  "Through photos, you also have the ability to look at life from someone else\'s viewpoint. I think that that\'s something that we definitely need as a society."
];
