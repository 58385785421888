import { ReactComponent as Pencil } from "./pencil.svg";
import { divisor } from "../../store/persistent";
import { Subscription, Transaction } from "../../store/common";
import { startEditing } from "../../store/transient";
import { useAppDispatch } from "../../../store";
import { Link, useNavigate } from "react-router-dom";
import { Finance } from "../Finance";
import { Responsive } from "../../../shared/Responsive";

import style from "./index.module.scss";
import shared from "../../../shared/style.module.css";

function Details(props: Transaction | Subscription) {
  if (props.type === "subscription") {
    const action = props.action === "withdrawl" ? "renews" : "deposits";
    return (
      <>
        {action} {props.renews} @{" "}
        <Finance amount={props.amount} type={props.action} />
      </>
    );
  } else {
    return null;
  }
}

function BasicCharge(props: Transaction | Subscription) {
  const isSubscription = props.type === "subscription";

  const amount = isSubscription
    ? props.amount / divisor(props.renews)
    : props.amount;

  return (
    <>
      <span className={`${shared.center} ${style.padded}`}>
        <Finance amount={amount} type={props.action} />
      </span>
      <span className={style.description}>
        {props.description} <Details {...props} />
      </span>
    </>
  );
}

function DesktopCharge(props: Transaction | Subscription) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const editCharge = () => {
    dispatch(startEditing(props));
    navigate("/budget/record");
  };

  return (
    <div className={`${style.desktop} ${shared.large} ${style.charge}`}>
      <BasicCharge {...props} />
      <Pencil
        className={`${style.actionbutton} ${style.top}`}
        onClick={editCharge}
      />
    </div>
  );
}

function MobileCharge(props: Transaction | Subscription) {
  const dispatch = useAppDispatch();

  return (
    <Link
      onClick={() => dispatch(startEditing(props))}
      to="/budget/record"
      className={`${style.mobile} ${style.charge} ${shared.large} ${shared.linkoverride} ${shared.center}`}
    >
      <BasicCharge {...props} />
    </Link>
  );
}

export const Charge = Responsive(MobileCharge, DesktopCharge);
