import { useAppSelector } from "../../../store";
import { calculateWeeklyBudget } from "../../store/persistent";
import { Charge } from "../Charge";

import style from "./index.module.scss";
import shared from "../../../shared/style.module.css";

export function Charges() {
  const budget = useAppSelector(calculateWeeklyBudget);

  if (budget.transactions.length || budget.subscriptions.length) {
    return (
      <div className={style.charges}>
        {budget.subscriptions.map((s) => (
          <Charge key={s.time} {...s} />
        ))}

        {budget.transactions.map((t) => (
          <Charge key={t.time} {...t} />
        ))}
      </div>
    );
  } else {
    return (
      <>
        <div className={style.charges}>
          <p className={shared.center}>
            <i>No charges yet this week...</i>
          </p>
        </div>
      </>
    );
  }
}
